import queryString from 'query-string'
import { useNavigate, useLocation } from 'react-router-dom'

function useUrlQuery() {
  const navigate = useNavigate()
  const location = useLocation()

  // Parse the query parameters from the URL
  const params = queryString.parse(location.search)

  // Create a function to update the query parameters in the URL
  const setParams = (newParams, removeKeys = []) => {
    const newQuery = {
      ...params,
      ...newParams,
    }
    for (const key of removeKeys) {
      delete newQuery[key]
    }

    // Replace the current URL with the updated query parameters
    navigate(`${location.pathname}?${queryString.stringify(newQuery)}`, { replace: true })
  }

  const removeParam = (paramKey) => {
    setParams({}, [paramKey])
  }

  // Return the current query parameters and the function to update them
  return { params, setParams, removeParam }
}

export default useUrlQuery
