import { useState } from 'react'

const useSwipe = ({ onSwipedLeft, onSwipedRight }) => {
  const [touchStartX, setTouchStartX] = useState(0)
  const [touchStartY, setTouchStartY] = useState(0)
  const [touchEndX, setTouchEndX] = useState(0)
  const [touchEndY, setTouchEndY] = useState(0)

  const minSwipeDistance = 50

  const onTouchStart = (e) => {
    setTouchEndX(0) // otherwise the swipe is fired even with usual touch events
    setTouchEndY(0) // otherwise the swipe is fired even with usual touch events
    setTouchStartX(e.targetTouches[0].clientX)
    setTouchStartY(e.targetTouches[0].clientY)
  }

  const onTouchMove = (e) => {
    setTouchEndX(e.targetTouches[0].clientX)
    setTouchEndY(e.targetTouches[0].clientY)
  }

  const onTouchEnd = () => {
    if (!touchStartX || !touchEndX) return
    const distanceX = touchStartX - touchEndX
    const distanceY = touchStartY - touchEndY
    const isLeftSwipe = distanceX > minSwipeDistance
    const isRightSwipe = distanceX < -minSwipeDistance
    if (isLeftSwipe && distanceX > distanceY) {
      onSwipedLeft()
    }
    if (isRightSwipe && Math.abs(distanceX) > distanceY) {
      onSwipedRight()
    }
  }

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  }
}

export default useSwipe
