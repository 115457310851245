import { toast } from 'react-toastify'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const useBackgroundUploadStore = create()(
  immer((set, get) => ({
    files: [],
    addUploadingFile: (filename) => {
      set({
        files: [...get().files, filename],
      })
    },
    addUploadingFiles: (filenames) => {
      set({
        files: [...get().files, ...filenames],
      })
    },
    removeUploadingFile: (filename) => {
      const newFiles = get().files.filter((file) => file !== filename)
      set({ files: newFiles })
      if (newFiles.length === 0) {
        toast.success('All files have been uploaded')
      }
    },
    removeUploadingFiles: (filenames) => {
      const newFiles = get().files.filter((file) => !filenames.includes(file))
      set({ files: newFiles })
      if (newFiles.length === 0) {
        toast.success('All files have been uploaded')
      }
    },
  })),
)
