import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'src/config/api'
import { toast } from 'react-toastify'

export const getListSubject = createAsyncThunk('contact/list-subjects', async (rejectWithValue) => {
  try {
    let URL = `contact-template/`
    const response = await Axios.get(URL)

    return response.data
  } catch (error) {
    if (!error.response) {
      toast.error('Get all subject failed')
      throw error
    }
    console.log('error.response.data: ', error.response.data)
    if (error.response.data) {
      const msg = error.response.data ? error.response.data.detail : 'Get all subject failed'
      toast.error(msg)
    }
    return rejectWithValue(error.response.data)
  }
})

export const getAllContacts = createAsyncThunk(
  'contact/all',
  async ({ filter, page = 1, page_size = 10 }, { rejectWithValue }) => {
    try {
      let URL = `contact/?page=${page}&page_size=${page_size}`
      if (filter) {
        for (const [key, value] of Object.entries(filter)) {
          URL += `&${key}=${value}`
        }
      }
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get all contacts failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get all contacts failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const createContact = createAsyncThunk(
  'contact/create',
  async (body, { rejectWithValue }) => {
    try {
      let URL = `contact/`
      const response = await Axios.post(URL, body)
      toast.success('Issue created!')
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Create issue failed')
        return rejectWithValue(error)
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Create issue failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getContactWithId = createAsyncThunk(
  'contact/get-detail',
  async (id, { rejectWithValue }) => {
    try {
      let URL = `contact/${id}`
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get contact detail failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get contact detail failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateContactWithId = createAsyncThunk(
  'contact/update-contact',
  async (contact, { rejectWithValue }) => {
    try {
      let URL = `contact/${contact.id}/`
      const response = await Axios.put(URL, contact)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get contact detail failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get contact detail failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getRepliesContactWithId = createAsyncThunk(
  'contact/get-detail-replies',
  async (id, { rejectWithValue }) => {
    try {
      let URL = `contact/reply/${id}/`
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get replies failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get replies failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const createReplyContact = createAsyncThunk(
  'contact/create-reply',
  async (contact, { rejectWithValue }) => {
    try {
      let URL = `contact/reply/`
      const response = await Axios.post(URL, contact)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Create reply contact failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Create reply contact failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    loading: false,
    loadingPage: false,
    loadingReply: false,
    isCreated: false,
    isUpdated: false,
    isReplyCreated: false,
    allContacts: null,
    contact: null,
    contactReplies: null,
    listSubjects: null,
    errors: null,
  },
  reducers: {
    resetContact: (state) => {
      state.loading = false
      state.loadingPage = false
      state.loadingReply = false
      state.isCreated = false
      state.isUpdated = false
      state.isReplyCreated = false
      state.errors = null
    },
  },
  extraReducers: (builder) => {
    // get list subject
    builder.addCase(getListSubject.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getListSubject.fulfilled, (state, action) => {
      state.loading = false
      state.listSubjects = action.payload
    })
    builder.addCase(getListSubject.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        listSubjects: action.payload,
      }
    })
    // get all contact
    builder.addCase(getAllContacts.pending, (state) => {
      state.loadingPage = true
    })
    builder.addCase(getAllContacts.fulfilled, (state, action) => {
      state.loadingPage = false
      state.allContacts = action.payload
    })
    builder.addCase(getAllContacts.rejected, (state, action) => {
      state.loadingPage = false
      state.errors = {
        allContacts: action.payload,
      }
    })
    // get contact detail
    builder.addCase(getContactWithId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getContactWithId.fulfilled, (state, action) => {
      state.loading = false
      state.contact = action.payload
    })
    builder.addCase(getContactWithId.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        detail: action.payload,
      }
    })
    // get replies contact
    builder.addCase(getRepliesContactWithId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getRepliesContactWithId.fulfilled, (state, action) => {
      state.loading = false
      state.contactReplies = action.payload
    })
    builder.addCase(getRepliesContactWithId.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        replies: action.payload,
      }
    })
    // create contact
    builder.addCase(createContact.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createContact.fulfilled, (state, action) => {
      state.loading = false
      state.isCreated = true
    })
    builder.addCase(createContact.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        create: action.payload,
      }
    })
    // update contact
    builder.addCase(updateContactWithId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateContactWithId.fulfilled, (state, action) => {
      state.loading = false
      state.isUpdated = true
    })
    builder.addCase(updateContactWithId.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        update: action.payload,
      }
    })
    // create reply contact
    builder.addCase(createReplyContact.pending, (state) => {
      state.loadingReply = true
    })
    builder.addCase(createReplyContact.fulfilled, (state, action) => {
      state.loadingReply = false
      state.isReplyCreated = true
    })
    builder.addCase(createReplyContact.rejected, (state, action) => {
      state.loadingReply = false
      state.errors = {
        createReply: action.payload,
      }
    })
  },
})

export const { resetContact } = contactSlice.actions

export default contactSlice.reducer
