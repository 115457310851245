import React from 'react'
import PropTypes from 'prop-types'
import { useUserStatsPanelStore } from 'src/store/useUserStatsPanelStore'
import { useShallow } from 'zustand/react/shallow'
import classNames from 'classnames'

function ClickableUsername({ className, username }) {
  const { openPanel: openUserStatsPanel } = useUserStatsPanelStore(
    useShallow((state) => ({ openPanel: state.openPanel })),
  )
  const onClick = async (username) => {
    openUserStatsPanel({ username })
  }

  return (
    <span
      className={classNames('text-info', className)}
      style={{ fontWeight: 500, fontStyle: 'italic', cursor: 'pointer' }}
      onClick={() => onClick(username)}
    >
      {username}
    </span>
  )
}

ClickableUsername.propTypes = {
  username: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

export default ClickableUsername
