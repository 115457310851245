import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import Axios from 'src/config/api'
import { UserRole, LS_PREFER_LANG, LS_ROLE, LS_TOKEN, LS_USERNAME } from 'src/constants'

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`reset-password/`, params)
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Failed to reset password')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          (error.response?.data?.detail || error.response?.data?.message) ??
          'Failed to reset password'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`forgot-password/`, params)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Forgot Password failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          (error.response?.data?.detail || error.response?.data?.message) ??
          'Forgot Password failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const login = createAsyncThunk('auth/login', async (user, { rejectWithValue }) => {
  try {
    const response = await Axios.post(`login/`, user)
    return response.data
  } catch (error) {
    if (!error.response) {
      toast.error('Login failed')
      throw error
    }
    if (error.response.data) {
      const msg = error.response.data ? error.response.data.detail : 'Login failed'

      // if (
      //   error.response.status === 401 &&
      //   msg === 'No active account found with the given credentials'
      // ) {
      //   // toast.error('Please verify your account to log in')
      // } else
      if (error.response.status === 401) {
        toast.error('Incorrect username/password or your account not verified. Please check again')
        // toast.error('Incorrect username/password, please try again')
      } else {
        toast.error(msg)
      }
    }
    return rejectWithValue(error.response.data)
  }
})

export const register = createAsyncThunk('auth/register', async (user, { rejectWithValue }) => {
  try {
    const response = await Axios.post(`register/`, user)
    return response.data
  } catch (error) {
    let message = 'Failed to register, please try again later or contact support if issue persists'
    if (!error.response) {
      toast.error(message)
      throw error
    }
    for (let [k, v] of Object.entries(error.response.data)) {
      message = Array.isArray(v) ? v[0] : v
    }
    toast.error(message)
    return rejectWithValue(error.response.data)
  }
})

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    token: localStorage.getItem('dms_token'),
    userInfo: null,
    isRegister: false,
    isLogin: false,
    isForgotPassword: false,
    isResetPassword: false,
    errors: null,
  },
  reducers: {
    setAuth: (state, action) => {
      state.userInfo = action.payload.user
      state.token = action.payload.token
    },
    resetAuth: (state) => {
      state.loading = false
      state.isRegister = false
      state.isLogin = false
      state.loading = false
      state.isForgotPassword = false
      state.isResetPassword = false
      state.token = null
      state.errors = null
    },
    logout: () => {
      localStorage.removeItem(LS_TOKEN)
      localStorage.removeItem(LS_ROLE)
      localStorage.removeItem(LS_USERNAME)
      localStorage.removeItem(LS_PREFER_LANG)
      window.location.href = '/login'
    },
  },
  extraReducers: (builder) => {
    // reset password
    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true
    })
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = false
      state.isResetPassword = true
    })
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        resetPassword: action.payload,
      }
    })
    // forgot password
    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true
    })
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.loading = false
      state.isForgotPassword = true
    })
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        forgotPassword: action.payload,
      }
    })
    // login
    builder.addCase(login.pending, (state) => {
      state.loading = true
    })
    builder.addCase(login.fulfilled, (state, action) => {
      const userInfo = action.payload
      const token = action.payload ? action.payload.access : action.payload

      let role = UserRole.customer
      if (userInfo.groups) {
        role = userInfo.groups[0]
      }
      localStorage.setItem(LS_TOKEN, token)
      localStorage.setItem(LS_ROLE, role)
      localStorage.setItem(LS_USERNAME, userInfo.username)

      state.loading = false
      state.userInfo = userInfo
      state.token = token
      state.isLogin = true
    })
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        login: action.payload,
      }
    })
    // register
    builder.addCase(register.pending, (state) => {
      state.loading = true
    })
    builder.addCase(register.fulfilled, (state, action) => {
      state.loading = false
      state.token = action.payload
      state.isRegister = true
    })
    builder.addCase(register.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        register: action.payload,
      }
    })
  },
})

export const { setAuth, resetAuth, logout } = authSlice.actions

export default authSlice.reducer
