import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CSpinner } from '@coreui/react'
import { useShallow } from 'zustand/react/shallow'
import { getScheduleDisplayName } from 'src/utils/schedule'
import ClickableUsername from 'src/components/common/ClickableUsername'
import { useTopicReplyStore } from 'src/store/useTopicReplyStore'
import FeedbackItem from './FeedbackItem'

const ReceivedFeedbacks = ({ username }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const { getReceivedFeedbacks } = useTopicReplyStore(
    useShallow((state) => ({
      getReceivedFeedbacks: state.getReceivedFeedbacks,
    })),
  )

  const fetchData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await getReceivedFeedbacks({ username })
      setData(res)
    } finally {
      setLoading(false)
    }
  }, [username, getReceivedFeedbacks])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <div className="d-flex flex-column gap-4">
      {!loading && data.length === 0 && <div>No feedback yet</div>}
      {data.map((e) => {
        return (
          <div key={e.id} className="d-flex flex-column gap-2 flex-grow-1">
            <div className="text-sm">
              Feedback given by <ClickableUsername username={e.user.username} /> on{' '}
              <strong>{getScheduleDisplayName(e.feedback_to_schedule)}</strong>
            </div>
            <FeedbackItem data={e} />
          </div>
        )
      })}
      {loading && <CSpinner className="mx-auto" color="primary" />}
    </div>
  )
}

ReceivedFeedbacks.propTypes = {
  username: PropTypes.string,
}

export default ReceivedFeedbacks
