import React from 'react'
import PropTypes from 'prop-types'
import { CAvatar } from '@coreui/react'
import { IMG_PLACEHOLDER } from 'src/constants'

const UserAvatar = ({ width = 40, height = 40, user, onClick }) => {
  return user ? (
    user.profile.avatar ? (
      <CAvatar src={user.profile.avatar} style={{ width, height }} onClick={onClick} />
    ) : (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ backgroundColor: '#ececec', width, height, borderRadius: '100%' }}
        onClick={onClick}
      >
        <span className="fw-bold text-uppercase">{user.username[0]}</span>
      </div>
    )
  ) : (
    <CAvatar
      style={{ width, height, overflow: 'hidden' }}
      src={IMG_PLACEHOLDER}
      onClick={onClick}
    />
  )
}

UserAvatar.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  user: PropTypes.object,
  onClick: PropTypes.func,
}

export default UserAvatar
