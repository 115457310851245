import React, { useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

const EllipsisTextHeight = ({ className, children, maxHeight }) => {
  const contentRef = useRef()
  const [contentHeight, setContentHeight] = useState(0)

  useLayoutEffect(() => {
    if (contentRef.current) setContentHeight(contentRef.current.clientHeight)
  }, [])

  return (
    <div className={className}>
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          overflowWrap: 'break-word',
          textOverflow: 'ellipsis',
          maxHeight: maxHeight,
        }}
      >
        <div ref={contentRef} style={{ whiteSpace: 'pre-line' }}>
          {children}
        </div>
      </div>
      {contentHeight > maxHeight && <div>...</div>}
    </div>
  )
}

EllipsisTextHeight.propTypes = {
  children: PropTypes.node,
  maxHeight: PropTypes.number,
  className: PropTypes.string,
}

export default EllipsisTextHeight
