import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilSpeedometer,
  cilTags,
  cilCart,
  cilPeople,
  cilList,
  cilClock,
  cilChatBubble,
  cilStar,
  cilNewspaper,
  cilHome,
  cilShareBoxed,
  cilLockUnlocked,
  cilUserPlus,
  cilStorage,
  cilMenu,
  cilCommentSquare,
  cilUser,
  cilUserFollow,
  cilBadge,
} from '@coreui/icons'
import { LiaCommentAltSolid, LiaCommentsSolid } from 'react-icons/lia'
import routes from './navigations/routes'
import { HiOutlineUsers } from 'react-icons/hi2'
import { FiTarget } from 'react-icons/fi'

const MenuProfile = {
  name: 'Profile',
  to: routes.SETTING_PROFILE,
  icon: <CIcon icon={cilUser} />,
}
const MenuFollowing = {
  name: 'Following Users',
  to: routes.SETTING_FOLLOWING,
  icon: <HiOutlineUsers fontSize={20} />,
}
const MenuObjectives = {
  name: 'Rewards',
  to: routes.SETTING_OBJECTIVES,
  icon: <CIcon icon={cilBadge} size="lg" />,
}
const MenuTopics = {
  name: 'Topics',
  to: routes.TOPICS,
  icon: <LiaCommentAltSolid fontSize={20} />,
}
const MenuManageTopics = {
  name: 'Manage Topics',
  to: routes.ADMIN_TOPICS,
  icon: <LiaCommentsSolid />,
}
const MenuManageUsers = {
  name: 'Manage Users',
  to: routes.ADMIN_USERS,
  icon: <CIcon icon={cilPeople} />,
}

const MenuLogin = {
  name: 'Login',
  to: routes.LOGIN,
  icon: <CIcon icon={cilLockUnlocked} />,
}
const MenuRegister = {
  name: 'Register',
  to: routes.REGISTER,
  icon: <CIcon icon={cilStorage} />,
}
export const _navPublic = [MenuLogin, MenuRegister]

export const _navUser = [/* MenuProfile */ MenuTopics, MenuFollowing, MenuObjectives]

export const _navModerator = [/* MenuProfile */ MenuTopics, MenuManageTopics]

export const _navAdmin = [
  // MenuProfile,
  MenuTopics,
  MenuManageTopics,
  MenuManageUsers,
  // {
  //   name: 'Organisers',
  //   to: '/dashboard/organisers',
  //   icon: <CIcon icon={cilCart}  />,
  // },
  // {
  //   name: 'Organiser Registration',
  //   to: '/dashboard/organiser-registrations',
  //   icon: <CIcon icon={cilNewspaper}  />,
  // },
  // {
  //   name: 'Vouchers',
  //   to: '/dashboard/coupons',
  //   icon: <CIcon icon={cilTags}  />,
  // },
  // {
  //   name: 'Categories',
  //   to: '/dashboard/categories',
  //   icon: <CIcon icon={cilList}  />,
  // },
  // {
  //   name: 'Supports',
  //   to: '/dashboard/supports',
  //   icon: <CIcon icon={cilChatBubble}  />,
  // },
  // {
  //   name: 'Review',
  //   to: '/dashboard/feedbacks',
  //   icon: <CIcon icon={cilStar}  />,
  // },
]
export const _navStore = [
  MenuTopics,
  {
    name: 'Vouchers',
    to: routes.VOUCHERS,
    icon: <CIcon icon={cilTags} />,
  },
  {
    name: 'Manage',
    icon: <CIcon icon={cilMenu} />,
    items: [
      {
        name: 'Overview',
        to: routes.SETTINGS_STORE_OVERVIEW,
        icon: <CIcon font icon={cilSpeedometer} />,
      },
      {
        name: 'Customers',
        to: routes.SETTING_CUSTOMERS,
        icon: <CIcon icon={cilPeople} />,
      },
      {
        name: 'Vouchers',
        to: routes.SETTINGS_STORE_VOUCHERS,
        icon: <CIcon icon={cilTags} />,
      },
      {
        to: routes.SETTINGS_STORE_VOUCHERS_CREATE,
      },
      {
        name: 'Orders',
        to: routes.SETTINGS_STORE_ORDERS,
        icon: <CIcon icon={cilClock} />,
      },
      {
        name: 'Issues',
        to: routes.SETTINGS_STORE_ISSUES,
        icon: <CIcon icon={cilChatBubble} />,
      },
      {
        name: 'Feedbacks',
        to: routes.SETTINGS_STORE_FEEDBACKS,
        icon: <CIcon icon={cilStar} />,
      },
    ],
  },
]
