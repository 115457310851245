import defaultAvatar from 'src/assets/images/default_avatar.jpeg'
import { ReactComponent as IconAu } from 'src/assets/images/ic_au.svg'
import { ReactComponent as IconCn } from 'src/assets/images/ic_cn.svg'
import { ReactComponent as IconKr } from 'src/assets/images/ic_kr.svg'
import { ReactComponent as IconTh } from 'src/assets/images/ic_th.svg'

export const LS_TOKEN = 'dms_token'
export const LS_ROLE = 'dms_role'
export const LS_USERNAME = 'dms_username'
export const LS_PREFER_LANG = 'dms_prefer_lang'
export const LS_SHOW_LANG_SELECT = 'dms_show_language_select'
export const LS_LAST_FOLLOWING_PARTICIPATE = 'dms_last_following_participate'

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'dms-project-d93e3.firebaseapp.com',
  projectId: 'dms-project-d93e3',
  storageBucket: 'dms-project-d93e3.appspot.com',
  messagingSenderId: '791781118712',
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: 'G-VNR7QRVRRL',
}

export const FILE_UPLOAD_MAX_MB = 50
export const VIDEO_UPLOAD_MAX_MB = 500

export const IMAGE_FILE_TYPES = ['PNG', 'JPG', 'JPEG']
export const VIDEO_FILE_TYPES = ['MP4', 'AVI', 'MOV', 'FLAC', 'M4A', 'WAV']
export const DOCUMENT_FILE_TYPES = ['PDF', 'DOC', 'DOCX']

export const IMG_PLACEHOLDER = defaultAvatar

export const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$/
export const PHONE_REGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const REPLY_CHAR_LIMIT = 300

export const MONTH_NAME = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const SearchType = {
  topic: 'topic',
  user: 'user',
}

export const UserBadgeType = {
  top_distributor: 'top_distributor',
  top_rated: 'top_rated',
}

export const UserRole = {
  store: 'STORE',
  organiser: 'ORGANISER',
  customer: 'CUSTOMER',
  admin: 'ADMIN',
  moderator: 'MODERATOR',
}

export const VoteType = {
  upvote: 1,
  downvote: 0,
}

export const UserLevel = {
  normal: 0,
  pro: 1,
}

export const UserLevelLabel = {
  [UserLevel.normal]: 'NORMAL',
  [UserLevel.pro]: 'PRO',
}

export const TopicStatus = {
  pending: 0,
  approved: 1,
  rejected: 2,
  need_info: 3,
}

export const TopicStatusLabel = {
  [TopicStatus.pending]: 'Pending',
  [TopicStatus.approved]: 'Approved',
  [TopicStatus.rejected]: 'Rejected',
  [TopicStatus.need_info]: 'Need Info',
}

export const RecurrenceType = {
  none: 'none',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
}
export const RecurrenceOccurType = {
  day: 'day',
  weekday: 'weekday',
}

export const RecurrenceTypeLabel = {
  [RecurrenceType.daily]: 'Daily',
  [RecurrenceType.weekly]: 'Weekly',
  [RecurrenceType.monthly]: 'Monthly',
}

export const RecurrenceEndType = {
  date: 'date',
  occurrence: 'occurrence',
  none: 'none',
}

export const Language = {
  au_english: 'au',
  thai: 'th',
  indonesian: 'id',
  korean: 'kr',
  chinese: 'zh',
}

export const LANGUAGES = [
  { id: Language.au_english, icon: IconAu, label: 'English' },
  { id: Language.chinese, icon: IconCn, label: '中文' },
  { id: Language.indonesian, icon: IconKr, label: 'Bahasa Indonesia' },
  { id: Language.korean, icon: IconKr, label: '한국인' },
  { id: Language.thai, icon: IconTh, label: 'แบบไทย' },
]

export const POLL_LIMIT = 10
export const POLL_OPTIONS_LIMIT = 5

export const CHART_COLORS = [
  '#ff4895',
  '#8001D8',
  '#20dfb5',
  '#0eb4ff',
  '#ff4632',
  '#000000',
  '#DDA15E',
  '#a2d2ff',
  '#FCF300',
  '#ffc4d6',
]

export const UserChallengeEventType = {
  receive: 'receive_qualify_message',
  confirm: 'confirm_qualify_message',
}

export const ScheduleLevel = {
  guidance_required: 'guidance_required',
  guidance_not_required: 'guidance_not_required',
}

export const ScheduleLevelLabel = {
  [ScheduleLevel.guidance_required]: 'Guidance Required',
  [ScheduleLevel.guidance_not_required]: 'Guidance Not Required',
}

export const TopicReplyActivity = {
  create_schedule: 'create_schedule',
  edit_schedule: 'edit_schedule',
  book_slot: 'book_slot',
  add_highlight: 'add_highlight',
  add_poll: 'add_poll',
  complete_challenge: 'complete_challenge',
  contribute_answer: 'contribute_answer',
  verified_participant: 'verified_participant',
  add_feedback: 'add_feedback',
}

export const UserChallengeStatus = {
  in_progress: 0,
  qualified: 1,
  approved: 2,
  rejected: 3,
}

export const UserChallengeStatusLabel = {
  [UserChallengeStatus.in_progress]: 'In progress',
  [UserChallengeStatus.qualified]: 'Qualified',
  [UserChallengeStatus.approved]: 'Approved',
  [UserChallengeStatus.rejected]: 'Rejected',
}

export const WsMessageType = {
  create_reply: 'create_reply',
  edit_reply: 'edit_reply',
  delete_reply: 'delete_reply',
  create_schedule: 'create_schedule',
  edit_schedule: 'edit_schedule',
  delete_schedule: 'delete_schedule',
  create_row: 'create_row',
  edit_row: 'edit_row',
  delete_row: 'delete_row',
  create_question: 'create_question',
  delete_question: 'delete_question',
}

export const BADGE_COLORS = {
  Contributor: '#f0ad4e',
  Participator: '#39f',
}
