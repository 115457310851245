import { toast } from 'react-toastify'
import Axios from 'src/config/api'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const useChallengeStore = create()(
  immer((set, get) => ({
    challenges: [],
    loading: false,
    congratModal: {
      open: false,
      challenges: [],
    },
    openCongratModal: (challenges) => {
      set({ congratModal: { open: true, challenges } })
    },
    closeCongratModal: () => {
      set({ congratModal: { open: false, challenges: [] } })
    },
    getChallenges: async () => {
      try {
        set({ loading: true })
        const response = await Axios.get(`challenges/`)
        set({ challenges: response.data })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      } finally {
        set({ loading: false })
      }
    },
    qualifyForChallenge: async (challengeKey) => {
      try {
        const response = await Axios.post(`challenges/qualify/`, {
          challenge: challengeKey,
        })
        return response.data
      } catch (error) {
        let message = 'Qualify failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    logUserChallengeEvent: async (challengeKey, eventType) => {
      try {
        const response = await Axios.post(`challenges/user-event/`, {
          challenge: challengeKey,
          event_type: eventType,
        })
        return response.data
      } catch (error) {
        let message = 'Log user challenge event failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    getUserObjectives: async () => {
      try {
        set({ loading: true })
        const response = await Axios.get(`challenges/user-objectives/`)
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      } finally {
        set({ loading: false })
      }
    },
    getCompletedRewards: async ({ page, pageSize }) => {
      try {
        const response = await Axios.get(`challenges/rewards/`, {
          params: {
            page: page,
            page_size: pageSize,
          },
        })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
  })),
)
