import React, { useState } from 'react'
import 'src/scss/common/_searchBox.scss'
import PropTypes from 'prop-types'

import { CForm, CFormInput, CButton } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilSearch } from '@coreui/icons'

const SearchBox = ({ loading, placeholder, onSubmit }) => {
  const [value, setValue] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(value)
  }

  return (
    <div className="searchBox">
      <CForm className="d-flex position-relative" onSubmit={handleSubmit}>
        <CFormInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type="search"
          className=""
          placeholder={placeholder || 'Search'}
          disabled={loading}
        />
        <CButton type="submit" color="dark" variant="outline" disabled={loading}>
          <CIcon icon={cilSearch} size="sm" />
        </CButton>
      </CForm>
    </div>
  )
}

SearchBox.propTypes = {
  value: PropTypes.string,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func,
}
export default SearchBox
