import { createSlice } from '@reduxjs/toolkit'

const mainSlice = createSlice({
  name: 'main',
  initialState: {
    loading: false,
    openSidebar: false,
    fullImageModal: {
      open: false,
      src: '',
      type: '',
      images: '',
      currentIndex: 0,
    },
    notificationModal: {
      open: false,
    },
  },
  reducers: {
    onToggleSidebar: (state, action) => {
      state.openSidebar = action.payload
    },
    setFullImageModal: (state, action) => {
      state.fullImageModal.open = action.payload.open
      state.fullImageModal.src = action.payload.src
      state.fullImageModal.type = action.payload.type
      state.fullImageModal.images = action.payload.images
      state.fullImageModal.currentIndex = action.payload.currentIndex
    },
    setNotificationModal: (state, action) => {
      state.notificationModal.open = action.payload
    },
  },
})

export const { onToggleSidebar, setFullImageModal, setNotificationModal } = mainSlice.actions

export default mainSlice.reducer
