import React from 'react'
import PropTypes from 'prop-types'
import { CModal, CModalHeader, CModalTitle, CButton, CModalFooter } from '@coreui/react'

const ConfirmDelete = ({ open, onClose, onDelete }) => {
  return (
    <CModal alignment="center" visible={open} onClose={onClose}>
      <CModalHeader onClose={onClose}>
        <CModalTitle>Are you sure you want to delete?</CModalTitle>
      </CModalHeader>
      <CModalFooter className="d-flex justify-content-end">
        <CButton color="danger" onClick={onDelete}>
          Delete
        </CButton>
        <CButton color="dark" onClick={onClose}>
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}
ConfirmDelete.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
}
export default ConfirmDelete
