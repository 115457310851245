import React from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import Routes from './routes'
import { isAuth } from 'src/utils/role'

const AuthRoute = ({ component: Component, layout: Layout }) => {
  const navigate = useNavigate()
  const location = useLocation()

  if (!isAuth()) {
    navigate(`${Routes.LOGIN}?redirect=${location.pathname}`)
    return <></>
  }

  return (
    <Layout>
      <Component />
    </Layout>
  )
}

AuthRoute.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
}

export default AuthRoute
