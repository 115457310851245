import { toast } from 'react-toastify'
import Axios from 'src/config/api'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const useTopicPollStore = create()(
  immer((set, get) => ({
    polls: {},
    loading: false,
    getPolls: async (topicId) => {
      try {
        set({ loading: true })
        const response = await Axios.get(`topics/polls/`, {
          params: { topic_id: topicId },
        })
        set({ polls: { ...get().polls, [topicId]: response.data } })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      } finally {
        set({ loading: false })
      }
    },
    createTopicPoll: async ({
      topicId,
      category,
      question,
      multipleChoice,
      isRenew,
      isPrivateVote,
    }) => {
      try {
        const response = await Axios.post(`topics/polls/`, {
          topic_id: topicId,
          category,
          question,
          multiple_choice: multipleChoice,
          reset_weekly: isRenew,
          private_vote: isPrivateVote,
        })
        return response.data
      } catch (error) {
        let message = 'Failed to create poll, please contact support if issue persists'
        if (error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
        throw message
      }
    },
    editTopicPoll: async ({ pollId, category, question, multipleChoice, isPrivateVote }) => {
      try {
        const response = await Axios.patch(`topics/polls/${pollId}/`, {
          question,
          category,
          multiple_choice: multipleChoice,
          private_vote: isPrivateVote,
        })
        return response.data
      } catch (error) {
        let message = 'Failed to update, please contact support if issue persists'
        if (error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
        throw message
      }
    },
    deleteTopicPoll: async (id) => {
      try {
        const response = await Axios.delete(`topics/polls/${id}/`)
        toast.success('Delete successfully')
        return response.data
      } catch (error) {
        let message = 'Failed to delete, please contact support if issue persists'
        if (error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
        throw message
      }
    },
    getPollOptions: async (pollId) => {
      try {
        const response = await Axios.get(`topics/polls/${pollId}/options/`)
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    createPollOption: async ({ pollId, text, image }) => {
      const formData = new FormData()
      formData.append('poll', pollId)
      formData.append('text', text)
      if (image) formData.append('image', image)
      try {
        const response = await Axios.post(`topics/polls/options/`, formData)
        return response.data
      } catch (error) {
        let message = 'Failed to create, please contact support if issue persists'
        if (error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        throw message
      }
    },
    editPollOption: async ({ id, text, image }) => {
      const formData = new FormData()
      formData.append('text', text)
      if (image) formData.append('image', image)
      try {
        const response = await Axios.patch(`topics/polls/options/${id}/`, formData)
        return response.data
      } catch (error) {
        let message = 'Failed to update, please contact support if issue persists'
        if (error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        throw message
      }
    },
    deletePollOptions: async (ids) => {
      try {
        const response = await Axios.delete(`topics/polls/options/`, {
          data: { ids },
        })
        return response.data
      } catch (error) {
        let message = 'Failed to delete, please contact support if issue persists'
        if (error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        throw message
      }
    },
    voteTopicPoll: async ({ pollId, options = '' }) => {
      try {
        const response = await Axios.post(`topics/polls/${pollId}/vote/`, {
          options,
        })
        toast.success('Vote successfully')
        return response.data
      } catch (error) {
        let message = 'Failed to vote, please contact support if issue persists'
        if (error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    getVotedUsers: async (option_id) => {
      try {
        const response = await Axios.get(`topics/polls/voted-users/`, {
          params: { option_id },
        })
        return response.data
      } catch (error) {
        let message = 'Failed to get, please contact support if issue persists'
        if (error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    getUserVotedPolls: async ({ username, page = 1, pageSize = 10, category }) => {
      try {
        const response = await Axios.get(`topics/polls/voted-polls/`, {
          params: { username, page, page_size: pageSize, category: category || undefined },
        })
        return response.data
      } catch (error) {
        let message = 'Failed to get, please contact support if issue persists'
        if (error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    getAllVotes: async (pollId) => {
      try {
        const response = await Axios.get(`topics/polls/all-votes/`, {
          params: { poll_id: pollId },
        })
        return response.data
      } catch (error) {
        let message = 'Failed to get, please contact support if issue persists'
        if (error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    getUserVotedCategories: async (username) => {
      try {
        const response = await Axios.get(`users/voted-categories/`, { params: { username } })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
        throw message
      }
    },
  })),
)
