import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'src/config/api'
import { toast } from 'react-toastify'

const role = localStorage.getItem('dms_role')

export const getAllStores = createAsyncThunk(
  'organisers/all',
  async ({ page = 1, page_size = 10 }, { rejectWithValue }) => {
    try {
      let URL = `organisers/?page=${page}&page_size=${page_size}`
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get stores failed')
        throw error
      }
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get stores failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getStoreWithId = createAsyncThunk(
  'organisers/get-detail',
  async (id, { rejectWithValue }) => {
    try {
      let URL = `organisers/${id}/`
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get detail failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get detail failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getStoreWithSlug = createAsyncThunk(
  'organisers/get-detail-by-slug',
  async (slug, { rejectWithValue }) => {
    try {
      let URL = `store/${slug}/`
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get store detail failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get store detail failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateStoreWithId = createAsyncThunk(
  'organisers/update-detail',
  async (store, { rejectWithValue }) => {
    try {
      let URL = `${role === 'ADMIN' ? 'admin/' : ''}organisers/${store.id}/`
      const response = await Axios.put(URL, store.data)
      toast.success('Update successfully!')

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Update failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Update failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const deleteStoreWithId = createAsyncThunk(
  'organisers/delete-organiser',
  async (id, { rejectWithValue }) => {
    try {
      let URL = `${role === 'ADMIN' ? 'admin/' : ''}organisers/${id}/`
      const response = await Axios.delete(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Update failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Update failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getCustomerCoupons = createAsyncThunk(
  'stats/store-customers',
  async ({ page = 1, page_size = 10, filter }, { rejectWithValue }) => {
    try {
      let URL = `dashboard/customers-coupons/?page=${page}&page_size=${page_size}`
      if (filter) {
        for (const [key, value] of Object.entries(filter)) {
          URL += `&${key}=${value}`
        }
      }
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get stats failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get stats failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

const savedSlice = createSlice({
  name: 'stores',
  initialState: {
    loading: false,
    allStores: null,
    store: null,
    storeSlug: null,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    errors: null,
  },
  reducers: {
    resetStores: (state) => {
      state.loading = false
      state.isCreated = false
      state.isUpdated = false
      state.isDeleted = false
      state.store = null
      state.storeSlug = null
      state.errors = null
    },
  },
  extraReducers: (builder) => {
    // get all stores
    builder.addCase(getAllStores.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAllStores.fulfilled, (state, action) => {
      state.loading = false
      state.allStores = action.payload
    })
    builder.addCase(getAllStores.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        all: action.payload,
      }
    })
    // get store with id
    builder.addCase(getStoreWithId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getStoreWithId.fulfilled, (state, action) => {
      state.loading = false
      state.store = action.payload
    })
    builder.addCase(getStoreWithId.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        detail: action.payload,
      }
    })
    // get store with slug
    builder.addCase(getStoreWithSlug.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getStoreWithSlug.fulfilled, (state, action) => {
      state.loading = false
      state.storeSlug = action.payload
    })
    builder.addCase(getStoreWithSlug.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        detailSlug: action.payload,
      }
    })
    // update store with id
    builder.addCase(updateStoreWithId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateStoreWithId.fulfilled, (state, action) => {
      state.loading = false
      state.isUpdated = true
    })
    builder.addCase(updateStoreWithId.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        detail: action.payload,
      }
    })
    // delete store with id
    builder.addCase(deleteStoreWithId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteStoreWithId.fulfilled, (state, action) => {
      state.loading = false
      state.isDeleted = true
    })
    builder.addCase(deleteStoreWithId.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        detail: action.payload,
      }
    })
  },
})

export const { resetStores } = savedSlice.actions

export default savedSlice.reducer
