import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'src/config/api'
import { toast } from 'react-toastify'

export const searchCoupons = createAsyncThunk(
  'search/all',
  async ({ filter, page = 1, page_size = 10 }, { rejectWithValue }) => {
    try {
      let URL = `search/?page=${page}&page_size=${page_size}`
      if (filter) {
        for (const [key, value] of Object.entries(filter)) {
          URL += `&${key}=${value}`
        }
      }
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Search vouchers failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Search vouchers failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    loading: false,
    search: null,
    errors: null,
  },
  reducers: {
    resetSearch: (state) => {
      state.loading = false
      state.errors = null
    },
    resetDataSearch: (state) => {
      state.search = null
    },
  },
  extraReducers: (builder) => {
    // get coupons filter
    builder.addCase(searchCoupons.pending, (state) => {
      state.loading = true
    })
    builder.addCase(searchCoupons.fulfilled, (state, action) => {
      state.loading = false
      state.search = action.payload
    })
    builder.addCase(searchCoupons.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        all: action.payload,
      }
    })
  },
})

export const { resetSearch, resetDataSearch } = searchSlice.actions

export default searchSlice.reducer
