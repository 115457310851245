import { useDispatch } from 'react-redux'
import { setFullImageModal } from 'src/store/reducers/main'

const useViewFullImage = () => {
  const dispatch = useDispatch()

  const openFullImage = ({ src, type, images, currentIndex }) => {
    if (Array.isArray(images)) {
      dispatch(setFullImageModal({ open: true, images, currentIndex }))
    } else {
      dispatch(setFullImageModal({ open: true, src, type }))
    }
  }

  return { openFullImage }
}

export default useViewFullImage
