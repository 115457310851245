import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const PhoneInput = ({ phone, onChange, required, errorText }) => {
  return (
    <div>
      <ReactPhoneInput
        containerClass={`phoneInput ${errorText && errorText !== '' ? 'inputError' : ''}`}
        country={'au'}
        value={phone}
        onChange={onChange}
      />
      {errorText !== '' && <p className="invalid-feedback d-block">{errorText}</p>}
    </div>
  )
}

PhoneInput.propTypes = {
  phone: PropTypes.string,
  required: PropTypes.bool,
  errorText: PropTypes.string,
  onChange: PropTypes.func,
}
export default PhoneInput
