import React from 'react'
import PropTypes from 'prop-types'

const NoAuthRoute = ({ component: Component, layout: Layout }) => {
  return (
    <Layout>
      <Component />
    </Layout>
  )
}

NoAuthRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default NoAuthRoute
