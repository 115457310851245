import { UserRole } from 'src/constants'

export const isAuth = () => {
  return !!localStorage.getItem('dms_role')
}

export const isCustomer = (user) => {
  if (user) {
    return user.roles.includes(UserRole.customer)
  } else {
    const role = localStorage.getItem('dms_role')
    return role === UserRole.customer
  }
}
export const isStore = () => {
  const role = localStorage.getItem('dms_role')
  return role === UserRole.store
}
export const isOrganiser = () => {
  const role = localStorage.getItem('dms_role')
  return role === UserRole.organiser
}
export const isAdmin = (user) => {
  if (user) {
    return user.roles.includes(UserRole.admin)
  } else {
    const role = localStorage.getItem('dms_role')
    return role === UserRole.admin
  }
}

export const isModerator = (user) => {
  if (user) {
    return user.roles.includes(UserRole.moderator)
  } else {
    const role = localStorage.getItem('dms_role')
    return role === UserRole.moderator
  }
}
