import React from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'

const Pagination = ({ data, page_size = 10, handlePageClick, className = '' }) => {
  return (
    <div>
      {data && data.totals && data.totals > page_size ? (
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={Math.ceil(data.totals / page_size)}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName={`pagination ${className}`}
          activeClassName="active"
          renderOnZeroPageCount={null}
          disableInitialCallback={true}
        />
      ) : (
        ''
      )}
    </div>
  )
}
Pagination.propTypes = {
  data: PropTypes.object,
  page_size: PropTypes.number,
  handlePageClick: PropTypes.func,
  className: PropTypes.string,
}
export default Pagination
