import React, { useState, useEffect } from 'react'
import 'src/scss/_sendMessage.scss'
import PropTypes from 'prop-types'

import { CFormTextarea, CButton, CSpinner } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilCursor } from '@coreui/icons'
import useWindowSize from 'src/hooks/useWindowSize'

const SendMessage = ({ onSend, contactId, loading, isSent }) => {
  const { width } = useWindowSize()
  const [message, setMessage] = useState('')

  const handleChange = (event) => {
    const { value } = event.target
    setMessage(value)
  }

  const handleSubmit = () => {
    if (message !== '') {
      onSend({ message, contact: contactId })
    }
  }

  useEffect(() => {
    if (isSent) {
      setMessage('')
    }
  }, [isSent])

  return (
    <div className="sendMessage">
      <CFormTextarea
        className="message"
        placeholder="Type message here..."
        rows={width > 480 ? 3 : 1}
        value={message}
        onChange={handleChange}
      ></CFormTextarea>
      <CButton
        color="info"
        className="btnSend"
        disabled={message === '' || loading}
        onClick={handleSubmit}
      >
        {loading ? (
          <CSpinner size="sm" color="light" style={{ marginRight: '4px' }} />
        ) : (
          <CIcon icon={cilCursor} style={{ marginRight: '4px' }} />
        )}
        {width > 480 && 'Send'}
      </CButton>
    </div>
  )
}

SendMessage.propTypes = {
  onSend: PropTypes.func,
  contactId: PropTypes.any,
  loading: PropTypes.bool,
  isSent: PropTypes.bool,
}
export default SendMessage
