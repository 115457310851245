import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'src/config/api'
import { toast } from 'react-toastify'

export const getListNotifications = createAsyncThunk(
  'notifications/list',
  async ({ page = 1, page_size = 10 }, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`notification/?page=${page}&page_size=${page_size}`)
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get notifications failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get notifications failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const makeAsRead = createAsyncThunk(
  'notifications/make-as-read',
  async (id, { rejectWithValue }) => {
    try {
      let URL = `notification/${id}/`
      const response = await Axios.put(URL, { is_read: true })

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get notifications failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get notifications failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const markAsReadAll = createAsyncThunk(
  'notifications/mark-all-read',
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios.put('notification/mark-all-read')
      return response.data
    } catch (error) {
      let msg = 'Markk all notifications as read failed'
      let err = error
      if (error.response.data) {
        msg = error.response.data ? error.response.data.detail : msg
        err = error.response.data
      }
      toast.error(msg)
      return rejectWithValue(err)
    }
  },
)

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    loading: false,
    isRead: false,
    newMessage: false,
    notifications: null,
    messageData: null,
    unreadCount: 0,
    errors: null,
  },
  reducers: {
    resetNotifications: (state) => {
      state.loading = false
      state.isRead = false
      state.errors = null
      state.unreadCount = 0
    },
    saveMessageData: (state, action) => {
      state.messageData = action.payload.message
      state.newMessage = action.payload.isNew
    },
    resetMessageData: (state) => {
      state.messageData = null
      state.newMessage = false
    },
  },
  extraReducers: (builder) => {
    // get notifications
    builder.addCase(getListNotifications.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getListNotifications.fulfilled, (state, action) => {
      state.loading = false
      state.notifications = action.payload
      state.unreadCount = action.payload.results.filter((e) => !e.is_read).length
    })
    builder.addCase(getListNotifications.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        all: action.payload,
      }
    })
    // make as read
    builder.addCase(makeAsRead.pending, (state) => {
      state.loading = true
    })
    builder.addCase(makeAsRead.fulfilled, (state, action) => {
      state.loading = false
      state.isRead = true
    })
    builder.addCase(makeAsRead.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        makeAsRead: action.payload,
      }
    })
    builder.addCase(markAsReadAll.fulfilled, (state, action) => {
      state.unreadCount = 0
    })
  },
})

export const { resetNotifications, saveMessageData, resetMessageData } = notificationsSlice.actions

export default notificationsSlice.reducer
