import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CNavbar, CContainer, CNavbarBrand, CButton } from '@coreui/react'
import classNames from 'classnames'
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai'

import { onToggleSidebar } from 'src/store/reducers/main'
import routes from 'src/navigations/routes'
import UploadingMenu from './UploadingMenu'

const HeaderMobile = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.users)
  const { openSidebar } = useSelector((state) => state.main)
  const prevScrollPos = useRef(0)

  const handleScroll = () => {
    const currentScrollPos = window.scrollY

    if (currentScrollPos > prevScrollPos.current) {
      document.getElementById('header-mobile').classList.add('hide')
    } else {
      document.getElementById('header-mobile').classList.remove('hide')
    }
    if (currentScrollPos === 0) {
      document.getElementById('header-mobile').classList.add('atTop')
    } else {
      document.getElementById('header-mobile').classList.remove('atTop')
    }
    prevScrollPos.current = currentScrollPos
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <CNavbar
      id="header-mobile"
      expand="lg"
      colorScheme="light"
      className={classNames('py-3 atTop')}
    >
      <CContainer>
        <CButton
          variant="ghost"
          color="link"
          className="me-4 text-xl text-info"
          onClick={() => dispatch(onToggleSidebar(!openSidebar))}
        >
          {openSidebar ? <AiOutlineDoubleLeft /> : <AiOutlineDoubleRight />}
        </CButton>
        <CNavbarBrand
          onClick={() => navigate(routes.TOPICS)}
          style={{ fontWeight: 700, cursor: 'pointer' }}
        >
          SavingOut
        </CNavbarBrand>
        <div className="d-flex gap-2 justify-content-end">
          <UploadingMenu />
          {!user && (
            <>
              {/* <CButton
          size="sm"
          className="fw-medium"
          variant="outline"
          color="success"
          onClick={() => navigate(routes.REGISTER_ORGANISER)}
        >
          Register For Organiser
        </CButton> */}
              <CButton
                size="sm"
                className="fw-medium"
                variant="outline"
                color="info"
                onClick={() => navigate(routes.LOGIN)}
              >
                Login
              </CButton>
              <CButton
                size="sm"
                className="fw-medium"
                variant="outline"
                color="warning"
                onClick={() => navigate(routes.REGISTER)}
              >
                Sign Up
              </CButton>
            </>
          )}
        </div>
      </CContainer>
    </CNavbar>
  )
}

export default HeaderMobile
