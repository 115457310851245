import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'
import {
  getListNotifications,
  makeAsRead,
  markAsReadAll,
  resetNotifications,
} from 'src/store/reducers/notifications'

import CIcon from '@coreui/icons-react'
import { cilEnvelopeClosed, cilEnvelopeOpen } from '@coreui/icons'
import { CButton } from '@coreui/react'

import 'src/scss/customers/_notifications.scss'
import useFirebaseMessage from 'src/hooks/useFirebaseMessage'
import { isAdmin, isCustomer, isStore } from 'src/utils/role'
import routes from 'src/navigations/routes'
import { TopicStatus } from 'src/constants'
import classNames from 'classnames'
import { setNotificationModal } from 'src/store/reducers/main'

const PAGE_SIZE = 10

const MenuNotifications = ({ onSelectNoti }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { subscribe, unsubscribe } = useFirebaseMessage()
  const { notifications, isRead } = useSelector((state) => state.notifications)

  const [query, setQuery] = useState({
    page: 1,
    page_size: PAGE_SIZE,
  })

  useEffect(() => {
    if (isRead === true) {
      dispatch(getListNotifications(query))
      dispatch(resetNotifications())
    }
  }, [isRead])

  useEffect(() => {
    const fcmHandler = (data) => {
      dispatch(getListNotifications(query))
    }
    subscribe(fcmHandler)
    return () => {
      unsubscribe(fcmHandler)
    }
  }, [])

  const onMarkAllRead = async () => {
    await dispatch(markAsReadAll())
    await dispatch(getListNotifications({}))
  }

  const onClickNotification = (notify) => {
    dispatch(makeAsRead(notify.id))

    if (notify.notification_type === 'order') {
      navigate(isStore() ? routes.SETTINGS_STORE_ORDERS : routes.SETTING_USER_ORDERS)
    }
    if (notify.notification_type === 'contact') {
      navigate(isStore() ? routes.SETTINGS_STORE_ISSUES : routes.SETTING_USER_ISSUES)
    }
    if (notify.notification_type === 'contactreply') {
      navigate(
        `${isStore() ? routes.SETTINGS_STORE_ISSUES : routes.SETTING_USER_ISSUES}?contact=${
          notify.content_object && notify.content_object.id
        }`,
      )
    }
    if (notify.notification_type === 'storerate') {
      if (isCustomer()) {
        navigate(
          `/store/${
            (notify.content_object &&
              notify.content_object.store_data &&
              notify.content_object.store_data.storename) ||
            ''
          }`,
        )
      } else {
        navigate(
          `${routes.SETTINGS_STORE_FEEDBACKS}?feedback=${
            (notify.content_object && notify.content_object.id) || ''
          }`,
        )
      }
    }
    if (notify.notification_type === 'storeratevote') {
      if (isCustomer()) {
        navigate(
          `/store/${
            (notify.content_object &&
              notify.content_object.storerate_data &&
              notify.content_object.storerate_data.store_data &&
              notify.content_object.storerate_data.store_data.storename) ||
            ''
          }`,
        )
      } else {
        navigate(
          `${routes.SETTINGS_STORE_FEEDBACKS}?feedback=${
            (notify.content_object && notify.content_object.id) || ''
          }`,
        )
      }
    }
    if (notify.notification_type === 'storeratereply') {
      if (isCustomer()) {
        navigate(
          `/store/${
            (notify.content_object &&
              notify.content_object.storerate_data &&
              notify.content_object.storerate_data.store_data &&
              notify.content_object.storerate_data.store_data.storename) ||
            ''
          }`,
        )
      } else {
        navigate(
          `${routes.SETTINGS_STORE_FEEDBACKS}?feedback=${
            (notify.content_object && notify.content_object.storerate) || ''
          }`,
        )
      }
    }
    if (notify.notification_type === 'couponsharecode') {
      if (isCustomer() && notify.content_object && notify.content_object.coupon_data) {
        navigate(
          `/store/${
            (notify.content_object.coupon_data.store_data &&
              notify.content_object.coupon_data.store_data.storename) ||
            ''
          }?coupon_code=${notify.content_object.coupon_data.coupon_code || ''}${
            notify.content_object.coupon_data.is_privated ? '&is_private=true' : ''
          }`,
        )
      }
    }
    if (notify.notification_type === 'topicreply') {
      if (notify.content_object) {
        navigate(`${routes.TOPICS}/${notify.content_object.topic_id}`)
      }
    }
    if (notify.notification_type === 'topic') {
      if (notify.content_object && notify.content_object.status === TopicStatus.approved) {
        navigate(`${routes.TOPICS}/${notify.content_object.id}`)
      }
    }
    if (notify.notification_type === 'followingparticipation') {
      if (notify.content_object && notify.content_object.schedule) {
        navigate(`${routes.TOPICS}/${notify.content_object.schedule.topic.id}`)
      }
    }
    onSelectNoti(notify)
  }

  return (
    <div className="menuNotification">
      <div className="notifyHeader">
        <h6>Notifications</h6>
        <div className="d-flex align-items-center">
          <CButton size="sm" color="link" className="text-decoration-none" onClick={onMarkAllRead}>
            Mark all as read
          </CButton>
          <CButton
            size="sm"
            color="link"
            className="text-sm"
            onClick={() => {
              navigate(routes.NOTIFICATIONS)
              dispatch(setNotificationModal(false))
            }}
          >
            View all
          </CButton>
        </div>
      </div>
      <div className="d-flex flex-column px-2 pt-4 gap-1">
        {notifications && notifications.results && notifications.results.length > 0 ? (
          notifications.results.map((item, i) => (
            <div
              key={i}
              onClick={() => onClickNotification(item)}
              style={{ cursor: 'pointer' }}
              className={classNames(
                'd-flex flex-column flex-md-row align-items-md-center justify-content-between gap-2',
                'px-2 px-md-3 py-3 rounded text-decoration-none text-black border',
                item.is_read && 'bg-light border-0',
              )}
            >
              <div className="d-flex align-items-center flex-1 gap-3">
                <div className="d-none d-md-block">
                  <CIcon icon={!item.is_read ? cilEnvelopeClosed : cilEnvelopeOpen} />
                </div>
                <div className="text-sm flex-1" style={{ whiteSpace: 'pre-line' }}>
                  {item.message}
                </div>
              </div>
              <div className="text-sm text-secondary text-end" style={{ minWidth: 120 }}>
                {item.created_at ? moment(item.created_at).fromNow() : ''}
              </div>
            </div>
          ))
        ) : (
          <div className="emptyNotify">
            <CIcon icon={cilEnvelopeOpen} size="xxl" />
            <p>No Data</p>
          </div>
        )}
      </div>

      {/* <Link to="/" className="text-decoration-none text-black">
        <CIcon size="xl" icon={cilBell} />
        <span className="message">Something else here</span>
        <span className="time">3 min ago</span>
      </Link> */}
    </div>
  )
}

MenuNotifications.propTypes = {
  onSelectNoti: PropTypes.func,
}
export default MenuNotifications
