import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'src/config/api'
import { toast } from 'react-toastify'

export const getAll = createAsyncThunk(
  'feedback/all',
  async ({ filter, page = 1, page_size = 10 }, { rejectWithValue }) => {
    try {
      let URL = `rate/?page=${page}&page_size=${page_size}`
      if (filter) {
        for (const [key, value] of Object.entries(filter)) {
          URL += `&${key}=${value}`
        }
      }
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get all feedbacks failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get all feedbacks failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const createFeedback = createAsyncThunk(
  'feedback/create',
  async (data, { rejectWithValue }) => {
    try {
      let formData = new FormData()
      if (data.images.length > 0) {
        data.images.forEach((file) => {
          formData.append('images', file, file.name)
        })
      }
      formData.append('rate', data.rate)
      formData.append('feedback', data.feedback)
      formData.append('organiser', data.organiser)
      const response = await Axios.post('rate/', formData)

      return response.data
    } catch (error) {
      console.log('🚀 ~ file: feedback.js:50 ~ error:', error)
      if (!error.response) {
        toast.error('Create feedback failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Create feedback failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getContactWithId = createAsyncThunk(
  'contact/get-detail',
  async (id, { rejectWithValue }) => {
    try {
      let URL = `contact/${id}`
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get contact detail failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get contact detail failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateContactWithId = createAsyncThunk(
  'contact/update-contact',
  async (contact, { rejectWithValue }) => {
    try {
      let URL = `contact/${contact.id}/`
      const response = await Axios.put(URL, contact)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get contact detail failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get contact detail failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getRepliestWithId = createAsyncThunk(
  'feedback/get-detail-replies',
  async ({ id, page = 1, page_size = 10 }, { rejectWithValue }) => {
    try {
      let URL = `rate/reply/${id}/?page=${page}&page_size=${page_size}`
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get replies failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get replies failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const createReply = createAsyncThunk(
  'feedback/create-reply',
  async (data, { rejectWithValue }) => {
    try {
      let URL = `rate/reply/`
      const response = await Axios.post(URL, data)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Create reply failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Create reply failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const voteFeedback = createAsyncThunk(
  'feedback/vote',
  async ({ id, isVote, type }, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: isVote ? 'POST' : 'DELETE',
        url: `rate/${id}/vote/`,
        params: {
          type,
        },
      })

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Vote failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Vote failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const voteReplyFeedback = createAsyncThunk(
  'feedback/vote',
  async ({ id, isVote, type }, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: isVote ? 'POST' : 'DELETE',
        url: `rate/reply/${id}/vote/`,
        params: {
          type,
        },
      })

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Vote failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Vote failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    loading: false,
    loadingReplies: false,
    submitting: false,
    isCreated: false,
    isUpdated: false,
    isReplyCreated: false,
    isVoted: false,
    list: null,
    contact: null,
    replies: null,
    errors: null,
  },
  reducers: {
    resetFeedback: (state) => {
      state.loading = false
      state.loadingReplies = false
      state.submitting = false
      state.isCreated = false
      state.isUpdated = false
      state.isReplyCreated = false
      state.isVoted = false
      state.errors = null
    },
    resetStateReply: (state) => {
      state.isReplyCreated = false
    },
  },
  extraReducers: (builder) => {
    // get all contact
    builder.addCase(getAll.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = false
      state.list = action.payload
    })
    builder.addCase(getAll.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        list: action.payload,
      }
    })
    // get contact detail
    builder.addCase(getContactWithId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getContactWithId.fulfilled, (state, action) => {
      state.loading = false
      state.contact = action.payload
    })
    builder.addCase(getContactWithId.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        detail: action.payload,
      }
    })
    // get replies contact
    builder.addCase(getRepliestWithId.pending, (state) => {
      state.loadingReplies = true
    })
    builder.addCase(getRepliestWithId.fulfilled, (state, action) => {
      state.loadingReplies = false
      state.replies = action.payload
    })
    builder.addCase(getRepliestWithId.rejected, (state, action) => {
      state.loadingReplies = false
      state.errors = {
        replies: action.payload,
      }
    })
    // create contact
    builder.addCase(createFeedback.pending, (state) => {
      state.submitting = true
    })
    builder.addCase(createFeedback.fulfilled, (state, action) => {
      state.submitting = true
      state.isCreated = true
    })
    builder.addCase(createFeedback.rejected, (state, action) => {
      state.submitting = true
      state.errors = {
        create: action.payload,
      }
    })
    // update contact
    builder.addCase(updateContactWithId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateContactWithId.fulfilled, (state, action) => {
      state.loading = false
      state.isUpdated = true
    })
    builder.addCase(updateContactWithId.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        update: action.payload,
      }
    })
    // create reply contact
    builder.addCase(createReply.pending, (state) => {
      state.loadingReplies = true
    })
    builder.addCase(createReply.fulfilled, (state, action) => {
      state.loadingReplies = false
      state.isReplyCreated = true
    })
    builder.addCase(createReply.rejected, (state, action) => {
      state.loadingReplies = false
      state.errors = {
        createReply: action.payload,
      }
    })
  },
})

export const { resetFeedback, resetStateReply } = feedbackSlice.actions

export default feedbackSlice.reducer
