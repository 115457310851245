import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CLink, CSpinner } from '@coreui/react'
import ClickableImage from 'src/components/common/ClickableImage'
import { formatNumber, formatTimeAgo } from 'src/utils/format'
import EllipsisTextHeight from 'src/components/common/EllipsisTextHeight'
import CIcon from '@coreui/icons-react'
import { cilClock, cilThumbUp } from '@coreui/icons'
import MentionableText from 'src/components/common/MentionableText'
import routes from 'src/navigations/routes'
import { useTopicReplyStore } from 'src/store/useTopicReplyStore'
import { useShallow } from 'zustand/react/shallow'

const PostedQuestion = ({ username }) => {
  const [loading, setLoading] = useState(false)
  const [questions, setQuestions] = useState([])
  const { getTopicPostedQuestions } = useTopicReplyStore(
    useShallow((state) => ({
      getTopicPostedQuestions: state.getTopicPostedQuestions,
    })),
  )

  const fetchQuestions = async () => {
    try {
      setLoading(true)
      const res = await getTopicPostedQuestions({ username })
      setQuestions(res)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchQuestions()
  }, [username])

  return (
    <div className="d-flex flex-column gap-4">
      {!loading && questions.length === 0 && <div>No question yet</div>}
      {questions.map((e) => {
        const reply = e.reply
        const schedule = e.schedule
        return (
          <div key={e.id} className="d-flex flex-column gap-2 flex-grow-1">
            <div className="d-flex flex-column flex-md-row gap-0 gap-md-4">
              <CLink
                className="text-sm text-decoration-none text-info"
                target="_blank"
                href={`${routes.TOPICS}/${reply.topic.id}`}
                style={{ whiteSpace: 'pre-line' }}
              >
                {reply.topic.title}
              </CLink>
              <div className="d-flex gap-3 text-black-50 text-xs fw-medium">
                <div className="d-flex align-items-center gap-2">
                  <CIcon icon={cilClock} />
                  {formatTimeAgo(schedule.created_at)}
                </div>
                <div className="d-flex align-items-center gap-2">
                  <CIcon icon={cilThumbUp} />
                  {formatNumber(reply.votes.total_upvote - reply.votes.total_downvote)}
                </div>
              </div>
            </div>
            <div>
              <EllipsisTextHeight maxHeight={100} className="flex-grow-1 text-sm">
                <MentionableText text={reply.content} disabled />
              </EllipsisTextHeight>
            </div>
            <div className="d-flex gap-2">
              {reply.images.map((image) => (
                <ClickableImage
                  key={image.uuid}
                  src={image.file}
                  width={50}
                  height={50}
                  style={{ cursor: 'default' }}
                  onClick={() => null}
                />
              ))}
            </div>
          </div>
        )
      })}
      {loading && <CSpinner className="mx-auto" color="primary" />}
    </div>
  )
}

PostedQuestion.propTypes = {
  username: PropTypes.string,
}

export default PostedQuestion
