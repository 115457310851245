import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'src/config/api'
import { toast } from 'react-toastify'

export const getAllSavedCoupons = createAsyncThunk(
  'saved/all',
  async ({ page = 1, page_size = 10, filter }, { rejectWithValue }) => {
    try {
      let URL = `vouchers/?page=${page}&page_size=${page_size}`
      if (filter) {
        for (const [key, value] of Object.entries(filter)) {
          URL += `&${key}=${value}`
        }
      }
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get categories failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get categories failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const saveCoupon = createAsyncThunk(
  'saved/save-coupon',
  async ({ code, quantity, token }, { rejectWithValue }) => {
    try {
      let URL = `vouchers/take-code/`
      const response = await Axios.post(URL, { code, quantity, token })
      toast.success('Take voucher successfully!')
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Save voucher failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Save voucher failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

const savedSlice = createSlice({
  name: 'saved',
  initialState: {
    loading: false,
    saved: null,
    isSaved: false,
    errors: null,
  },
  reducers: {
    resetSaved: (state) => {
      state.loading = false
      state.isSaved = false
      state.errors = null
    },
  },
  extraReducers: (builder) => {
    // get coupons saved
    builder.addCase(getAllSavedCoupons.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAllSavedCoupons.fulfilled, (state, action) => {
      state.loading = false
      state.saved = action.payload
    })
    builder.addCase(getAllSavedCoupons.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        all: action.payload,
      }
    })
    // save coupon
    builder.addCase(saveCoupon.pending, (state) => {
      state.loading = true
      state.isSaved = false
    })
    builder.addCase(saveCoupon.fulfilled, (state, action) => {
      state.loading = false
      state.isSaved = true
    })
    builder.addCase(saveCoupon.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        all: action.payload,
      }
    })
  },
})

export const { resetSaved } = savedSlice.actions

export default savedSlice.reducer
