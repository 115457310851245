import { toast } from 'react-toastify'
import Axios from 'src/config/api'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const useTopicStore = create()(
  immer((set, get) => ({
    suggestedTopics: [],
    suggestedUsers: [],
    getSearchSuggestions: async () => {
      try {
        const res = await Axios.get(`topics/search-suggestions/`)
        set({ suggestedTopics: res.data.topics, suggestedUsers: res.data.users })
        return res.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
  })),
)
