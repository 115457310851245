import React from 'react'
import styles from './Footer.module.scss'

import { Link } from 'react-router-dom'
import { CContainer } from '@coreui/react'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <CContainer>
        <div className={styles.content}>
          <div className="d-flex justify-content-center flex-grow-1 gap-4">
            <div className={styles.menu}>
              {/* <Link to="/about-us">About Us</Link> */}
              <Link to="/how-it-works">How it Works</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-of-use">Terms of Use</Link>
            </div>
          </div>
        </div>
      </CContainer>
    </footer>
  )
}

export default Footer
