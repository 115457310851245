import { toast } from 'react-toastify'
import Axios from 'src/config/api'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const useBadgeStore = create()(
  immer((set, get) => ({
    loading: false,
    getUserBadges: async ({ username, topicId, name }) => {
      try {
        set({ loading: true })
        const response = await Axios.get(`badges/`, {
          params: {
            username,
            topic_id: topicId,
            name,
          },
        })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      } finally {
        set({ loading: false })
      }
    },
  })),
)
