import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { isAdmin, isModerator } from 'src/utils/role'
import routes from 'src/navigations/routes'
import AdminDesktop from './desktop'
import AdminMobile from './mobile'
import useWindowSize from 'src/hooks/useWindowSize'

const Admin = () => {
  const navigate = useNavigate()
  const { isMobile } = useWindowSize()
  const { role } = useSelector((state) => state.users)

  useEffect(() => {
    if (!isAdmin() && !isModerator()) navigate(routes.HOME)
  }, [role, navigate])

  if (isMobile()) return <AdminMobile />
  return <AdminDesktop />
}

export default Admin
