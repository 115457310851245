import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { CAvatar, CBadge, CCollapse, CImage, CSidebar, CSidebarNav } from '@coreui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AiOutlineDoubleRight } from 'react-icons/ai'
import { cilAccountLogout, cilBell, cilMinus, cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CSSTransition } from 'react-transition-group'

// sidebar nav config
import { _navAdmin, _navStore, _navPublic, _navUser, _navModerator } from 'src/_nav'
import { isAdmin, isAuth, isOrganiser } from 'src/utils/role'
import { onToggleSidebar, setNotificationModal } from 'src/store/reducers/main'
import { isModerator } from 'src/utils/role'
import { IMG_PLACEHOLDER, LANGUAGES } from 'src/constants'
import BadgeLevel from 'src/components/BadgeLevel'
import styles from './styles.module.scss'
import classNames from 'classnames'
import useWindowSize from 'src/hooks/useWindowSize'
import NotificationModal from 'src/views/app/NotificationModal'
import { getListNotifications } from 'src/store/reducers/notifications'
import routes from 'src/navigations/routes'
import { logout } from 'src/store/reducers/auth'
import IconAllLang from 'src/assets/images/ic_all_language.png'
import { useUserVenueStore } from 'src/store/useUserVenueStore'
import { useShallow } from 'zustand/react/shallow'
import { usePreferLanguageStore } from 'src/store/usePreferLanguageStore'
import UserAvatar from '../UserAvatar'

const NavItem = ({ item, onClick }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { openSidebar } = useSelector((state) => state.main)
  const { isMobile } = useWindowSize()
  const { name, icon, to } = item

  return (
    <div
      className={classNames('px-4', styles.menuItem)}
      onClick={() => {
        if (onClick) onClick()
        else {
          if (isMobile()) dispatch(onToggleSidebar(false))
          navigate(to)
        }
      }}
    >
      <div className={classNames(location.pathname === to && 'text-info', styles.icon)}>
        {icon && icon}
      </div>
      <CSSTransition in={isMobile() || openSidebar} timeout={300} classNames={'fade'} unmountOnExit>
        <span className={styles.content}>{name}</span>
      </CSSTransition>
    </div>
  )
}

NavItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.node,
    to: PropTypes.string,
  }),
  onClick: PropTypes.func,
}

const NavGroup = ({ item }) => {
  const { name, icon, items } = item
  const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
      <div
        className="text-md d-flex align-items center justify-content-between p-3"
        onClick={() => setOpen((e) => !e)}
      >
        <div className="d-flex">
          {icon && icon}
          {name && name}
        </div>
        <CIcon className="my-auto" icon={open ? cilMinus : cilPlus} />
      </div>
      <CCollapse visible={open} className="ps-4">
        {items.map((e, i) =>
          e.items ? <NavGroup key={i} item={e} /> : <NavItem key={i} item={e} />,
        )}
      </CCollapse>
    </React.Fragment>
  )
}

NavGroup.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.node,
    items: PropTypes.array,
  }),
}

const Sidebar = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { openSidebar } = useSelector((state) => state.main)
  const { user } = useSelector((state) => state.users)
  const { unreadCount } = useSelector((state) => state.notifications)
  const language = usePreferLanguageStore((state) => state.language)
  const { isMobile } = useWindowSize()

  const { openModal: openSelectLangModal } = usePreferLanguageStore(
    useShallow((state) => ({
      openModal: state.openModal,
    })),
  )

  const { getVenues } = useUserVenueStore(
    useShallow((state) => ({
      getVenues: state.getVenues,
    })),
  )

  useEffect(() => {
    if (user) {
      dispatch(getListNotifications({ page: 1, page_size: 10 }))
      getVenues()
    }
  }, [user])

  const menu = useMemo(() => {
    if (isAdmin()) return _navAdmin
    if (isModerator()) return _navModerator
    if (isOrganiser()) return _navStore
    if (isAuth()) return _navUser
    return _navPublic
  }, [])

  const preferLanguage = useMemo(() => {
    if (!language) return null
    return LANGUAGES.find((e) => e.id === language)
  }, [language])

  const handleLogout = () => {
    dispatch(logout())
  }

  if (!isAuth() && !isMobile()) return null

  return (
    <CSidebar
      position="fixed"
      style={{ listStyle: 'none' }}
      className={classNames(styles.sidebar, openSidebar && styles.expand, 'shadow-sm')}
      visible={(isMobile() && openSidebar) || !isMobile()}
      onHide={() => {
        dispatch(onToggleSidebar(false))
      }}
    >
      <CSidebarNav>
        {user ? (
          <div className="d-flex flex-column py-4 bg-info gap-4">
            {!isMobile() && (
              <div className="px-4">
                <div
                  className={classNames(styles.toggle, openSidebar && styles.rotate)}
                  onClick={() => dispatch(onToggleSidebar(!openSidebar))}
                >
                  <AiOutlineDoubleRight className="text-info" fontSize={20} />
                </div>
              </div>
            )}
            <div className={classNames('px-4', styles.menuItem)}>
              <div className={styles.icon}>
                <UserAvatar
                  user={user}
                  width={30}
                  height={30}
                  onClick={() => navigate(routes.SETTING_PROFILE)}
                />
              </div>
              <CSSTransition
                in={isMobile() || openSidebar}
                timeout={200}
                classNames={'fade'}
                unmountOnExit
              >
                <div className={classNames(styles.content, 'd-flex flex-column text-white')}>
                  <span className="fw-medium">@{user.username}</span>
                  <div>
                    <BadgeLevel level={user.profile.level} />
                  </div>
                </div>
              </CSSTransition>
            </div>
          </div>
        ) : null}
        <div className="d-flex flex-column gap-2 pt-4">
          {isAuth() && (
            <div
              className={classNames('px-4', styles.menuItem)}
              onClick={() => dispatch(setNotificationModal(true))}
            >
              <div className={classNames('position-relative', styles.icon)}>
                <CIcon icon={cilBell} size="lg" />
                {unreadCount > 0 && (
                  <CBadge
                    size="sm"
                    color="danger"
                    style={{ position: 'absolute', top: -5, right: -10 }}
                    shape="rounded-pill"
                  >
                    {unreadCount > 5 ? '5+' : unreadCount}{' '}
                    <span className="visually-hidden">unread messages</span>
                  </CBadge>
                )}
              </div>
              <CSSTransition
                in={isMobile() || openSidebar}
                timeout={300}
                classNames={'fade'}
                unmountOnExit
              >
                <span className={styles.content}>Notifications</span>
              </CSSTransition>
            </div>
          )}
          {menu.map((item, index) =>
            item.items ? <NavGroup item={item} key={index} /> : <NavItem key={index} item={item} />,
          )}
          {/* <NavItem
            item={{
              icon: <CImage src={IconAllLang} width={20} />,
              name: preferLanguage ? `${preferLanguage.label}` : 'All languages',
            }}
            onClick={() => openSelectLangModal()}
          /> */}
        </div>
      </CSidebarNav>
      <div className="pb-4">
        <hr />
        <div
          className={classNames(styles.menuItem, 'px-4')}
          style={{ cursor: 'pointer' }}
          onClick={handleLogout}
        >
          <div className={styles.icon}>
            <CIcon icon={cilAccountLogout} />
          </div>
          <CSSTransition
            in={isMobile() || openSidebar}
            timeout={200}
            classNames={'fade'}
            unmountOnExit
          >
            <span className={classNames(styles.content)}>Logout</span>
          </CSSTransition>
        </div>
      </div>
      <NotificationModal />
    </CSidebar>
  )
}

export default React.memo(Sidebar)
