import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { usePublicUserStore } from 'src/store/usePublicUserStore'
import { MdArticle, MdCategory, MdQuiz, MdThumbUp } from 'react-icons/md'
import { AiOutlineSchedule } from 'react-icons/ai'
import { FaComment } from 'react-icons/fa'
import classNames from 'classnames'
import { IoMdMailOpen } from 'react-icons/io'
import { CTooltip } from '@coreui/react'

const Summary = ({ stats, center }) => {
  const statsList = useMemo(() => {
    const replyCount = get(stats, 'count_replies', 0)
    const voteCount = get(stats, 'count_votes', 0)
    const topicCount = get(stats, 'count_topics', 0)
    const pollCount = get(stats, 'count_polls', 0)
    const invitedCount = get(stats, 'count_invited', 0)
    const joinedCount = get(stats, 'count_joined', 0)
    const contributedCount = get(stats, 'count_contributed', 0)
    const categoryCount = get(stats, 'count_categories', 0)
    const referredCount = get(stats, 'count_referred', 0)
    return [
      // {
      //   icon: <MdCategory size={20} />,
      //   label: 'activities',
      //   value: categoryCount,
      //   desc: 'Number of activities voted on poll or participated',
      // },
      // {
      //   icon: <MdQuiz size={20} />,
      //   label: 'polls',
      //   value: pollCount,
      //   desc: 'Number of polls voted',
      // },
      {
        icon: <AiOutlineSchedule size={20} />,
        label: 'contributed events',
        value: contributedCount,
        desc: 'Number of events contributed',
      },
      {
        icon: <AiOutlineSchedule size={20} />,
        label: 'participated events',
        value: joinedCount,
        desc: 'Number of events participated',
      },
      {
        icon: <AiOutlineSchedule size={20} />,
        label: 'referred events',
        value: referredCount,
        desc: 'Number of events referred',
      },
      // {
      //   icon: <IoMdMailOpen size={20} />,
      //   label: 'invites',
      //   value: invitedCount,
      //   desc: 'Number of people invited to the events',
      // },
      // {
      //   icon: <FaComment size={20} />,
      //   label: 'replies',
      //   value: replyCount,
      //   desc: 'Number of replies received',
      // },
      // {
      //   icon: <MdThumbUp size={20} />,
      //   label: 'votes',
      //   value: voteCount,
      //   desc: 'Number of votes received',
      // },
      // {
      //   icon: <MdArticle size={20} />,
      //   label: 'topics',
      //   value: topicCount,
      //   desc: 'Number of topics created',
      // },
    ]
  }, [stats])

  return (
    <div className="d-flex flex-column gap-4">
      <h3 className={classNames('text-lg', center && 'text-center')}>Activity Summary</h3>
      <div
        className={classNames(
          'd-flex align-items-center flex-wrap gap-4',
          center && 'justify-content-center',
        )}
      >
        {statsList.map((e) => (
          <CTooltip key={e.label} content={e.desc}>
            <div style={{ maxWidth: 300 }} className="d-flex p-2 gap-2">
              <div
                className="d-flex align-items-center justify-content-center rounded-circle"
                style={{ width: 50, height: 50, background: '#ebedef' }}
              >
                {e.icon}
              </div>
              <div className="d-flex flex-column text-sm">
                <strong className="text-xl">{e.value}</strong>
                <span>{e.label}</span>
              </div>
            </div>
          </CTooltip>
        ))}
      </div>
    </div>
  )
}

Summary.propTypes = {
  stats: PropTypes.object,
  center: PropTypes.bool,
}

export default Summary
