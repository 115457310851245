import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react'
import classNames from 'classnames'
import { useShallow } from 'zustand/react/shallow'

import { LANGUAGES, LS_SHOW_LANG_SELECT } from 'src/constants'
import { usePreferLanguageStore } from 'src/store/usePreferLanguageStore'
import styles from './styles.module.scss'

const PreferLanguageModal = () => {
  const { open, language } = usePreferLanguageStore((state) => state)
  const { openModal, closeModal, setPreferLanguage } = usePreferLanguageStore(
    useShallow((state) => ({
      openModal: state.openModal,
      closeModal: state.closeModal,
      setPreferLanguage: state.setPreferLanguage,
    })),
  )

  useEffect(() => {
    // if (localStorage.getItem(LS_SHOW_LANG_SELECT) === 'true') {
    //   openModal(true)
    // }
  }, [openModal, language])

  const onSelect = (lang) => {
    localStorage.removeItem(LS_SHOW_LANG_SELECT)
    setPreferLanguage(lang)
    closeModal()
  }

  return (
    <CModal
      size="sm"
      alignment="center"
      visible={open}
      onClose={() => {
        localStorage.removeItem(LS_SHOW_LANG_SELECT)
        closeModal()
      }}
    >
      <CModalHeader>
        <CModalTitle>Language Section</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="d-flex flex-column gap-1">
          <div
            className={classNames(
              'text-center',
              'border rounded p-2',
              language === '' && 'text-white bg-primary',
              styles.item,
            )}
            onClick={() => onSelect('')}
          >
            <span>All</span>
          </div>
          {LANGUAGES.map((e) => {
            return (
              <div
                key={e.id}
                className={classNames(
                  'text-center',
                  'border rounded p-2',
                  language === e.id && 'text-white bg-primary',
                  styles.item,
                )}
                onClick={() => onSelect(e.id)}
              >
                <span>{e.label}</span>
              </div>
            )
          })}
        </div>
      </CModalBody>
    </CModal>
  )
}
PreferLanguageModal.propTypes = {
  open: PropTypes.bool,
}
export default PreferLanguageModal
