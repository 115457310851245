import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'src/config/api'
import { toast } from 'react-toastify'

export const getListOrders = createAsyncThunk(
  'orders/list',
  async ({ page = 1, page_size = 10 }, { rejectWithValue }) => {
    try {
      let URL = `order/?page=${page}&page_size=${page_size}`
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get orders failed')
        throw error
      }
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get orders failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getOrderWithId = createAsyncThunk(
  'orders/get-detail',
  async (id, { rejectWithValue }) => {
    try {
      let URL = `order/${id}/`
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get order detail failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get order detail failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateOrderWithId = createAsyncThunk(
  'orders/update-detail',
  async (order, { rejectWithValue }) => {
    try {
      let URL = `order/${order.id}/`
      const response = await Axios.put(URL, order)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Update order failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Update order failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const createOrder = createAsyncThunk('orders/create', async (order, { rejectWithValue }) => {
  try {
    let URL = `order/`
    const response = await Axios.post(URL, order)
    toast.success('Order sent!')
    return response.data
  } catch (error) {
    if (!error.response) {
      toast.error('Create order failed')
      throw error
    }
    console.log('error.response.data: ', error.response.data)
    if (error.response.data) {
      const msg = error.response.data
        ? error.response.data.detail || error.response.data.message
        : 'Create order failed'

      toast.error(String(msg))
    }
    return rejectWithValue(error.response.data)
  }
})

const orderSlice = createSlice({
  name: 'orders',
  initialState: {
    loading: false,
    orders: null,
    orderDetail: null,
    errors: null,
    isUpdated: false,
    isCreated: false,
  },
  reducers: {
    resetOrders: (state) => {
      state.loading = false
      state.isUpdated = false
      state.isCreated = false
      state.errors = null
    },
  },
  extraReducers: (builder) => {
    // get orders
    builder.addCase(getListOrders.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getListOrders.fulfilled, (state, action) => {
      state.loading = false
      state.orders = action.payload
    })
    builder.addCase(getListOrders.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        all: action.payload,
      }
    })
    // get order detail
    builder.addCase(getOrderWithId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getOrderWithId.fulfilled, (state, action) => {
      state.loading = false
      state.orderDetail = action.payload
    })
    builder.addCase(getOrderWithId.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        detail: action.payload,
      }
    })
    // update order
    builder.addCase(updateOrderWithId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateOrderWithId.fulfilled, (state, action) => {
      state.loading = false
      state.isUpdated = true
      state.orderDetail = action.payload
    })
    builder.addCase(updateOrderWithId.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        detail: action.payload,
      }
    })
    // create order
    builder.addCase(createOrder.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createOrder.fulfilled, (state, action) => {
      state.loading = false
      state.isCreated = true
    })
    builder.addCase(createOrder.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        create: action.payload,
      }
    })
  },
})

export const { resetOrders } = orderSlice.actions

export default orderSlice.reducer
