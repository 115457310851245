import React from 'react'
import PropTypes from 'prop-types'
import { CModal, CModalBody, CModalContent } from '@coreui/react'
import MenuNotifications from 'src/components/customers/notifications/MenuNotifications'
import { useDispatch, useSelector } from 'react-redux'
import { setNotificationModal } from 'src/store/reducers/main'

const NotificationModal = () => {
  const dispatch = useDispatch()
  const { open } = useSelector((state) => state.main.notificationModal)

  return (
    <CModal alignment="center" visible={open} onClose={() => dispatch(setNotificationModal(false))}>
      <CModalContent className="overflow-hidden">
        <CModalBody className="px-0">
          <MenuNotifications onSelectNoti={() => dispatch(setNotificationModal(false))} />
        </CModalBody>
      </CModalContent>
    </CModal>
  )
}
NotificationModal.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
}
export default NotificationModal
