import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'src/config/api'
import { toast } from 'react-toastify'

export const registerDevices = createAsyncThunk(
  'fcm/registerDevices',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`/devices/`, params)
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Register Devices failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          (error.response?.data?.detail || error.response?.data?.message) ??
          'Register Devices failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

const fcmSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    device_info: null,
    errors: null,
  },
  reducers: {
    resetFcm: (state) => {
      state.loading = false
      state.device_info = null
      state.errors = null
    },
  },
  extraReducers: (builder) => {
    // register devices
    builder.addCase(registerDevices.pending, (state) => {
      state.loading = true
    })
    builder.addCase(registerDevices.fulfilled, (state, action) => {
      state.loading = false
      state.device_info = action.payload
    })
    builder.addCase(registerDevices.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        resetPassword: action.payload,
      }
    })
  },
})

export const { resetFcm } = fcmSlice.actions

export default fcmSlice.reducer
