import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import CarouselImages from 'src/components/CarouselImages'
import useViewFullImage from 'src/hooks/useViewFullImage'
import { isPdfFile, isVideoFile } from 'src/utils/url'
import useWindowSize from 'src/hooks/useWindowSize'

const TopicCarousel = ({ topic }) => {
  const { openFullImage } = useViewFullImage()
  const { isMobile } = useWindowSize()
  const [imageHeight, setImageHeight] = useState(400)
  const containerRef = useRef()

  useEffect(() => {
    if (containerRef.current) setImageHeight(Math.min(400, containerRef.current.offsetWidth))
  }, [])

  const onClickCarousel = (page) => {
    if (!isVideoFile(images[page].file) || isMobile()) openFullImage({ images, currentIndex: page })
  }

  const images = useMemo(() => {
    if (!topic) return []
    return topic.images.filter((e) => {
      if (e && e.type === 'application/pdf') return false
      if (isPdfFile(e.file)) return false
      return true
    })
  }, [topic])

  if (!topic || images.length === 0) return null
  return (
    <div ref={containerRef} className="w-100">
      <CarouselImages
        images={images}
        height={imageHeight}
        onClick={(page) => onClickCarousel(page)}
      />
    </div>
  )
}

TopicCarousel.propTypes = {
  topic: PropTypes.any,
}

export default TopicCarousel
