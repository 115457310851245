import React, { useCallback, useEffect, useRef } from 'react'
import 'src/scss/common/_fullscreen.scss'

import { CImage, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux'
import { setFullImageModal } from 'src/store/reducers/main'
import CarouselImages from 'src/components/CarouselImages'
import useWindowSize from 'src/hooks/useWindowSize'

const ViewFullScreenModal = () => {
  const dispatch = useDispatch()
  const { height } = useWindowSize()
  const { open, src, type, images, currentIndex } = useSelector(
    (state) => state.main.fullImageModal,
  )
  const carouselRef = useRef()

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      close()
    }
  }, [])

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === 'ArrowLeft') {
        if (carouselRef.current) carouselRef.current.prev()
      } else if (event.key === 'ArrowRight') {
        if (carouselRef.current) carouselRef.current.next()
      }
    }

    if (open) document.addEventListener('keydown', handleKeyDown)
    else document.removeEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [open])

  useEffect(() => {
    if (open)
      window.onpopstate = () => {
        close()
      }
  }, [open])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)
    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  const close = () => {
    dispatch(setFullImageModal({ open: false, src: '' }))
  }

  return (
    <CModal className="viewFullScreen" size="xl" visible={open} onClose={close}>
      {/* <CModalHeader>
        <CModalTitle></CModalTitle>
      </CModalHeader> */}
      <CModalBody>
        {images ? (
          <CarouselImages
            ref={carouselRef}
            defaultPage={currentIndex}
            images={images}
            height={height * 0.8}
          />
        ) : type === 'video' ? (
          <video controls width="100%" src={`${src}#t=0.01`} style={{ maxHeight: '80vh' }} />
        ) : (
          <div className="viewImg">
            <CImage
              src={src}
              height={Math.min(600, height * 0.8)}
              style={{ objectFit: 'contain' }}
              alt="Full Image"
            />
          </div>
        )}
      </CModalBody>
    </CModal>
  )
}

export default ViewFullScreenModal
