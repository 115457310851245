import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { registerFCMListener } from 'src/services/firebase'

let subscribers = new Set()

const useFirebaseMessage = () => {
  const { user } = useSelector((state) => state.users)

  useEffect(() => {
    if (!user) return
    registerFCMListener((payload) => {
      if (payload.data) {
        for (const cb of subscribers) {
          cb(payload.data)
        }
      }
    })
  }, [user])

  const subscribe = (callback) => {
    subscribers.add(callback)
  }
  const unsubscribe = (callback) => {
    subscribers.delete(callback)
  }

  return { subscribe, unsubscribe }
}

export default useFirebaseMessage
