import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CBadge, CButton, CSpinner } from '@coreui/react'

import { approveTopicAdmin, getTopicDetail, rejectTopicAdmin } from 'src/store/actions/topic.action'
import { TopicStatus, TopicStatusLabel } from 'src/constants'
import ClickableImage from 'src/components/common/ClickableImage'
import styles from './styles.module.scss'
import { isPdfFile } from 'src/utils/url'
import TopicCarousel from 'src/views/topics/TopicCarousel'
import TopicAttachment from 'src/components/TopicAttachment'

const StatusColor = {
  [TopicStatus.pending]: 'warning',
  [TopicStatus.approved]: 'success',
  [TopicStatus.rejected]: 'danger',
}

const TopicDetailAdmin = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const [loading, setLoading] = useState(false)
  const [detail, setDetail] = useState(null)

  const fetchData = useCallback(async () => {
    if (!id) return
    try {
      setLoading(true)
      const res = await dispatch(getTopicDetail(id))
      if (res.error) return
      setDetail(res.payload)
    } finally {
      setLoading(false)
    }
  }, [id, dispatch])

  useEffect(() => {
    fetchData()
  }, [id, fetchData])

  const onApprove = async () => {
    const res = await dispatch(approveTopicAdmin(id))
    if (res.error) return
    fetchData()
  }
  const onReject = async () => {
    const res = await dispatch(rejectTopicAdmin(id))
    if (res.error) return
    fetchData()
  }

  const pdfFiles = useMemo(() => {
    if (!detail) return []
    return detail.images.filter((e) => {
      if (e && e.type === 'application/pdf') return true
      if (isPdfFile(e.file)) return true
      return false
    })
  }, [detail])

  if (!detail) return <div className="p-3" />

  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex align-items-center justify-content-between">
        <CBadge color={StatusColor[detail.status]}>{TopicStatusLabel[detail.status]}</CBadge>
        <div className="d-flex align-items-center justify-content-end gap-2">
          {detail.status !== TopicStatus.approved && (
            <CButton size="sm" color="success" onClick={onApprove}>
              Approve
            </CButton>
          )}
          {detail.status !== TopicStatus.rejected && (
            <CButton size="sm" color="danger" onClick={onReject}>
              Reject
            </CButton>
          )}
        </div>
      </div>
      <div className="p-3">
        {loading && <CSpinner />}
        <h3>{detail.title}</h3>
        {detail.venue && (
          <div>
            <i className="fw-medium text-info text-sm">Venue: {detail.venue}</i>
          </div>
        )}
        <div style={{ whiteSpace: 'pre-line' }}>{detail.content}</div>
        <div className="py-2">
          <TopicCarousel topic={detail} />
        </div>
        <div className="d-flex flex-column gap-1">
          {pdfFiles.map((e) => (
            <TopicAttachment key={e.uuid} file={e} src={e.file} width={30} height={30} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default TopicDetailAdmin
