import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import CIcon from '@coreui/icons-react'
import { cilHandshake, cilStar } from '@coreui/icons'
import { BADGE_COLORS, UserBadgeType } from 'src/constants'
import { useBadgeStore } from 'src/store/useBadgeStore'
import { useShallow } from 'zustand/react/shallow'
import classNames from 'classnames'
import styles from './styles.module.scss'

// const BADGES = {
//   [UserBadgeType.top_distributor]: {
//     icon: cilHandshake,
//     iconColor: '#16ca7c',
//     text: 'Top Contributor',
//   },
//   [UserBadgeType.top_rated]: {
//     icon: cilStar,
//     iconColor: '#f9b115',
//     text: 'Top Rated',
//   },
// }

const UserBadges = ({ username }) => {
  const [badges, setBadges] = useState([])

  const { getUserBadges } = useBadgeStore(
    useShallow((state) => ({
      getUserBadges: state.getUserBadges,
    })),
  )

  useEffect(() => {
    getUserBadges({ username }).then((res) => setBadges(res))
  }, [username])

  const groupedBadges = useMemo(() => {
    return badges.reduce((acc, e) => {
      if (!acc[e.name]) acc[e.name] = { ...e, count: 0 }
      acc[e.name].count++
      return acc
    }, {})
  }, [badges])

  return (
    <div className="d-flex align-items-center justify-content-center justify-content-md-start flex-wrap gap-4">
      {Object.keys(groupedBadges).map((name) => {
        const color = BADGE_COLORS[name] || BADGE_COLORS.Participator
        return (
          <div
            key={name}
            className="d-flex flex-column align-items-center p-2 gap-2 position-relative"
          >
            <CIcon icon={cilStar} size="xxl" style={{ color }} />
            <span className="text-sm fw-medium text-center">{name}</span>
            <div
              style={{ backgroundColor: color }}
              className={classNames(
                'd-flex align-items-center justify-content-center',
                'text-xs text-white',
                styles.count,
              )}
            >
              {groupedBadges[name].count}
            </div>
          </div>
        )
      })}
    </div>
  )
}

UserBadges.propTypes = {
  username: PropTypes.string,
}

export default UserBadges
