import React from 'react'
import { CCard, CCardBody } from '@coreui/react'

import styles from './styles.module.scss'
import TopicsAdmin from './Topics'
import { Route, Routes } from 'react-router-dom'
import adminRoutes from './routes'

const AdminDesktop = () => {
  return (
    <>
      <div className={styles.emptyBg} />
      <div className="d-flex justify-content-between" style={{ gap: 20, margin: '0 60px' }}>
        <CCard className="border-0 shadow-lg flex-grow-1 p-3">
          <CCardBody>
            <Routes>
              {adminRoutes.map((e) => (
                <Route key={e.path} path={e.path} element={<e.element />} />
              ))}
              <Route path={'/'} element={<TopicsAdmin />} />
            </Routes>
          </CCardBody>
        </CCard>
      </div>
    </>
  )
}

export default AdminDesktop
