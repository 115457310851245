import dayjs from 'dayjs'
import { timezoneLocation } from 'src/utils/timezone'

export const formatNumber = (number, fraction = 0) => {
  if (isNaN(number)) return ''
  return number.toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: fraction },
  )
}

export const formatDate = (date, formatter = 'DD MMM YYYY hh:mm a') => {
  return dayjs(date).format(formatter)
}

export const formatRelativeDate = (date) => {
  const targetDate = dayjs(date)
  const currentDate = dayjs()

  const daysDifference = currentDate.diff(targetDate, 'day')

  if (daysDifference < 7) {
    if (currentDate.isSame(targetDate, 'week')) return targetDate.format('dddd')
    else return `last ${targetDate.format('dddd')}`
  } else if (daysDifference < 30) {
    return 'last week'
  } else if (daysDifference < 365) {
    return 'last month'
  } else {
    return 'last year'
  }
}

export const formatTimeAgo = (date) => {
  return dayjs(date).fromNow()
}

export const formatDateToApi = (date) => {
  return dayjs.tz(date, timezoneLocation).format('YYYY-MM-DDTHH:mm')
}

export const formatTextMaxLength = (text, maxLength = 40) => {
  if (text.length > maxLength) return `${text.slice(0, maxLength)}...`
  return text
}

export const formatUsername = (username) => {
  if (!username) return ''
  return `@${username}`
}

export const formatMentionableText = (text) => {
  const mentionRegex = /@\[([^\]]+)\]\(([^)]+)\)/g
  const htmlText = text.replace(mentionRegex, '<a class="mention" data-mention="$2">@$1</a>')
  return htmlText
}
