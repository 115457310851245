import React from 'react'
import PropTypes from 'prop-types'
import useViewFullImage from 'src/hooks/useViewFullImage'
import classNames from 'classnames'

const ClickableVideo = ({
  src,
  onClick,
  autoPlay = false,
  controls = true,
  width = 100,
  height = 100,
  className,
  style,
  onTouchStart,
  onTouchMove,
  onTouchEnd,
}) => {
  const { openFullImage } = useViewFullImage()

  const onVideoClick = () => {
    openFullImage({ src, type: 'video' })
  }

  return (
    <div style={{ width, height }}>
      <video
        controls={controls}
        autoPlay={autoPlay}
        src={`${src}#t=1`}
        className={classNames(className, 'rounded border')}
        width="100%"
        height="100%"
        style={{ objectFit: 'cover', cursor: 'pointer', ...style }}
        draggable={false}
        onClick={onClick || onVideoClick}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      />
    </div>
  )
}

ClickableVideo.propTypes = {
  src: PropTypes.string,
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onTouchStart: PropTypes.func,
  onTouchMove: PropTypes.func,
  onTouchEnd: PropTypes.func,
}

export default ClickableVideo
