import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { usePublicUserStore } from './usePublicUserStore'
import { isAdmin, isModerator } from 'src/utils/role'

export const useUserStatsPanelStore = create()(
  immer((set, get) => ({
    open: false,
    username: null,
    user: null,
    openPanel: ({ user, username }) => {
      set({ username })
      if (user && !isModerator(user) && !isAdmin(user)) {
        set({ user, open: true })
      } else if (username) {
        usePublicUserStore
          .getState()
          .getUserPublicInfo(username)
          .then((res) => {
            if (isModerator(res) || isAdmin(res)) return
            set({ user: res, open: true })
          })
      }
    },
    closePanel: (user) => {
      set({ user: null, open: false })
    },
  })),
)
