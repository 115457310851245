import { LS_PREFER_LANG } from 'src/constants'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const usePreferLanguageStore = create()(
  immer((set, get) => ({
    open: false,
    language:
      localStorage.getItem(LS_PREFER_LANG) === null ? '' : localStorage.getItem(LS_PREFER_LANG),
    openModal: () => {
      set({ open: true })
    },
    closeModal: () => {
      set({ open: false })
    },
    setPreferLanguage: (lang) => {
      set({ language: lang })
      localStorage.setItem(LS_PREFER_LANG, lang)
    },
  })),
)
