import * as Sentry from '@sentry/react'
import React from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

export const registerSentry = () => {
  const dsn = process.env.REACT_APP_SENTRY_DSN
  if (!dsn) return
  Sentry.init({
    dsn,
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: ['*'],
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracePropagationTargets: [
      'localhost',
      'https://savingout.com.au',
      'https://www.savingout.com.au',
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

export const sendErrorEvent = (err, data) => {
  Sentry.setTag('category', 'api')
  if (localStorage.getItem('dms_username')) {
    Sentry.setTag('username', localStorage.getItem('dms_username'))
  }
  if (typeof err === 'string') {
    Sentry.captureMessage(err, { extra: data })
  } else {
    Sentry.captureException(err, { extra: data })
  }
}
