import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { BsFiletypeDoc, BsFiletypePdf } from 'react-icons/bs'
import ClickableImage from '../common/ClickableImage'
import classNames from 'classnames'
import ClickableVideo from '../common/ClickableVideo'
import { isPdfFile, isVideoFile, isWordFile } from 'src/utils/url'

const TopicAttachment = ({ file, src, width = 100, height = 100, showFileName = true }) => {
  const isPdf = useMemo(() => {
    if (file && file.type === 'application/pdf') return true
    if (isPdfFile(src)) return true
    return false
  }, [file, src])

  const isWord = useMemo(() => {
    if (
      file &&
      [
        'application/application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ].includes(file.type)
    )
      return true
    if (isWordFile(src)) return true
    return false
  }, [file, src])

  const isDocFile = useMemo(() => {
    return isPdf || isWord
  }, [isPdf, isWord])

  const isVideo = useMemo(() => {
    if (file && file.type && file.type.includes('video')) return true
    if (isVideoFile(src)) return true
    return false
  }, [file, src])

  return isDocFile ? (
    <div
      className="d-flex align-items-center gap-2"
      style={{ cursor: 'pointer' }}
      onClick={() => window.open(src)}
    >
      <div
        style={{ width, height }}
        className="d-flex align-items-center justify-content-center border rounded"
      >
        {isPdf ? (
          <BsFiletypePdf className={classNames('text-danger text-xl', width > 80 && 'text-4xl')} />
        ) : (
          <BsFiletypeDoc className={classNames('text-danger text-xl', width > 80 && 'text-4xl')} />
        )}
      </div>
      {showFileName && (
        <span className="text-xs text-danger">{file ? file.filename || file.name : ''}</span>
      )}
    </div>
  ) : isVideo ? (
    <ClickableVideo controls={false} src={src} width={width} height={height} />
  ) : (
    <ClickableImage src={src} width={width} height={height} className="border rounded" />
  )
}

TopicAttachment.propTypes = {
  file: PropTypes.any,
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  showFileName: PropTypes.bool,
}

export default TopicAttachment
