import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { CButton, CImage } from '@coreui/react'
import classNames from 'classnames'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import useSwipe from 'src/hooks/useSwipe'
import styles from './styles.module.scss'
import { isVideoFile } from 'src/utils/url'

const CarouselImages = (
  { images, defaultPage = 0, height = 400, objectFit = 'contain', onClick, onChangePage },
  ref,
) => {
  const [page, setPage] = useState(defaultPage)

  const hasControls = useMemo(() => images.length > 1, [images])

  useImperativeHandle(ref, () => ({
    next() {
      onClickNext()
    },
    prev() {
      onClickPrev()
    },
  }))

  useEffect(() => {
    onChangePage && onChangePage(page)
  }, [page])

  const onClickNext = (e) => {
    if (e) e.stopPropagation()
    setPage((page + 1) % images.length)
  }

  const onClickPrev = (e) => {
    if (e) e.stopPropagation()
    setPage(page === 0 ? images.length - 1 : page - 1)
  }

  const { onTouchStart, onTouchMove, onTouchEnd } = useSwipe({
    onSwipedLeft: onClickNext,
    onSwipedRight: onClickPrev,
  })

  const renderCurrentFile = () => {
    const file = images[page] ? images[page] : null
    if (!file) return null
    if (isVideoFile(file.filename)) {
      return (
        <video
          controls
          onTouchStart={images.length > 1 ? onTouchStart : null}
          onTouchMove={images.length > 1 ? onTouchMove : null}
          onTouchEnd={images.length > 1 ? onTouchEnd : null}
          className={classNames('d-block w-100')}
          style={{ objectFit: 'contain', pointerEvent: '' }}
          height={Math.min(600, height)}
          src={`${file.file}#t=0.01`}
        />
      )
    } else {
      return (
        <CImage
          onTouchStart={images.length > 1 ? onTouchStart : null}
          onTouchMove={images.length > 1 ? onTouchMove : null}
          onTouchEnd={images.length > 1 ? onTouchEnd : null}
          className={classNames('d-block w-100')}
          style={{ objectFit, pointerEvent: '', backgroundColor: '#000' }}
          height={Math.min(600, height)}
          src={file.file}
        />
      )
    }
  }

  if (!images || images.length === 0) return null

  return (
    <div
      onClick={() => onClick && onClick(page)}
      className={classNames(
        'bg-light rounded-3',
        styles.wrapper,
        hasControls && styles.hasControls,
      )}
    >
      <div className={classNames(styles.slide)}>{renderCurrentFile()}</div>
      {hasControls && (
        <>
          <CButton
            color="link"
            className={classNames(styles.prevButton, 'bg-white  shadow-sm rounded-circle')}
            onClick={onClickPrev}
          >
            <FaChevronLeft />
          </CButton>
          <CButton
            color="link"
            className={classNames(styles.nextButton, 'bg-white  shadow-sm rounded-circle')}
            onClick={onClickNext}
          >
            <FaChevronRight />
          </CButton>
          <div className={styles.status}>
            <span className="text-sm px-4 py-1">
              {page + 1} / {images.length}
            </span>
          </div>
        </>
      )}
    </div>
  )
}

CarouselImages.propTypes = {
  defaultPage: PropTypes.number,
  images: PropTypes.array,
  height: PropTypes.number,
  objectFit: PropTypes.oneOf(['contain', 'cover']),
  onChangePage: PropTypes.func,
  onClick: PropTypes.func,
}

export default forwardRef(CarouselImages)
