import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton } from '@coreui/react'
import { useShallow } from 'zustand/react/shallow'

import { useChallengeStore } from 'src/store/useChallengeStore'
import { UserChallengeEventType } from 'src/constants'
import CIcon from '@coreui/icons-react'
import { cilCheckCircle } from '@coreui/icons'

const ChallengeCongratModal = () => {
  const { challenges, congratModal } = useChallengeStore((state) => ({
    challenges: state.challenges,
    congratModal: state.congratModal,
  }))
  const { openCongratModal, closeCongratModal, getChallenges, logUserChallengeEvent } =
    useChallengeStore(
      useShallow((state) => ({
        openCongratModal: state.openCongratModal,
        closeCongratModal: state.closeCongratModal,
        getChallenges: state.getChallenges,
        logUserChallengeEvent: state.logUserChallengeEvent,
      })),
    )
  const [challenge, setChallenge] = useState({})

  useEffect(() => {
    if (congratModal.challenges) {
      const c = challenges.find((e) => e.key === congratModal.challenges[0])
      if (c) {
        setChallenge(c)
        logUserChallengeEvent(c.key, UserChallengeEventType.receive)
      }
    }
  }, [congratModal, challenges])

  useEffect(() => {
    getChallenges()
  }, [])

  const onConfirm = async () => {
    logUserChallengeEvent(challenge.key, UserChallengeEventType.confirm)
    if (congratModal.challenges.length > 1) {
      openCongratModal(congratModal.challenges.slice(1))
    } else {
      closeCongratModal()
    }
  }

  return (
    <CModal
      size="sm"
      alignment="center"
      visible={congratModal.open}
      onClose={() => {
        closeCongratModal()
      }}
    >
      <CModalHeader>
        <CModalTitle>Congratulations!</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {challenge.id && (
          <div className="d-flex flex-column align-items-center gap-4">
            <CIcon icon={cilCheckCircle} className="text-success" size="5xl" />
            <div className="d-flex flex-column align-items-center">
              <h3 className="text-success">{challenge.name}</h3>
              <div className="text-info fw-semibold text-center">
                {challenge.reward_note.replace('<topic_owner>', challenge.topic.user.username)}
              </div>
            </div>
          </div>
        )}
      </CModalBody>
      <CModalFooter className="d-flex">
        <CButton style={{ width: 80 }} onClick={onConfirm} color="success">
          OK
        </CButton>
        <CButton color="dark" onClick={() => closeCongratModal()}>
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}
ChallengeCongratModal.propTypes = {
  open: PropTypes.bool,
}
export default ChallengeCongratModal
