import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'src/config/api'
import { toast } from 'react-toastify'

export const getCustomerDashboardStats = createAsyncThunk(
  'stats/customers-all',
  async (rejectWithValue) => {
    try {
      let URL = `customer-dashboard/`
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get stats failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get stats failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getDashboardStores = createAsyncThunk(
  'stats/stores',
  async ({ page = 1, page_size = 10 }, { rejectWithValue }) => {
    try {
      let URL = `dashboard/stores/?page=${page}&page_size=${page_size}`
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get stats failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get stats failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getCustomerStats = createAsyncThunk(
  'stats/customer',
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`dashboard/customer-stats/`)
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get stats failed')
        throw error
      }
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get stats failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getCouponStats = createAsyncThunk('stats/coupon', async (_, { rejectWithValue }) => {
  try {
    const response = await Axios.get(`dashboard/coupon-stats/`)
    return response.data
  } catch (error) {
    if (!error.response) {
      toast.error('Get stats failed')
      throw error
    }
    if (error.response.data) {
      const msg = error.response.data ? error.response.data.detail : 'Get stats failed'
      toast.error(msg)
    }
    return rejectWithValue(error.response.data)
  }
})

export const getFeedbackStats = createAsyncThunk(
  'stats/feedback',
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`dashboard/feedback-stats/`)
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get stats failed')
        throw error
      }
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get stats failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getOrderStats = createAsyncThunk('stats/order', async (_, { rejectWithValue }) => {
  try {
    const response = await Axios.get(`dashboard/order-stats/`)
    return response.data
  } catch (error) {
    if (!error.response) {
      toast.error('Get stats failed')
      throw error
    }
    if (error.response.data) {
      const msg = error.response.data ? error.response.data.detail : 'Get stats failed'
      toast.error(msg)
    }
    return rejectWithValue(error.response.data)
  }
})

const statsSlice = createSlice({
  name: 'stats',
  initialState: {
    loading: false,
    loadingCustomer: false,
    loadingStore: false,
    loadingCouponStats: false,
    loadingFeedbackStats: false,
    loadingOrderStats: false,
    customerDashboardStats: null,
    customerStats: null,
    dashboardStores: null,
    dashboardCustomers: null,
    couponStats: null,
    feedbackStats: null,
    orderStats: null,
    errors: null,
  },
  reducers: {
    resetStats: (state) => {
      state.loading = false
      state.loadingCustomerDashboard = false
      state.loadingCustomer = false
      state.loadingStore = false
      state.loadingCouponStats = false
      state.loadingFeedbackStats = false
      state.loadingOrderStats = false
      state.errors = null
    },
  },
  extraReducers: (builder) => {
    // get stats customers
    builder.addCase(getCustomerDashboardStats.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCustomerDashboardStats.fulfilled, (state, action) => {
      state.loading = false
      state.customerDashboardStats = action.payload
    })
    builder.addCase(getCustomerDashboardStats.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        all: action.payload,
      }
    })
    // get stores
    builder.addCase(getDashboardStores.pending, (state) => {
      state.loadingStore = true
    })
    builder.addCase(getDashboardStores.fulfilled, (state, action) => {
      state.loadingStore = false
      state.dashboardStores = action.payload
    })
    builder.addCase(getDashboardStores.rejected, (state, action) => {
      state.loadingStore = false
      state.errors = {
        all: action.payload,
      }
    })
    // Customer Stats
    builder.addCase(getCustomerStats.pending, (state) => {
      state.loadingCustomer = true
    })
    builder.addCase(getCustomerStats.fulfilled, (state, action) => {
      state.loadingCustomer = false
      state.customerStats = action.payload
    })
    builder.addCase(getCustomerStats.rejected, (state, action) => {
      state.loadingCustomer = false
    })
    // Coupon Stats
    builder.addCase(getCouponStats.pending, (state) => {
      state.loadingCouponStats = true
    })
    builder.addCase(getCouponStats.fulfilled, (state, action) => {
      state.loadingCouponStats = false
      state.couponStats = action.payload
    })
    builder.addCase(getCouponStats.rejected, (state, action) => {
      state.loadingCouponStats = false
    })
    // Feedback Stats
    builder.addCase(getFeedbackStats.pending, (state) => {
      state.loadingFeedbackStats = true
    })
    builder.addCase(getFeedbackStats.fulfilled, (state, action) => {
      state.loadingFeedbackStats = false
      state.feedbackStats = action.payload
    })
    builder.addCase(getFeedbackStats.rejected, (state, action) => {
      state.loadingFeedbackStats = false
    })
    // Feedback Stats
    builder.addCase(getOrderStats.pending, (state) => {
      state.loadingOrderStats = true
    })
    builder.addCase(getOrderStats.fulfilled, (state, action) => {
      state.loadingOrderStats = false
      state.orderStats = action.payload
    })
    builder.addCase(getOrderStats.rejected, (state, action) => {
      state.loadingOrderStats = false
    })
  },
})

export const { resetStats } = statsSlice.actions

export default statsSlice.reducer
