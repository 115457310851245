import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CButton,
  CFormTextarea,
  CSpinner,
  CModalFooter,
} from '@coreui/react'
import { useDispatch } from 'react-redux'
import { rejectTopicAdmin } from 'src/store/actions/topic.action'

const AdminRejectTopicModal = ({ open, data, onClose, onSaved }) => {
  const dispatch = useDispatch()
  const [message, setMessage] = useState('')
  const [submitting, setSubmitting] = useState('')

  useEffect(() => {
    if (!open) setMessage('')
  }, [open])

  const onReject = async () => {
    setSubmitting(true)
    const res = await dispatch(rejectTopicAdmin({ id: data.id, message }))
    setSubmitting(false)
    if (res.error) return
    onClose()
    onSaved()
  }
  return (
    <CModal alignment="center" visible={open} onClose={onClose}>
      <CModalHeader onClose={onClose}>
        <CModalTitle>Reject Topic</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="d-flex flex-column gap-4">
          <CFormTextarea
            placeholder="Reason to reject"
            rows={5}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
      </CModalBody>
      <CModalFooter className="d-flex justify-content-end">
        <CButton color="danger" disabled={submitting} onClick={onReject}>
          {submitting && <CSpinner size="sm" className="me-2" />} Reject
        </CButton>
        <CButton color="light" onClick={onClose}>
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}
AdminRejectTopicModal.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.any,
  onClose: PropTypes.func,
  onSaved: PropTypes.func,
}
export default AdminRejectTopicModal
