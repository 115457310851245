import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import classNames from 'classnames'

const UploadingIcon = ({ size = 32, disabled }) => {
  return (
    <div
      style={{ transform: `scale(${size / 32})` }}
      className={classNames(styles.wrapper, disabled && styles.disabled)}
    >
      <svg
        className={styles.uploadBox}
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 17"
        version="1.1"
        xmlSpace="preserve"
        style={{
          'clip-rule': 'evenodd',
          'fill-rule': 'evenodd',
          'stroke-linejoin': 'round',
          'stroke-miterlimit': 1.41,
        }}
      >
        <path
          d="M26 3.82l3.16 1.18 -13.16 4.91 -13.16-4.91 3.16-1.18 0-3.07 -6 2.25 0 8 16 6 16-6 0-8 -6-2.25 0 3.07Z"
          fill="rgb(51, 153, 255)"
        />
      </svg>

      <svg
        className={styles.uploadArrow}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="32"
        viewBox="0 0 16 16"
        version="1.1"
        xmlSpace="preserve"
        style={{
          'clip-rule': 'evenodd',
          'fill-rule': 'evenodd',
          'stroke-linejoin': 'round',
          'stroke-miterlimit': 1.41,
        }}
      >
        <path d="M6 16l4 0 0-8 6 0 -8-8 -8 8 6 0 0 8Z" fill="rgb(51, 153, 255)" />
      </svg>
    </div>
  )
}

UploadingIcon.propTypes = {
  size: PropTypes.number,
  disabled: PropTypes.bool,
}

export default UploadingIcon
