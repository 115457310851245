export const getStaticMediaUrl = (path) => {
  return process.env.REACT_APP_BACKEND_HOST + path
}

export const isPdfFile = (filename) => {
  if (/^.+\.pdf$/i.test(filename)) return true
  return false
}
export const isWordFile = (filename) => {
  if (/^.+\.(doc|docx)$/i.test(filename)) return true
  return false
}
export const isVideoFile = (filename) => {
  if (/^.+\.(mp4|avi|mov)$/i.test(filename)) return true
  return false
}
