import React from 'react'
import { CContainer } from '@coreui/react'

import TopicsAdmin from './Topics'
import { Route, Routes } from 'react-router-dom'
import adminRoutes from './routes'

const AdminMobile = () => {
  return (
    <CContainer>
      <Routes>
        {adminRoutes.map((e) => (
          <Route key={e.path} path={e.path} element={<e.element />} />
        ))}
        <Route path={'/'} element={<TopicsAdmin />} />
      </Routes>
    </CContainer>
  )
}

export default AdminMobile
