import { toast } from 'react-toastify'
import Axios from 'src/config/api'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const useUserVenueStore = create()(
  immer((set, get) => ({
    venues: [],
    loading: false,
    getVenues: async (userId) => {
      try {
        set({ loading: true })
        const response = await Axios.get(`venues/`, {
          params: {
            user: userId,
          },
        })
        set({ venues: response.data })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      } finally {
        set({ loading: false })
      }
    },
    createVenue: async (name) => {
      try {
        set({ loading: true })
        const response = await Axios.post(`venues/`, {
          name,
        })
        return response.data
      } catch (error) {
        let message = 'Create failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      } finally {
        set({ loading: false })
      }
    },
  })),
)
