import React from 'react'
import { CSpinner } from '@coreui/react'
import PropTypes from 'prop-types'

const ButtonSpinner = ({ color = 'white' }) => {
  return <CSpinner size="sm" color={color} className="me-1" />
}

ButtonSpinner.propTypes = {
  color: PropTypes.string,
}

export default ButtonSpinner
