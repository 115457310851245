import Axios from 'src/config/api'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const useConfigStore = create()(
  immer((set, get) => ({
    categories: [],
    badges: [],
    getPlatformCategories: async () => {
      const response = await Axios.get('config/categories/')
      set({ categories: response.data })
      return response.data
    },
    getPlatformBadges: async () => {
      const response = await Axios.get('config/badges/')
      set({ badges: response.data })
      return response.data
    },
  })),
)
