import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'

const DatetimePicker = ({ label, min, invalid, feedbackInvalid, value, onChange, disabled }) => {
  return (
    <div className={`datePickerBox ${invalid ? 'error' : ''}`}>
      <label htmlFor="">{label}</label>
      <DatePicker
        placeholderText="DD/MM/YYYY h:mm a"
        selected={value}
        onChange={onChange}
        showTimeSelect
        // excludeTimes={[
        //   setHours(setMinutes(new Date(), 0), 17),
        //   setHours(setMinutes(new Date(), 30), 18),
        //   setHours(setMinutes(new Date(), 30), 19),
        //   setHours(setMinutes(new Date(), 30), 17),
        // ]}
        minDate={min || new Date()}
        dateFormat="dd/MM/yyyy h:mm aa"
        disabled={disabled}
      />
      {invalid && (
        <p className="invalid text-danger my-0" style={{ fontSize: '14px', marginTop: '4px' }}>
          {feedbackInvalid}
        </p>
      )}
    </div>
  )
}

DatetimePicker.propTypes = {
  label: PropTypes.string,
  min: PropTypes.any,
  invalid: PropTypes.bool,
  feedbackInvalid: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}
export default DatetimePicker
