import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CButton,
  CFormTextarea,
  CSpinner,
  CModalFooter,
} from '@coreui/react'
import { useDispatch } from 'react-redux'
import { requestInfoTopicAdmin } from 'src/store/actions/topic.action'

const AdminRequestInfoTopicModal = ({ open, data, onClose, onSaved }) => {
  const dispatch = useDispatch()
  const [message, setMessage] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const onRequestInfo = async (message) => {
    setSubmitting(true)
    const res = await dispatch(requestInfoTopicAdmin({ id: data.id, message }))
    setSubmitting(false)
    if (res.error) return
    onClose()
    if (onSaved) onSaved()
  }

  return (
    <CModal alignment="center" visible={open} onClose={onClose}>
      <CModalHeader onClose={onClose}>
        <CModalTitle>Require Information</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="d-flex flex-column gap-4">
          <CFormTextarea
            placeholder="Enter message..."
            rows={5}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
      </CModalBody>
      <CModalFooter className="d-flex justify-content-end">
        <CButton disabled={submitting} onClick={() => onRequestInfo(message)}>
          {submitting && <CSpinner size="sm" className="me-2" />} Send
        </CButton>
        <CButton color="light" onClick={onClose}>
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}
AdminRequestInfoTopicModal.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.any,
  onClose: PropTypes.func,
  onSaved: PropTypes.func,
}
export default AdminRequestInfoTopicModal
