import React from 'react'
import { Routes } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import SidebarLayout from 'src/layout/SidebarLayout'

import AuthRoute from './AuthRoute'
import NoAuthRoute from './NonAuthRoute'
import routes from './routes'
import Admin from 'src/pages/admin'

const Home = React.lazy(() => import('src/pages/home'))
const Login = React.lazy(() => import('src/pages/auth/Login'))
const Register = React.lazy(() => import('src/pages/auth/Register'))
const RegisterStore = React.lazy(() => import('src/pages/auth/RegisterStore'))
const ForgotPassword = React.lazy(() => import('src/pages/auth/ForgotPassword'))
const ResetPassword = React.lazy(() => import('src/pages/auth/ResetPassword'))
const PreviewQR = React.lazy(() => import('src/pages/preview'))
const Page404 = React.lazy(() => import('src/pages/page404'))
const Dashboard = React.lazy(() => import('src/pages/manage-store/Overview'))
const Topics = React.lazy(() => import('src/pages/topics'))
const TopicDetail = React.lazy(() => import('src/pages/topics/detail'))
const CreateTopic = React.lazy(() => import('src/pages/topics/create'))
const CreateAnnouncement = React.lazy(() => import('src/pages/topics/createAnnouncement'))
const VerifyLogin = React.lazy(() => import('src/pages/auth/VerifyLogin'))

// user
const Settings = React.lazy(() => import('src/pages/settings'))
const ReportIssue = React.lazy(() => import('src/pages/customers/ReportIssue'))
const PageUserStats = React.lazy(() => import('src/pages/users/userStats'))

// store public
const PageStore = React.lazy(() => import('src/pages/stores/detail'))
// const Vouchers = React.lazy(() => import('src/pages/customers/store/ViewCoupon'))
// const MyCouponsOfStore = React.lazy(() => import('src/pages/customers/store/MyCoupons'))
// const FeedbacksOfStore = React.lazy(() => import('src/pages/customers/store/feedback'))
// const FeedbacksOfStoreNew = React.lazy(() => import('src/pages/customers/store/feedback/New'))

// // main pages
// const Coupons = React.lazy(() => import('src/pages/store/Coupons'))
// const NewCoupon = React.lazy(() => import('src/pages/store/NewCoupon'))
// const CouponDetail = React.lazy(() => import('src/pages/store/CouponDetail'))
// const OrderDetail = React.lazy(() => import('src/pages/store/OrderDetail'))
// const Contacts = React.lazy(() => import('src/pages/store/Contacts'))
// const ContactDetail = React.lazy(() => import('src/pages/store/ContactDetail'))
// const ContactNew = React.lazy(() => import('src/pages/store/ContactNew'))
// const Feedbacks = React.lazy(() => import('src/pages/dashboard/Feedbacks'))
// const Customers = React.lazy(() => import('src/pages/store/Customers'))
// // admin
// const Users = React.lazy(() => import('src/pages/admin/Users'))
// const Categories = React.lazy(() => import('src/pages/admin/Categories'))
// const CategoryForm = React.lazy(() => import('src/pages/admin/CategoryForm'))
// const UserForm = React.lazy(() => import('src/pages/admin/UserForm'))
// const Supports = React.lazy(() => import('src/pages/dashboard/Supports'))

// // customers
const Vouchers = React.lazy(() => import('src/pages/vouchers'))
// const Search = React.lazy(() => import('src/pages/customers/Search'))
// const Saved = React.lazy(() => import('src/pages/customers/Saved'))
const Notifications = React.lazy(() => import('src/pages/customers/Notifications'))

// // other pages
const HowItWorks = React.lazy(() => import('src/pages/other/HowItWorks'))
const AboutUs = React.lazy(() => import('src/pages/other/AboutUs'))
const PrivacyPolicy = React.lazy(() => import('src/pages/other/PrivacyPolicy'))
const TermsOfUse = React.lazy(() => import('src/pages/other/TermsOfUse'))

const noAuthRoutes = [
  { path: routes.LOGIN, component: Login },
  { path: routes.VERIFY_LOGIN, component: VerifyLogin },
  { path: routes.REGISTER, component: Register },
  { path: routes.REGISTER_ORGANISER, component: RegisterStore },
  { path: routes.FORGOT_PASSWORD, component: ForgotPassword },
  { path: routes.RESET_PASSWORD, component: ResetPassword },
  { path: routes.FORGOT_PASSWORD, component: ForgotPassword },
  { path: routes.PREVIEW_QR, component: PreviewQR },
  { path: routes.TOPICS, component: Topics },
  { path: routes.TOPIC_DETAIL, component: TopicDetail },
  { path: routes.CREATE_TOPIC, component: CreateTopic },
  { path: routes.CREATE_ANNOUNCEMENT, component: CreateAnnouncement },
  { path: routes.VOUCHERS, component: Vouchers },
  { path: routes.HOW_IT_WORKS, component: HowItWorks },
  { path: routes.ABOUT_US, component: AboutUs },
  { path: routes.PRIVACY_POLICY, component: PrivacyPolicy },
  { path: routes.TERM_OF_USE, component: TermsOfUse },
  { path: routes.VIEW_STORE, component: PageStore },
  { path: routes.USER_STATS, component: PageUserStats },

  // { path: routes.LOGOUT, component: Logout },
]

const authRoutes = [
  { path: routes.DASHBOARD, component: Dashboard },
  { path: routes.SETTINGS + '/*', component: Settings },
  { path: routes.NOTIFICATIONS, component: Notifications },
  { path: routes.REPORT_ISSUE, component: ReportIssue },
  { path: routes.ADMIN + '/*', component: Admin },
  { path: `${routes.ADMIN}/*`, component: Admin },
]

const RouteContainer = (props) => {
  return (
    <Routes>
      {noAuthRoutes.map((e) => (
        <Route
          key={e}
          path={e.path}
          element={<NoAuthRoute layout={SidebarLayout} component={e.component} />}
        />
      ))}
      {authRoutes.map((e) => (
        <Route
          key={e}
          exact
          path={e.path}
          element={<AuthRoute layout={SidebarLayout} component={e.component} />}
        />
      ))}
      <Route path="/" element={<Home />} />
      <Route
        path="*"
        element={
          <SidebarLayout>
            <Page404 />
          </SidebarLayout>
        }
      />
    </Routes>
  )
}

RouteContainer.propTypes = {
  layout: PropTypes.any,
}

export default RouteContainer
