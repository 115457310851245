import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CSpinner } from '@coreui/react'
import { useTopicReplyStore } from 'src/store/useTopicReplyStore'
import { useShallow } from 'zustand/react/shallow'
import ClickableUsername from 'src/components/common/ClickableUsername'
import { getScheduleDisplayName } from 'src/utils/schedule'
import FeedbackItem from './FeedbackItem'

const GivenFeedbacks = ({ username }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const { getGivenFeedbacks } = useTopicReplyStore(
    useShallow((state) => ({
      getGivenFeedbacks: state.getGivenFeedbacks,
    })),
  )

  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await getGivenFeedbacks({ username })
      setData(res)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [username])

  return (
    <div className="d-flex flex-column gap-4">
      {!loading && data.length === 0 && <div>No feedback yet</div>}
      {data.map((e) => {
        const schedule = e.feedback_to_schedule
        return (
          <div key={e.id} className="d-flex flex-column gap-2 flex-grow-1">
            <div className="text-sm">
              Feedback given to{' '}
              {e.participants.map((u, i) => (
                <>
                  {i > 0 && ', '}
                  <ClickableUsername key={u.username} username={u.username} />
                </>
              ))}{' '}
              on <strong>{getScheduleDisplayName(schedule)}</strong>
            </div>
            <FeedbackItem data={e} />
          </div>
        )
      })}
      {loading && <CSpinner className="mx-auto" color="primary" />}
    </div>
  )
}

GivenFeedbacks.propTypes = {
  username: PropTypes.string,
}

export default GivenFeedbacks
