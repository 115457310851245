import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CNavbar, CContainer, CNavbarNav, CButton, CNavbarBrand } from '@coreui/react'
import classNames from 'classnames'

import styles from './styles.module.scss'
import routes from 'src/navigations/routes'
import UploadingMenu from './UploadingMenu'

const HeaderDesktop = () => {
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.users)
  const prevScrollPos = useRef(0)
  const { openSidebar } = useSelector((state) => state.main)

  const handleScroll = () => {
    const currentScrollPos = window.scrollY

    if (currentScrollPos > prevScrollPos.current) {
      document.getElementById('header').classList.add('hide')
    } else {
      document.getElementById('header').classList.remove('hide')
    }
    if (currentScrollPos === 0) {
      document.getElementById('header').classList.add('atTop')
    } else {
      document.getElementById('header').classList.remove('atTop')
    }
    prevScrollPos.current = currentScrollPos
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <CNavbar
      id="header"
      expand="lg"
      colorScheme="light"
      className={classNames(openSidebar && 'sidebarOpen', 'py-3', 'atTop')}
    >
      <CContainer>
        <CNavbarBrand
          onClick={() => navigate(routes.TOPICS)}
          style={{ fontWeight: 700, cursor: 'pointer' }}
        >
          SavingOut
        </CNavbarBrand>
        <CNavbarNav className={classNames('ms-auto text-sm', styles.navMenu)}>
          {/* <CNavItem>
            <CNavLink
              onClick={() => navigate(routes.TOPICS)}
              style={{ margin: '0 15px' }}
              active={location.pathname === routes.TOPICS}
            >
              Topics
            </CNavLink>
          </CNavItem> */}
          {/* <CNavItem>
            <CNavLink
              onClick={() => navigate(routes.VOUCHERS)}
              active={location.pathname === routes.VOUCHERS}
              style={{ margin: '0 15px' }}
            >
              Vouchers
            </CNavLink>
          </CNavItem> */}
        </CNavbarNav>
        <div className="d-flex gap-2 justify-content-end">
          <UploadingMenu />
          {!user && (
            <>
              {/* <CButton
              size="sm"
              className="fw-medium"
              variant="outline"
              color="success"
              onClick={() => navigate(routes.REGISTER_ORGANISER)}
            >
              Register For Organiser
            </CButton> */}
              <CButton
                size="sm"
                className="fw-medium"
                variant="outline"
                color="info"
                onClick={() => navigate(routes.LOGIN)}
              >
                Login
              </CButton>
              <CButton
                size="sm"
                className="fw-medium"
                variant="outline"
                color="warning"
                onClick={() => navigate(routes.REGISTER)}
              >
                Sign Up
              </CButton>
            </>
          )}
        </div>
      </CContainer>
    </CNavbar>
  )
}

export default HeaderDesktop
