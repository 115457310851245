import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CCollapse, CLink } from '@coreui/react'
import { formatTimeAgo } from 'src/utils/format'
import CIcon from '@coreui/icons-react'
import { cilClock } from '@coreui/icons'
import routes from 'src/navigations/routes'
import { getScheduleQuestion } from 'src/utils/schedule'
import { FaCaretRight } from 'react-icons/fa'

const FeedbackItem = ({ data }) => {
  const [open, setOpen] = useState(false)

  return (
    <div key={data.id} className="d-flex flex-column gap-2 flex-grow-1">
      <div className="d-flex flex-column flex-md-row gap-0 gap-md-4">
        <CLink
          className="text-sm text-decoration-none text-info"
          target="_blank"
          href={`${routes.TOPICS}/${data.topic.id}`}
          style={{ whiteSpace: 'pre-line' }}
        >
          {getScheduleQuestion(data.feedback_to_schedule)}
        </CLink>
        <div className="d-flex gap-3 text-black-50 text-xs fw-medium">
          <div className="d-flex align-items-center gap-2">
            <CIcon icon={cilClock} />
            {formatTimeAgo(data.created_at)}
          </div>
        </div>
      </div>
      <div
        className="text-xs text-primary d-flex align-items-center"
        style={{ cursor: 'pointer' }}
        onClick={() => setOpen((e) => !e)}
      >
        <FaCaretRight style={{ transition: '0.2s all', transform: open ? 'rotate(90deg)' : '' }} />
        Show feedback
      </div>
      <CCollapse visible={open} className="text-sm">
        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
        squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente
        ea proident.
      </CCollapse>
    </div>
  )
}

FeedbackItem.propTypes = {
  data: PropTypes.object,
}

export default FeedbackItem
