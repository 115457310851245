import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'src/config/api'
import { toast } from 'react-toastify'

export const deleteUserOfAdmin = createAsyncThunk(
  'users/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await Axios.delete(`admin/user/${id}/`)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Delete user failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          error.response.data && error.response.data.detail
            ? error.response.data.detail
            : 'Delete user failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateUserOfAdmin = createAsyncThunk(
  'users/update',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Axios.put(`admin/user/${id}/`, data)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Update user failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          error.response.data && error.response.data.detail
            ? error.response.data.detail
            : 'Update user failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const createUserOfAdmin = createAsyncThunk(
  'users/new',
  async (data, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`admin/user/`, data)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Create User failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          error.response.data && error.response.data.detail
            ? error.response.data.detail
            : 'Create User failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getUserByIdOfAdmin = createAsyncThunk(
  'users/get-detail',
  async (id, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`admin/user/${id}/`)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get user detail failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          error.response.data && error.response.data.detail
            ? error.response.data.detail
            : 'Get user detail failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getAllUsers = createAsyncThunk(
  'users/all',
  async ({ page = 1, page_size = 50 }, { rejectWithValue }) => {
    try {
      let URL = `admin/user/?page=${page}&page_size=${page_size}`
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get all users failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get all users failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getProfile = createAsyncThunk('users/profile', async (user, { rejectWithValue }) => {
  try {
    const response = await Axios.get(`me/`)

    return response.data
  } catch (error) {
    if (!error.response) {
      toast.error('Get profile failed')
      throw error
    }
    console.log('error.response.data: ', error.response.data)
    if (error.response.data) {
      const msg = error.response.data ? error.response.data.detail : 'Get profile failed'
      toast.error(msg)
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateProfile = createAsyncThunk(
  'users/updateProfile',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`account/update-user-profile/`, params)
      toast.success('Update successfully')
      return response.data
    } catch (error) {
      let message = 'Failed to update profile'
      if (!error.response) {
        toast.error(message)
        throw error
      }
      if (error.response.data && error.response.data.detail) {
        message = error.response.data.detai
        toast.error(message)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const changePassword = createAsyncThunk(
  'users/changePassword',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`account/change-password/`, params)
      toast.success('Change password successfully!')
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Change password failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          error.response?.data?.detail || error.response?.data?.message || 'Change password failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

const userSlice = createSlice({
  name: 'users',
  initialState: {
    loading: false,
    user: null,
    allUsers: null,
    isUpdateProfile: false,
    isCreateUser: false,
    isDeleteUser: false,
    errors: null,
    selectUser: null,
    needChangePassword: false,
  },
  reducers: {
    resetUsers: (state) => {
      state.loading = false
      state.errors = null
      state.isCreateUser = false
      state.isDeleteUser = false
      state.selectUser = false
      state.needChangePassword = false
      state.isUpdateProfile = false
    },
  },
  extraReducers: (builder) => {
    // Delete Category
    builder.addCase(deleteUserOfAdmin.pending, (state) => {
      state.loading = true
      state.isDeleteUser = false
    })
    builder.addCase(deleteUserOfAdmin.fulfilled, (state, action) => {
      state.loading = false
      state.isDeleteUser = true
    })
    builder.addCase(deleteUserOfAdmin.rejected, (state, action) => {
      state.loading = false
      state.isDeleteUser = false
    })
    // Update User
    builder.addCase(updateUserOfAdmin.pending, (state) => {
      state.loading = true
      state.isCreateUser = false
    })
    builder.addCase(updateUserOfAdmin.fulfilled, (state, action) => {
      state.loading = false
      state.isCreateUser = true
    })
    builder.addCase(updateUserOfAdmin.rejected, (state, action) => {
      state.loading = false
      state.isCreateUser = false
      state.errors = {
        form: action.payload,
      }
    })
    // create User
    builder.addCase(createUserOfAdmin.pending, (state) => {
      state.loading = true
      state.isCreateUser = false
    })
    builder.addCase(createUserOfAdmin.fulfilled, (state, action) => {
      state.loading = false
      state.isCreateUser = true
    })
    builder.addCase(createUserOfAdmin.rejected, (state, action) => {
      state.loading = false
      state.isCreateUser = false
      state.errors = {
        form: action.payload,
      }
    })
    // get User by id
    builder.addCase(getUserByIdOfAdmin.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getUserByIdOfAdmin.fulfilled, (state, action) => {
      state.loading = false
      state.selectUser = action.payload
    })
    builder.addCase(getUserByIdOfAdmin.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        getDetail: action.payload,
      }
    })
    // Change password
    builder.addCase(changePassword.pending, (state) => {
      state.loading = true
    })
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.loading = false
      state.needChangePassword = false
    })
    builder.addCase(changePassword.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        changePassword: action.payload,
      }
    })
    // update profile
    builder.addCase(updateProfile.pending, (state) => {
      state.loading = true
      state.isUpdateProfile = false
    })
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.loading = false
      state.isUpdateProfile = true
    })
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.loading = false
      state.isUpdateProfile = false
      state.errors = {
        updateProfile: action.payload,
      }
    })
    // get profile
    builder.addCase(getProfile.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.loading = false
      state.user = action.payload
      if (state.user.groups) {
        const role = state.user.groups[0].name
        localStorage.setItem('dms_role', role)
        state.role = role
      }
      state.needChangePassword = state.user.profile.need_change_pw
    })
    builder.addCase(getProfile.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        profile: action.payload,
      }
    })
    // admin get all users
    builder.addCase(getAllUsers.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.loading = false
      state.allUsers = action.payload
    })
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        profile: action.payload,
      }
    })
  },
})

export const { resetUsers } = userSlice.actions

export default userSlice.reducer
