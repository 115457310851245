import { toast } from 'react-toastify'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import Axios from 'src/config/api'

export const usePublicUserStore = create()(
  immer((set, get) => ({
    publicUser: null,
    loadingUser: true,
    loadingStats: true,
    publicStats: null,
    loadingActivityStatistic: true,
    activityStatistic: null,
    loadingVenueStatistic: true,
    venueStatistic: null,
    getUserPublicInfo: async (username) => {
      try {
        set({ loadingUser: true })
        const response = await Axios.get(`users/public-info/`, { params: { username } })
        set({ publicUser: response.data })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
        throw message
      } finally {
        set({ loadingUser: false })
      }
    },
    getUserStats: async (username) => {
      try {
        set({ loadingStats: true })
        const response = await Axios.get(`users/stats/`, { params: { username } })
        set({ publicStats: response.data })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
        throw message
      } finally {
        set({ loadingStats: false })
      }
    },
    getActivityStatistic: async (username) => {
      try {
        set({ loadingActivityStatistic: true })
        const response = await Axios.get(`users/category-statistic/`, { params: { username } })
        set({ activityStatistic: response.data })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
        throw message
      } finally {
        set({ loadingActivityStatistic: false })
      }
    },
    getVenueStatistic: async (username) => {
      try {
        set({ loadingVenueStatistic: true })
        const response = await Axios.get(`users/venue-statistic/`, { params: { username } })
        set({ venueStatistic: response.data })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
        throw message
      } finally {
        set({ loadingVenueStatistic: false })
      }
    },
    searchUsers: async ({ search, page, pageSize }) => {
      try {
        const response = await Axios.get(`users/`, {
          params: { search: search, page, page_size: pageSize },
        })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
        throw message
      }
    },
    getFollowingUsers: async ({ username, page, pageSize }) => {
      try {
        const response = await Axios.get(`users/follow/`, {
          params: { follower: username, page, page_size: pageSize },
        })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
        throw message
      }
    },
    followOtherUser: async (userId) => {
      try {
        const response = await Axios.post(`users/follow/${userId}/`)
        toast.success('User followed')
        return response.data
      } catch (error) {
        let message = 'Failed to follow, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
        throw message
      }
    },
    unfollowOtherUser: async (userId) => {
      try {
        const response = await Axios.delete(`users/follow/${userId}/`)
        toast.success('User not followed')
        return response.data
      } catch (error) {
        let message = 'Failed to follow, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
        throw message
      }
    },
  })),
)
