import React from 'react'
import { CButton } from '@coreui/react'
import PropTypes from 'prop-types'
import styles from './VotePill.module.scss'
import classNames from 'classnames'
import { VoteType } from 'src/constants'
import { HiOutlineThumbUp, HiOutlineThumbDown } from 'react-icons/hi'

const VotePill = ({ count, isUpvoted, isDownvoted, disabled, vertical, onVote, onUnvote }) => {
  const onUpvoteClick = () => {
    if (disabled) return
    if (isUpvoted) onUnvote(VoteType.upvote)
    else onVote(VoteType.upvote)
  }
  const onDownvoteClick = () => {
    if (disabled) return
    if (isDownvoted) onUnvote(VoteType.downvote)
    else onVote(VoteType.downvote)
  }

  return (
    <div className={classNames(styles.votePill, vertical && styles.vertical)}>
      <CButton
        size="sm"
        color="link"
        className={classNames('text-xl fw-medium', !isUpvoted && 'text-dark')}
        onClick={onUpvoteClick}
        style={{ opacity: disabled ? 0.5 : 1, cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        <HiOutlineThumbUp size={16} />
      </CButton>
      <strong className="px-1">{count}</strong>
      <CButton
        size="sm"
        color="link"
        className={classNames('text-xl fw-medium', !isDownvoted && 'text-dark')}
        onClick={onDownvoteClick}
        style={{ opacity: disabled ? 0.5 : 1, cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        <HiOutlineThumbDown size={16} />
      </CButton>
    </div>
  )
}

VotePill.propTypes = {
  count: PropTypes.number,
  isUpvoted: PropTypes.bool,
  isDownvoted: PropTypes.bool,
  disabled: PropTypes.bool,
  vertical: PropTypes.bool,
  onVote: PropTypes.func,
  onUnvote: PropTypes.func,
}

export default VotePill
