import React from 'react'
import PropTypes from 'prop-types'
import { UserLevel, UserLevelLabel } from 'src/constants'
import { CBadge } from '@coreui/react'
import classNames from 'classnames'

const Colors = {
  [UserLevel.normal]: 'secondary',
  [UserLevel.pro]: 'success',
}

const BadgeLevel = ({ isMod, level, className, showNormal }) => {
  if (!isMod && !showNormal && level === UserLevel.normal) return null

  return (
    <CBadge className={classNames('mx-1', className)} color={isMod ? 'info' : Colors[level]}>
      {isMod ? 'MOD' : UserLevelLabel[level]}
    </CBadge>
  )
}

BadgeLevel.propTypes = {
  isMod: PropTypes.bool,
  level: PropTypes.number.isRequired,
  className: PropTypes.string,
  showNormal: PropTypes.bool,
}

export default BadgeLevel
