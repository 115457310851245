import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'src/config/api'
import { toast } from 'react-toastify'

export const deleteCategoryOfAdmin = createAsyncThunk(
  'categories/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await Axios.delete(`admin/category/${id}/`)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Delete Category failed!')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          error.response.data && error.response.data.detail
            ? error.response.data.detail
            : 'Delete Category failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateCategoryOfAdmin = createAsyncThunk(
  'categories/update',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Axios.put(`admin/category/${id}/`, data)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Update Category failed!')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          error.response.data && error.response.data.detail
            ? error.response.data.detail
            : 'Update Category failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const createCategoryOfAdmin = createAsyncThunk(
  'categories/new',
  async (data, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`admin/category/`, data)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Create Category failed!')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          error.response.data && error.response.data.detail
            ? error.response.data.detail
            : 'Create Category failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getCategoryByIdOfAdmin = createAsyncThunk(
  'category/get-detail',
  async (id, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`category/${id}/`)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get category detail failed!')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          error.response.data && error.response.data.detail
            ? error.response.data.detail
            : 'Get category detail failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getCategoriesOfAdmin = createAsyncThunk(
  'categories/admin-all',
  async ({ page = 1, page_size = 10 }, { rejectWithValue }) => {
    try {
      let URL = `admin/category/?page=${page}&page_size=${page_size}`
      const response = await Axios.get(URL)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get categories failed!')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get categories failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getCategories = createAsyncThunk(
  'categories/all',
  async (user, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`category/`)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get categories failed!')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get categories failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    loading: false,
    categories: null,
    adminCategories: null,
    errors: null,
    isCreateCategory: false,
    isDeleteCategory: false,
    category: null,
  },
  reducers: {
    resetCategories: (state) => {
      state.loading = false
      state.errors = null
      state.isCreateCategory = false
      state.isDeleteCategory = false
    },
  },
  extraReducers: (builder) => {
    // Delete Category
    builder.addCase(deleteCategoryOfAdmin.pending, (state) => {
      state.loading = true
      state.isDeleteCategory = false
    })
    builder.addCase(deleteCategoryOfAdmin.fulfilled, (state, action) => {
      state.loading = false
      state.isDeleteCategory = true
    })
    builder.addCase(deleteCategoryOfAdmin.rejected, (state, action) => {
      state.loading = false
      state.isDeleteCategory = false
    })
    // Update Category
    builder.addCase(updateCategoryOfAdmin.pending, (state) => {
      state.loading = true
      state.isCreateCategory = false
    })
    builder.addCase(updateCategoryOfAdmin.fulfilled, (state, action) => {
      state.loading = false
      state.isCreateCategory = true
    })
    builder.addCase(updateCategoryOfAdmin.rejected, (state, action) => {
      state.loading = false
      state.isCreateCategory = false
      state.errors = {
        form: action.payload,
      }
    })
    // create Category
    builder.addCase(createCategoryOfAdmin.pending, (state) => {
      state.loading = true
      state.isCreateCategory = false
    })
    builder.addCase(createCategoryOfAdmin.fulfilled, (state, action) => {
      state.loading = false
      state.isCreateCategory = true
    })
    builder.addCase(createCategoryOfAdmin.rejected, (state, action) => {
      state.loading = false
      state.isCreateCategory = false
      state.errors = {
        form: action.payload,
      }
    })
    // get Category by id
    builder.addCase(getCategoryByIdOfAdmin.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCategoryByIdOfAdmin.fulfilled, (state, action) => {
      state.loading = false
      state.category = action.payload
    })
    builder.addCase(getCategoryByIdOfAdmin.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        getDetail: action.payload,
      }
    })
    // get all categories
    builder.addCase(getCategories.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.loading = false
      state.categories = action.payload
    })
    builder.addCase(getCategories.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        all: action.payload,
      }
    })
    // get categories with admin
    builder.addCase(getCategoriesOfAdmin.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCategoriesOfAdmin.fulfilled, (state, action) => {
      state.loading = false
      state.adminCategories = action.payload
    })
    builder.addCase(getCategoriesOfAdmin.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        all: action.payload,
      }
    })
  },
})

export const { resetCategories } = categoriesSlice.actions

export default categoriesSlice.reducer
