import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useSelector, useDispatch } from 'react-redux'
import { getProfile } from 'src/store/reducers/users'
import { searchCoupons } from 'src/store/reducers/search'
import { getCustomerDashboardStats } from 'src/store/reducers/stats'

import useFirebaseMessage from 'src/hooks/useFirebaseMessage'
import ViewFullScreenModal from 'src/components/common/ViewFullScreenModal'
import UserStatsPanel from 'src/views/app/UserStatsPanel'
import Header from 'src/views/app/Header'
import { AppSidebar } from 'src/components'
import ChangePasswordModal from 'src/components/common/ChangePasswordModal'
import { isAuth } from 'src/utils/role'
import Footer from './Footer'
import styles from './SidebarLayout.module.scss'
import PreferLanguageModal from 'src/views/app/PreferLanguageModal'
import ChallengeCongratModal from 'src/views/app/ChallengeCongratModal'

const PAGE_SIZE = 10

const PublicLayout = ({ children }) => {
  const token = localStorage.getItem('dms_token')
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.users)
  const { openSidebar } = useSelector((state) => state.main)
  const { subscribe, unsubscribe } = useFirebaseMessage()

  useEffect(() => {
    if (!user && token) {
      dispatch(getProfile())
    }

    const fcmHandler = (data) => {
      if (data.notification_type === 'coupon') {
        dispatch(searchCoupons({ page: 1, page_size: PAGE_SIZE }))
        user && dispatch(getCustomerDashboardStats())
      }
    }
    subscribe(fcmHandler)
    return () => unsubscribe(fcmHandler)
  }, [])

  return (
    <div>
      <AppSidebar />
      <div
        className={classNames(
          !isAuth() && 'sidebar-hide',
          openSidebar && 'sidebar-expand',
          'wrapper publicLayout bg-white d-flex flex-column min-vh-100',
        )}
      >
        <Header />
        <div className={classNames('flex-grow-1 d-flex flex-column', styles.layoutContent)}>
          {children}
        </div>
        <Footer />
        <ViewFullScreenModal />
        <UserStatsPanel />
        <ChangePasswordModal />
        <PreferLanguageModal />
        <ChallengeCongratModal />

        {/* <FollowingParticipateModal /> */}
      </div>
    </div>
  )
}

PublicLayout.propTypes = {
  children: PropTypes.node,
}

export default PublicLayout
