import { createSlice } from '@reduxjs/toolkit'
import { VoteType } from 'src/constants'
import {
  createTopic,
  getAnnouncements,
  getLatestTopics,
  getTopicDetail,
  getTopicMentionUsers,
} from 'src/store/actions/topic.action'

const defaultList = {
  page: 1,
  pageSize: 10,
  total: 0,
  results: [],
}

const topicSlice = createSlice({
  name: 'topic',
  initialState: {
    loadingLatestTopics: false,
    loadingAnnouncements: false,
    loadingCreate: false,
    loadingDetail: false,
    currentTopic: null,
    latestTopics: { ...defaultList },
    announcements: { ...defaultList },
    topicVotes: {},
    currentTopicParticipants: [],
    searchSuggestions: { users: [], topics: [], venues: [] },
  },
  reducers: {
    resetLatestTopics: (state) => {
      state.loadingLatestTopics = false
      state.latestTopics = { ...defaultList }
    },
    setCurrentTopic: (state, action) => {
      state.currentTopic = action.payload
    },
    setVoteTopic: (state, action) => {
      const { id, type } = action.payload
      const votes = state.topicVotes[id]
      if (type === VoteType.upvote) {
        state.topicVotes = {
          ...state.topicVotes,
          [id]: {
            is_upvoted: true,
            is_downvoted: false,
            total_upvote: votes.total_upvote + 1,
            total_downvote: votes.total_downvote - (votes.is_downvoted ? 1 : 0),
          },
        }
      } else {
        state.topicVotes = {
          ...state.topicVotes,
          [id]: {
            is_upvoted: false,
            is_downvoted: true,
            total_upvote: votes.total_upvote - (votes.is_upvoted ? 1 : 0),
            total_downvote: votes.total_downvote + 1,
          },
        }
      }
    },
    setUnvoteTopic: (state, action) => {
      const { id, type } = action.payload
      const votes = state.topicVotes[id]
      if (type === VoteType.upvote) {
        state.topicVotes = {
          ...state.topicVotes,
          [id]: {
            ...votes,
            is_upvoted: false,
            total_upvote: votes.total_upvote - 1,
          },
        }
      } else {
        state.topicVotes = {
          ...state.topicVotes,
          [id]: {
            ...votes,
            is_downvoted: false,
            total_downvote: votes.total_downvote - 1,
          },
        }
      }
    },
  },
  extraReducers: (builder) => {
    // get latest topics
    builder.addCase(getLatestTopics.pending, (state) => {
      state.loadingLatestTopics = true
    })
    builder.addCase(getLatestTopics.fulfilled, (state, action) => {
      state.loadingLatestTopics = false
      state.latestTopics.totals = action.payload.totals
      state.latestTopics.results = action.payload.results
      state.latestTopics.page = action.payload.current_page
      state.latestTopics.pageSize = action.payload.page_size
      state.topicVotes = {
        ...action.payload.results.reduce((map, e) => ({ ...map, [e.id]: e.votes }), {}),
      }
    })
    builder.addCase(getLatestTopics.rejected, (state, action) => {
      state.loadingLatestTopics = false
    })
    // create topic
    builder.addCase(createTopic.pending, (state) => {
      state.loadingCreate = true
    })
    builder.addCase(createTopic.fulfilled, (state, action) => {
      state.loadingCreate = false
    })
    builder.addCase(createTopic.rejected, (state, action) => {
      state.loadingCreate = false
    })
    // get topic detail
    builder.addCase(getTopicDetail.pending, (state) => {
      state.loadingDetail = true
    })
    builder.addCase(getTopicDetail.fulfilled, (state, action) => {
      state.loadingDetail = false
      state.currentTopic = action.payload
    })
    builder.addCase(getTopicDetail.rejected, (state, action) => {
      state.loadingDetail = false
    })
    // get announcements
    builder.addCase(getAnnouncements.pending, (state) => {
      state.loadingAnnouncements = true
    })
    builder.addCase(getAnnouncements.fulfilled, (state, action) => {
      state.loadingAnnouncements = false
      state.announcements.totals = action.payload.totals
      state.announcements.results = action.payload.results
      state.announcements.page = action.payload.current_page
      state.announcements.pageSize = action.payload.page_size
      state.topicVotes = {
        ...action.payload.results.reduce((map, e) => ({ ...map, [e.id]: e.votes }), {}),
      }
    })
    builder.addCase(getAnnouncements.rejected, (state, action) => {
      state.loadingAnnouncements = false
    })
    // get topic participants
    builder.addCase(getTopicMentionUsers.fulfilled, (state, action) => {
      state.currentTopicParticipants = action.payload
    })
  },
})

export const { resetLatestTopics, setCurrentTopic, setUnvoteTopic, setVoteTopic } =
  topicSlice.actions

export default topicSlice.reducer
