const routes = {
  /* Public URL */
  HOME: '/',
  LOGIN: '/login',
  VERIFY_LOGIN: '/verify-login',
  REGISTER_ORGANISER: '/register-organiser',
  REGISTER: '/register',
  PREVIEW_QR: '/preview/:code',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  PAGE_404: '/404',
  PAGE_500: '/500',
  HOW_IT_WORKS: '/how-it-works',
  ABOUT_US: '/about-us',
  PRIVACY_POLICY: '/privacy-policy',
  TERM_OF_USE: '/terms-of-use',
  VOUCHERS: '/vouchers',
  TOPICS: '/topics',
  TOPIC_DETAIL: '/topics/:id',
  VIEW_STORE: 'store/:id',
  USER_STATS: '/user/:username',

  /* Admin URL */
  STORE_REGISTRATIONS: '/store-registrations',
  DASHBOARD: '/dashboard/*',
  DASHBOARD_USERS: '/users',
  DASHBOARD_USER_DETAIL: '/users/:id',
  DASHBOARD_USER_CREATE: '/dashboard/user/new',
  SUPPORTS: '/supports',
  ADMIN: '/manage',
  ADMIN_TOPICS: '/manage/topics',
  ADMIN_USERS: '/manage/users',

  /* Store URL */
  ORDERS: '/orders',
  ORDER_DETAIL: '/orders/:id',
  CUSTOMERS: '/customers',
  CATEGORIES: '/categories',
  CATEGORY_DETAIL: '/categories/:id',
  CREATE_CATEGORY: '/categories/new',
  FEEDBACKS: '/feedbacks',
  SETTING_CUSTOMERS: '/settings/customers',
  SETTINGS_STORE_VOUCHERS: '/settings/store-vouchers',
  SETTINGS_STORE_VOUCHERS_CREATE: '/settings/store-vouchers/create',
  SETTINGS_STORE_ORDERS: '/settings/store-orders',
  SETTINGS_STORE_ISSUES: '/settings/store-issues',
  SETTINGS_STORE_ISSUES_CREATE: '/settings/store-issues/create',
  SETTINGS_STORE_FEEDBACKS: '/settings/store-feedbacks',
  SETTINGS_STORE_OVERVIEW: '/settings/store-overview',

  SETTINGS: '/settings',
  SETTING_PROFILE: '/settings/profile',
  SETTING_FOLLOWING: '/settings/following',
  SETTING_USER_ORDERS: '/settings/user-orders',
  SETTING_USER_ISSUES: '/settings/user-issues',
  SETTING_OBJECTIVES: '/settings/rewards',
  SAVED: '/saved',
  SEARCH: '/search',
  CONTACTS: '/contacts',
  REPORT_ISSUE: '/report-issue',
  CONTACT_DETAIL: '/contacts/:contactId',
  VIEW_COUPON: '/store/:storeName/:couponCode',
  MY_COUPONS: '/store/:storeName/my-coupons',
  STORES: '/stores',
  STORE_DETAIL: '/stores/:storeId',
  STORE_FEEDBACKS: '/store/:storeName/feedbacks',
  STORE_CREATE_FEEDBACK: '/store/:storeName/feedbacks/new',
  NOTIFICATIONS: '/notifications',
  CREATE_TOPIC: '/topics/create',
  CREATE_ANNOUNCEMENT: '/topics/create-announcement',
}

export default routes
