import { CButton, CImage, CModal, CModalBody, CModalHeader } from '@coreui/react'
import React, { useEffect, useMemo, useState } from 'react'
import { formatDate } from 'src/utils/format'
import Summary from 'src/views/user/Summary'
import { IMG_PLACEHOLDER, UserRole } from 'src/constants'
import styles from './styles.module.scss'
import classNames from 'classnames'
import BadgeLevel from 'src/components/BadgeLevel'
import { useUserStatsPanelStore } from 'src/store/useUserStatsPanelStore'
import { useShallow } from 'zustand/react/shallow'
import { usePublicUserStore } from 'src/store/usePublicUserStore'
import UserBadges from 'src/views/user/UserBadges'
import { useNavigate } from 'react-router-dom'
import routes from 'src/navigations/routes'
import { toast } from 'react-toastify'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import RecentActivities from './RecentActivities'
import ButtonSpinner from 'src/components/common/ButtonSpinner'
import { useSelector } from 'react-redux'
import { isAuth } from 'src/utils/role'

const UserStatsPanel = () => {
  const navigate = useNavigate()
  const { user: currentUser } = useSelector((state) => state.users)
  const { open, user: publicUser } = useUserStatsPanelStore((state) => ({
    open: state.open,
    user: state.user,
  }))
  const { closePanel } = useUserStatsPanelStore(
    useShallow((state) => ({ closePanel: state.closePanel })),
  )
  const { getUserPublicInfo, getUserStats, followOtherUser, unfollowOtherUser } =
    usePublicUserStore(
      useShallow((state) => ({
        getUserStats: state.getUserStats,
        getUserPublicInfo: state.getUserPublicInfo,
        followOtherUser: state.followOtherUser,
        unfollowOtherUser: state.unfollowOtherUser,
      })),
    )

  const [stats, setStats] = useState(null)
  const [followSubmitting, setFollowSubmitting] = useState(false)

  const isMe = useMemo(() => {
    return isAuth() && publicUser && currentUser && currentUser.username === publicUser.username
  }, [currentUser, publicUser])

  useEffect(() => {
    if (!publicUser) return
    getUserStats(publicUser.username).then((res) => setStats(res))
  }, [publicUser])

  const isMod = useMemo(() => {
    if (!publicUser) return false
    return publicUser.roles.some((e) => e === UserRole.moderator)
  }, [publicUser])

  useEffect(() => {
    const escapeHandler = (e) => {
      if (e.key === 'Escape') {
        closePanel()
      }
    }
    window.onpopstate = () => {
      closePanel()
    }
    window.addEventListener('keyup', escapeHandler)
    return () => {
      window.removeEventListener('keyup', escapeHandler)
    }
  }, [])

  const onClose = () => {
    closePanel()
  }

  const onFollow = async () => {
    if (!isAuth()) return toast.error('You need to login to follow user')
    try {
      setFollowSubmitting(true)
      if (publicUser.is_follow) await unfollowOtherUser(publicUser.id)
      else await followOtherUser(publicUser.id)
      await getUserPublicInfo(publicUser.username)
    } finally {
      setFollowSubmitting(false)
    }
  }

  return (
    <CModal size="xl" alignment="center" backdrop="static" visible={open} onClose={onClose}>
      <CModalHeader closeButton />
      <CModalBody>
        <div className={classNames(styles.userStats, 'bg-white h-100 overflow-scroll')}>
          {publicUser && (
            <div>
              <div className="d-flex align-items-start gap-4">
                <CImage
                  src={publicUser.profile.avatar || IMG_PLACEHOLDER}
                  width={80}
                  height={80}
                  className="rounded-circle border"
                />
                <div className="flex-grow-1 d-flex flex-column gap-2">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="text-2xl fw-medium me-2">
                        {publicUser.first_name} {publicUser.last_name}
                      </div>
                      <i>@{publicUser.username}</i>
                    </div>
                    <div className="d-flex align-items-center gap-1">
                      <CopyToClipboard
                        text={`${window?.location?.origin}${routes.USER_STATS.replace(
                          ':username',
                          publicUser.username,
                        )}`}
                        onCopy={() => toast.success('Copied')}
                      >
                        <CButton size="sm">Share</CButton>
                      </CopyToClipboard>
                      {!isMe && (
                        <CButton
                          size="sm"
                          color={publicUser.is_follow ? 'danger' : 'success'}
                          disabled={followSubmitting}
                          onClick={onFollow}
                        >
                          {followSubmitting && <ButtonSpinner />}
                          {publicUser.is_follow ? 'Unfollow' : 'Follow'}
                        </CButton>
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <BadgeLevel isMod={isMod} level={publicUser.profile.level} />
                    <span className="text-black-50 text-sm fw-medium">
                      joined from {formatDate(publicUser.date_joined)}
                    </span>
                  </div>
                  <div className="text-sm" style={{ whiteSpace: 'pre-line' }}>
                    {publicUser.profile.description}
                  </div>
                </div>
              </div>
              <UserBadges username={publicUser.username} />
              <hr />
              <Summary stats={stats} />
              <hr />
              <RecentActivities
                username={publicUser.username}
                onClickTabStats={() => {
                  navigate(routes.USER_STATS.replace(':username', publicUser.username))
                  closePanel()
                }}
              />
            </div>
          )}
        </div>
      </CModalBody>
    </CModal>
  )
}

export default UserStatsPanel
