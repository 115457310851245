import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { firebaseConfig } from '../constants'

// Initialize the Firebase app in the service worker by passing in
// your app's Firebase config object.
// https://firebase.google.com/docs/web/setup#config-object
const firebaseApp = initializeApp(firebaseConfig)

export const getMessagingToken = async () => {
  let currentToken = ''
  const messaging = getMessaging(firebaseApp)
  if (!messaging) return
  try {
    currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_FCM_VAPID_KEY,
    })
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error)
  }
  return currentToken
}

/* handleFunc: (payload: { data }) => void */
export const registerFCMListener = (handleFunc) => {
  const isValid = firebaseApp?._container?.providers?.size > 1
  if (isValid) {
    const messaging = getMessaging(firebaseApp)
    onMessage(messaging, handleFunc)
  }
}
