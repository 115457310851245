import React from 'react'
import PropTypes from 'prop-types'
import { useUserStatsPanelStore } from 'src/store/useUserStatsPanelStore'
import { useShallow } from 'zustand/react/shallow'

function MentionableText({ className, text, disabled }) {
  const { openPanel: openUserStatsPanel } = useUserStatsPanelStore(
    useShallow((state) => ({ openPanel: state.openPanel })),
  )
  const formatMentionableText = (text) => {
    const mentionRegex = /@\[([^\]]+)\]\(([^)]+)\)/g
    const parts = text.split(mentionRegex)

    const onClickMention = async (username) => {
      if (disabled || username === '@all') return
      openUserStatsPanel({ username })
    }

    return parts.map((part, index) => {
      if (index % 3 === 0) {
        return part
      } else if (index % 3 === 1) {
        const username = parts[index + 1]
        const name = part
        return (
          <span
            key={index}
            className="text-info"
            style={{ fontWeight: 500, fontStyle: 'italic', cursor: 'pointer' }}
            onClick={() => onClickMention(username)}
          >
            {name}
          </span>
        )
      } else {
        return '' // Remove parentheses
      }
    })
  }

  const formattedText = formatMentionableText(text)

  return (
    <div className={className} style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}>
      {formattedText}
    </div>
  )
}

MentionableText.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

export default MentionableText
