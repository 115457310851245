import { createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'src/config/api'
import { toast } from 'react-toastify'

export const getAllTopicsAdmin = createAsyncThunk(
  'topic/all-topics-admin',
  async ({ page = 1, pageSize = 10 }, { rejectWithValue }) => {
    try {
      const response = await Axios.get('admin/topic/', {
        params: {
          page: page,
          page_size: pageSize,
        },
      })

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get topics failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get topics failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const approveTopicAdmin = createAsyncThunk(
  'topic/approve-topic-admin',
  async (id, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`admin/topic/${id}/approve`)
      toast.success('Approved successfully')
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Failed to approve, please contact support if issue persists')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data
          ? error.response.data.detail
          : 'Failed to approve, please contact support if issue persists'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const rejectTopicAdmin = createAsyncThunk(
  'topic/reject-topic-admin',
  async ({ id, message }, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`admin/topic/${id}/reject`, { message })
      toast.success('Rejected')
      return response.data
    } catch (error) {
      let message = 'Failed to reject, please contact support if issue persists'
      if (!error.response) {
        toast.error(message)
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data && error.response.data.detail) {
        message = error.response.data.detail
        toast.error(message)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const requestInfoTopicAdmin = createAsyncThunk(
  'topic/request-info-topic-admin',
  async ({ id, message }, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`admin/topic/${id}/request-info`, { message })
      toast.success('Requested info successfully')
      return response.data
    } catch (error) {
      let message = 'Failed to request for info, please contact support if issue persists'
      if (!error.response) {
        toast.error(message)
        throw error
      }
      if (error.response.data && error.response.data.detail) {
        message = error.response.data.detail
        toast.error(message)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getLatestTopics = createAsyncThunk(
  'topic/latest-topics',
  async (
    { page = 1, pageSize = 10, search = '', orderBy = '', category = '', language = '' },
    { rejectWithValue },
  ) => {
    try {
      const response = await Axios.get('topics/', {
        params: {
          page: page,
          page_size: pageSize,
          search,
          sort_by: orderBy ? orderBy : null,
          category,
          lang: language === 'all' ? undefined : language,
        },
      })

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get topics failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get topics failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const createTopic = createAsyncThunk(
  'topic/create-topic',
  async ({ title, content, category, language, images }, { rejectWithValue }) => {
    try {
      let formData = new FormData()
      if (images.length > 0) {
        images.forEach((file) => {
          formData.append('images', file, file.name)
        })
      }
      formData.append('title', title)
      formData.append('content', content)
      formData.append('category', category)
      formData.append('language', language)

      const response = await Axios.post('topics/', formData)
      toast.success('Your topic is under review')
      return response.data
    } catch (error) {
      let message = 'Topic creation failed, please contact support if issue persists'
      if (!error.response) {
        toast.error(message)
        throw error
      }
      if (error.response.data && error.response.data.detail) {
        message = error.response.data.detail
        toast.error(message)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getTopicDetail = createAsyncThunk(
  'topic/topic-detail',
  async (id, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`topics/${id}/`)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get topic failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get topics failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const editTopic = createAsyncThunk(
  'topic/edit-topic',
  async ({ topicId, title, content, category, images, deleteImages }, { rejectWithValue }) => {
    try {
      let formData = new FormData()
      if (images.length > 0) {
        images.forEach((file) => {
          formData.append('images', file, file.name)
        })
      }
      if (deleteImages.length > 0) deleteImages.forEach((e) => formData.append('delete_images', e))
      formData.append('title', title)
      formData.append('content', content)
      formData.append('topic_id', topicId)
      formData.append('category', category)

      const response = await Axios.patch(`topics/${topicId}/`, formData)
      toast.success('Update successfully')
      return response.data
    } catch (error) {
      let message = 'Failed to update, please contact support if issue persists'
      if (!error.response) {
        toast.error(message)
        throw error
      }
      if (error.response.data && error.response.data.detail) {
        message = error.response.data.detail
        toast.error(message)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const deleteTopic = createAsyncThunk(
  'topic/delete-topic',
  async (id, { rejectWithValue }) => {
    try {
      const response = await Axios.delete(`topics/${id}/`)
      toast.success('Delete successfully')
      return response.data
    } catch (error) {
      let message = 'Failed to delete, please contact support if issue persists'
      if (!error.response) {
        toast.error(message)
        throw error
      }
      if (error.response.data && error.response.data.detail) {
        message = error.response.data.detail
        toast.error(message)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getRepliesOfUser = createAsyncThunk(
  'topic/replies-of-user',
  async ({ username, page = 1, pageSize = 10 }, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`topics/replies/`, {
        params: {
          user: username,
          page,
          page_size: pageSize,
        },
      })
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get replies failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get replies failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getTopicsOfUser = createAsyncThunk(
  'topic/topics-of-user',
  async ({ username, page = 1, pageSize = 10 }, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`topics/topics-of-user/`, {
        params: {
          user: username,
          page,
          page_size: pageSize,
        },
      })
      return response.data
    } catch (error) {
      let message = 'Get topics failed'
      if (!error.response) {
        toast.error(message)
        throw error
      }
      if (error.response.data && error.response.data.detail) {
        message = error.response.data.detail
        toast.error(message)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const voteTopic = createAsyncThunk(
  'topic/vote-topic',
  async ({ topicId, isVote, type }, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: isVote ? 'POST' : 'DELETE',
        url: `topics/${topicId}/vote/`,
        params: {
          type,
        },
      })
      return response.data
    } catch (error) {
      let message = 'Failed to vote, please contact support if issue persists'
      if (!error.response) {
        toast.error(message)
        throw error
      }
      if (error.response.data && error.response.data.detail) {
        message = error.response.data.detail
        toast.error(message)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateTopicViewCount = createAsyncThunk(
  'topic/update-view-count',
  async (id, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`topics/${id}/update-view/`)
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Update failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Update failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const createAnnouncement = createAsyncThunk(
  'topic/create-announcement',
  async ({ title, content, images, is_announcement }, { rejectWithValue }) => {
    try {
      let formData = new FormData()
      if (images.length > 0) {
        images.forEach((file) => {
          formData.append('images', file, file.name)
        })
      }
      formData.append('title', title)
      formData.append('content', content)
      formData.append('is_announcement', is_announcement)

      const response = await Axios.post('announcements/', formData)
      toast.success('Announcement created successfully')
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Failed to create announcement')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data
          ? error.response.data.detail
          : 'Failed to create announcement'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getAnnouncements = createAsyncThunk(
  'topic/get-announcements',
  async ({ page = 1, pageSize = 10 }, { rejectWithValue }) => {
    try {
      const response = await Axios.get('announcements/', {
        params: { page, page_size: pageSize },
      })
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getTopAuthors = createAsyncThunk(
  'topic/get-top-authors',
  async ({ page = 1, pageSize = 10 }, { rejectWithValue }) => {
    try {
      const response = await Axios.get('topics/top-authors', {
        params: { page, page_size: pageSize },
      })
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getTopVotedTopics = createAsyncThunk(
  'topic/get-top-voted-topics',
  async ({ page = 1, pageSize = 10 }, { rejectWithValue }) => {
    try {
      const response = await Axios.get('topics/top-voted', {
        params: { page, page_size: pageSize },
      })
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get failed')
        throw error
      }
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)
export const pinTopic = createAsyncThunk(
  'topic/pin-topic',
  async ({ topicId, isPin }, { rejectWithValue }) => {
    try {
      const response = await Axios({
        method: isPin ? 'POST' : 'DELETE',
        url: `topics/${topicId}/pin/`,
      })
      return response.data
    } catch (error) {
      let message = 'Failed to pin'
      if (!error.response) {
        toast.error(message)
        throw error
      }
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getTopicMentionUsers = createAsyncThunk(
  'topic/get-participants',
  async (topicId, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`topics/${topicId}/mention-users/`)
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get participants failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get participants failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)
