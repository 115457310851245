import { configureStore } from '@reduxjs/toolkit'

import main from './reducers/main'
import auth from './reducers/auth'
import users from './reducers/users'
import categories from './reducers/categories'
import coupons from './reducers/coupons'
import search from './reducers/search'
import saved from './reducers/saved'
import stores from './reducers/stores'
import stats from './reducers/stats'
import orders from './reducers/orders'
import contact from './reducers/contact'
import feedback from './reducers/feedback'
import fcm from './reducers/fcm'
import notifications from './reducers/notifications'
import registrationStore from './reducers/registrationStore'
import topic from './reducers/topic'

export const store = configureStore({
  reducer: {
    main,
    auth,
    users,
    categories,
    coupons,
    search,
    saved,
    stores,
    stats,
    orders,
    contact,
    feedback,
    fcm,
    notifications,
    registrationStore,
    topic,
  },
})
