import { toast } from 'react-toastify'
import Axios from 'src/config/api'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { useTopicReplyStore } from './useTopicReplyStore'

export const useHighlightStore = create()(
  immer((set, get) => ({
    getHighlight: async (id) => {
      try {
        const response = await Axios.get(`topics/highlights/${id}/`)
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    getUserHighlights: async (username) => {
      try {
        const response = await Axios.get(`topics/highlights/`, { params: { username } })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    createHighlight: async ({ scheduleId, topicId, content, occuredAt, attachments, newUsers }) => {
      try {
        let formData = new FormData()
        if (attachments.length > 0) {
          attachments.forEach((file) => {
            formData.append('attachments', file, file.name)
          })
        }
        formData.append('schedule_id', scheduleId)
        formData.append('content', content)
        if (occuredAt) formData.append('occured_at', occuredAt)
        if (newUsers.length > 0) newUsers.forEach((e) => formData.append('new_users', e))

        const response = await Axios.post(`topics/highlights/`, formData)
        toast.success('Create highlight successfully')
        useTopicReplyStore.getState().setReloadNewReplyInTopic(topicId)

        return response.data
      } catch (error) {
        let message = 'Create failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
        throw message
      }
    },
    editHighlight: async ({
      id,
      content,
      topicId,
      scheduleId,
      attachments,
      deleteAttachments,
      occuredAt,
      newUsers,
      deleteUsers,
    }) => {
      try {
        let formData = new FormData()
        if (attachments.length > 0) {
          attachments.forEach((file) => {
            formData.append('attachments', file, file.name)
          })
        }
        if (deleteAttachments.length > 0)
          deleteAttachments.forEach((e) => formData.append('delete_attachments', e))

        if (content) formData.append('content', content)
        if (scheduleId) formData.append('schedule_id', scheduleId)
        if (occuredAt) formData.append('occured_at', occuredAt)

        if (deleteUsers.length > 0) deleteUsers.forEach((e) => formData.append('delete_users', e))
        if (newUsers.length > 0) newUsers.forEach((e) => formData.append('new_users', e))

        const response = await Axios.patch(`topics/highlights/${id}/`, formData)
        toast.success('Updated successfully')
        useTopicReplyStore.getState().setReloadEditedReply(id)

        return response.data
      } catch (error) {
        console.log('🚀 ~ immer ~ error:', error)
        let message = 'Update failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    voteHighlight: async (id, type) => {
      try {
        const response = await Axios.post(`topics/highlights/${id}/vote/`, null, {
          params: {
            type,
          },
        })
        return response.data
      } catch (error) {
        let message = 'Vote failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
  })),
)
