import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Topics from './Topics'
import Polls from './Polls'
import Replies from './Replies'
import classNames from 'classnames'
import Timeline from './Timeline'
import PostedQuestion from './PostedQuestions'
import ReceivedFeedbacks from './ReceivedFeedbacks'
import GivenFeedbacks from './GivenFeedbacks'

const TAB = {
  highlight: 0,
  topic: 1,
  reply: 2,
  poll: 3,
  questions: 4,
  received: 5,
  given: 6,
}

const RecentActivities = ({ username, onClickTabStats }) => {
  const [tab, setTab] = useState(TAB.received)

  return (
    <div>
      <CNav variant="pills" role="tablist" className="tab-nav">
        {/* <CNavItem>
          <CNavLink
            active={tab === TAB.topic}
            onClick={() => setTab(TAB.topic)}
            style={{ cursor: 'pointer' }}
          >
            Posts
          </CNavLink>
        </CNavItem> */}
        {/* <CNavItem>
          <CNavLink
            active={tab === TAB.highlight}
            onClick={() => setTab(TAB.highlight)}
            style={{ cursor: 'pointer' }}
            className={classNames(tab === TAB.highlight && 'text-info border-info')}
          >
            Timeline
          </CNavLink>
        </CNavItem> */}
        <CNavItem>
          <CNavLink
            active={tab === TAB.received}
            onClick={() => setTab(TAB.received)}
            style={{ cursor: 'pointer' }}
            className={classNames(tab === TAB.received && 'text-info border-info')}
          >
            Received
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            active={tab === TAB.given}
            onClick={() => setTab(TAB.given)}
            style={{ cursor: 'pointer' }}
            className={classNames(tab === TAB.given && 'text-info border-info')}
          >
            Given
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            active={tab === TAB.questions}
            onClick={() => setTab(TAB.questions)}
            style={{ cursor: 'pointer' }}
            className={classNames(tab === TAB.questions && 'text-info border-info')}
          >
            Questions
          </CNavLink>
        </CNavItem>
        {/* <CNavItem>
          <CNavLink
            active={tab === TAB.poll}
            onClick={() => setTab(TAB.poll)}
            style={{ cursor: 'pointer' }}
            className={classNames(tab === TAB.poll && 'text-info border-info')}
          >
            Polls
          </CNavLink>
        </CNavItem> */}
        {/* <CNavItem>
          <CNavLink
            active={tab === TAB.reply}
            onClick={() => setTab(TAB.reply)}
            style={{ cursor: 'pointer' }}
            className={classNames(tab === TAB.reply && 'text-info border-info')}
          >
            Comments
          </CNavLink>
        </CNavItem> */}
        {onClickTabStats && (
          <CNavItem>
            <CNavLink onClick={onClickTabStats} style={{ cursor: 'pointer' }}>
              Stats
            </CNavLink>
          </CNavItem>
        )}
      </CNav>
      <CTabContent>
        {/* <CTabPane className="mt-3" role="tabpanel" visible={tab === TAB.topic}>
          <Topics username={username} />
        </CTabPane> */}
        {/* <CTabPane className="mt-3" role="tabpanel" visible={tab === TAB.highlight}>
          <Timeline username={username} />
        </CTabPane> */}
        <CTabPane className="mt-3" role="tabpanel" visible={tab === TAB.received}>
          <ReceivedFeedbacks username={username} />
        </CTabPane>
        <CTabPane className="mt-3" role="tabpanel" visible={tab === TAB.given}>
          <GivenFeedbacks username={username} />
        </CTabPane>
        <CTabPane className="mt-3" role="tabpanel" visible={tab === TAB.questions}>
          <PostedQuestion username={username} />
        </CTabPane>
        <CTabPane className="mt-3" role="tabpanel" visible={tab === TAB.reply}>
          <Replies username={username} />
        </CTabPane>
        {/* <CTabPane className="mt-3" role="tabpanel" visible={tab === TAB.poll}>
          <Polls username={username} />
        </CTabPane> */}
      </CTabContent>
    </div>
  )
}

RecentActivities.propTypes = {
  username: PropTypes,
  onClickTabStats: PropTypes.func,
}

export default RecentActivities
