import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
  CBadge,
  CRow,
  CCol,
} from '@coreui/react'
import { FaEye, FaComment, FaTimes, FaCheck } from 'react-icons/fa'

import { ReactPagination } from 'src/components/common'
import { approveTopicAdmin, getAllTopicsAdmin } from 'src/store/actions/topic.action'
import { formatRelativeDate } from 'src/utils/format'
import { TopicStatus, TopicStatusLabel } from 'src/constants'
import { useNavigate } from 'react-router-dom'
import { isAdmin, isModerator } from 'src/utils/role'
import routes from 'src/navigations/routes'
import AdminRejectTopicModal from 'src/views/topics/AdminRejectTopicModal'
import AdminRequestInfoTopicModal from 'src/views/topics/AdminRequestInfoTopicModal'

const PAGE_SIZE = 20
const StatusColor = {
  [TopicStatus.pending]: 'warning',
  [TopicStatus.approved]: 'success',
  [TopicStatus.rejected]: 'danger',
  [TopicStatus.need_info]: 'info',
}

const TopicsAdmin = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.users)

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [rejectModal, setRejectModal] = useState({ open: false, data: null })
  const [requestModal, setRequestModal] = useState({ open: false, data: null })

  useEffect(() => {
    if (!isAdmin() && !isModerator()) navigate(routes.HOME)
  }, [user])

  useEffect(() => {
    fetchData(page)
  }, [page])

  const fetchData = useCallback(
    async (page) => {
      try {
        setLoading(true)
        const res = await dispatch(getAllTopicsAdmin({ page, pageSize }))
        if (res.error) return
        setData(res.payload.results)
        setTotal(res.payload.totals)
      } finally {
        setLoading(false)
      }
    },
    [dispatch, pageSize],
  )

  const onApprove = async (id) => {
    const res = await dispatch(approveTopicAdmin(id))
    if (res.error) return
    fetchData(page)
  }
  const onReject = async (item) => {
    setRejectModal({ open: true, data: item })
  }
  const onRequest = async (item) => {
    setRequestModal({ open: true, data: item })
  }

  const onChangePage = (p) => {
    setPage(p)
  }

  return (
    <div>
      <h3>All Topics</h3>
      <CTable responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Category</CTableHeaderCell>
            <CTableHeaderCell scope="col">Title</CTableHeaderCell>
            <CTableHeaderCell scope="col">Author</CTableHeaderCell>
            <CTableHeaderCell scope="col">Language</CTableHeaderCell>
            <CTableHeaderCell
              scope="col"
              style={{ minWidth: 200 }}
              width={200}
              className="text-center"
            >
              Actions
            </CTableHeaderCell>
            <CTableHeaderCell scope="col">Status</CTableHeaderCell>
            <CTableHeaderCell scope="col" style={{ minWidth: 120 }} width={120}>
              Created
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" style={{ minWidth: 130 }} width={130}>
              Approved By
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {data.map((item, i) => {
            const categories = item.category ? item.category.split(',') : []
            return (
              <CTableRow key={i}>
                <CTableDataCell style={{ minWidth: 200, maxWidth: 300 }}>
                  <div className="d-flex flex-wrap gap-1">
                    {categories.map((e) => (
                      <CBadge key={e} color="primary">
                        {e}
                      </CBadge>
                    ))}
                  </div>
                </CTableDataCell>
                <CTableDataCell style={{ minWidth: 300 }}>{item.title}</CTableDataCell>
                <CTableDataCell>@{item.user.username}</CTableDataCell>
                <CTableDataCell className="text-center">{item.language}</CTableDataCell>
                <CTableDataCell>
                  <CRow>
                    <CCol xs={3}>
                      {item.status !== TopicStatus.approved && (
                        <CButton
                          size="sm"
                          color="link"
                          className="fw-bold text-success text-sm"
                          onClick={() => onApprove(item.id)}
                        >
                          <FaCheck />
                        </CButton>
                      )}
                    </CCol>
                    <CCol xs={3}>
                      {item.status !== TopicStatus.rejected && (
                        <CButton
                          size="sm"
                          color="link"
                          className="fw-bold text-danger text-sm"
                          onClick={() => onReject(item)}
                        >
                          <FaTimes />
                        </CButton>
                      )}
                    </CCol>
                    <CCol xs={3}>
                      {item.status !== TopicStatus.approved && (
                        <CButton
                          size="sm"
                          color="link"
                          className="fw-bold text-warning text-sm"
                          onClick={() => onRequest(item)}
                        >
                          <FaComment />
                        </CButton>
                      )}
                    </CCol>
                    <CCol xs={3}>
                      <CButton
                        size="sm"
                        color="link"
                        className="fw-bold text-info text-sm"
                        onClick={() => navigate(`${routes.ADMIN_TOPICS}/${item.id}/`)}
                      >
                        <FaEye />
                      </CButton>
                    </CCol>
                  </CRow>
                </CTableDataCell>
                <CTableDataCell>
                  <CBadge color={StatusColor[item.status]}>{TopicStatusLabel[item.status]}</CBadge>
                </CTableDataCell>
                <CTableDataCell>{formatRelativeDate(item.created_at)}</CTableDataCell>
                <CTableDataCell>
                  {item.approved_by ? `@${item.approved_by.username}` : ''}
                </CTableDataCell>
              </CTableRow>
            )
          })}
        </CTableBody>
      </CTable>
      <ReactPagination
        page={page}
        size={PAGE_SIZE}
        total={total}
        onChange={onChangePage}
        loading={loading}
      />
      <AdminRejectTopicModal
        open={rejectModal.open}
        data={rejectModal.data}
        onSaved={() => fetchData(page)}
        onClose={() => setRejectModal({ open: false, data: null })}
      />
      <AdminRequestInfoTopicModal
        open={requestModal.open}
        data={requestModal.data}
        onSaved={() => fetchData(page)}
        onClose={() => setRequestModal({ open: false, data: null })}
      />
    </div>
  )
}

export default TopicsAdmin
