import React from 'react'
import TopicsAdmin from './Topics'
import TopicDetailAdmin from './TopicDetail'

const Dashboard = React.lazy(() => import('../manage-store/Overview'))

// main pages
const Stores = React.lazy(() => import('../manage-store/Stores'))
const StoreDetail = React.lazy(() => import('../manage-store/StoreDetail'))
const Coupons = React.lazy(() => import('../manage-store/Coupons'))
const NewCoupon = React.lazy(() => import('../manage-store/NewCoupon'))
const CouponDetail = React.lazy(() => import('../manage-store/CouponDetail'))
const Orders = React.lazy(() => import('../manage-store/Orders'))
const OrderDetail = React.lazy(() => import('../manage-store/OrderDetail'))
const Profile = React.lazy(() => import('../customers/Profile'))
const Contacts = React.lazy(() => import('../manage-store/Contacts'))
const ContactDetail = React.lazy(() => import('../manage-store/ContactDetail'))
const ContactNew = React.lazy(() => import('../manage-store/CreateIssue'))
const Feedbacks = React.lazy(() => import('../dashboard/Feedbacks'))
const StoreRegistrations = React.lazy(() => import('../dashboard/StoreRegistrations'))
const Customers = React.lazy(() => import('../manage-store/Customers'))
// admin
const Users = React.lazy(() => import('./Users'))
const Categories = React.lazy(() => import('./Categories'))
const CategoryForm = React.lazy(() => import('./CategoryForm'))
const UserForm = React.lazy(() => import('./UserForm'))
const Supports = React.lazy(() => import('../dashboard/Supports'))

const adminRoutes = [
  { path: '/coupons/new', name: 'New Voucher', element: NewCoupon },
  { path: '/coupons/:couponId', name: 'Update Voucher', element: CouponDetail },
  { path: '/coupons', name: 'Coupons', element: Coupons },
  { path: '/profile', name: 'Profile', element: Profile },
  { path: '/organisers/:id', name: 'Store Detail', element: StoreDetail },
  { path: '/organisers', name: 'Stores', element: Stores },
  { path: '/organiser-registrations', name: 'Stores', element: StoreRegistrations },
  { path: '/orders/:id', name: 'Order Detail', element: OrderDetail },
  { path: '/orders', name: 'Orders', element: Orders },
  { path: '/customers', name: 'Users', element: Customers },
  { path: '/users', name: 'Users', element: Users },
  { path: '/users/:id', name: 'Update User', element: UserForm },
  { path: '/users/new', name: 'New User', element: UserForm },
  { path: '/categories', name: 'Categories', element: Categories },
  { path: '/categories/new', name: 'New Category', element: CategoryForm },
  { path: '/categories/:id', name: 'Update Category', element: CategoryForm },
  { path: '/contacts', name: 'Contacts', element: Contacts },
  { path: '/contacts/new', name: 'New Contact', element: ContactNew },
  { path: '/contacts/:id', name: 'Contact Detail', element: ContactDetail },
  { path: '/feedbacks', name: 'Feedbacks', element: Feedbacks },
  { path: '/supports', name: 'Supports', element: Supports },
  // { path: '/', name: 'Dashboard', element: Dashboard },
  { path: '/', name: 'Topics', element: TopicsAdmin },
  { path: '/topics', name: 'Topics', element: TopicsAdmin },
  { path: '/topics/:id/', name: 'Topic Detail', element: TopicDetailAdmin },
  // { path: '/', exact: true, name: 'Home' },
]

export default adminRoutes
