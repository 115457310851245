import React from 'react'
import PropTypes from 'prop-types'
import { CImage } from '@coreui/react'
import useViewFullImage from 'src/hooks/useViewFullImage'
import classNames from 'classnames'
import { IMG_PLACEHOLDER } from 'src/constants'

const ClickableImage = ({
  src,
  onClick,
  width = 100,
  height = 100,
  className,
  style,
  onTouchStart,
  onTouchMove,
  onTouchEnd,
}) => {
  const { openFullImage } = useViewFullImage()

  const onImageClick = () => {
    openFullImage({ src })
  }

  return (
    <CImage
      src={src || IMG_PLACEHOLDER}
      className={classNames(className, 'rounded border')}
      width={width}
      height={height}
      style={{ objectFit: 'cover', cursor: 'pointer', ...style }}
      draggable={false}
      onClick={onClick || onImageClick}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    />
  )
}

ClickableImage.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onTouchStart: PropTypes.func,
  onTouchMove: PropTypes.func,
  onTouchEnd: PropTypes.func,
}

export default ClickableImage
