import { CTooltip } from '@coreui/react'
import React, { useEffect } from 'react'
import UploadingIcon from 'src/components/UploadingIcon'
import { useBackgroundUploadStore } from 'src/store/useBackgroundUploadStore'
import { isAuth } from 'src/utils/role'

const UploadingMenu = () => {
  const uploadingCount = useBackgroundUploadStore((state) => state.files.length)

  useEffect(() => {
    if (uploadingCount)
      window.onbeforeunload = () => {
        return 'There are files uploading currently. Are you sure you want to leave?'
      }
    else window.onbeforeunload = null
    return () => {
      window.onbeforeunload = null
    }
  }, [uploadingCount])

  if (!isAuth()) return null

  return (
    <CTooltip
      content={
        uploadingCount > 0
          ? `There are ${uploadingCount} files uploading currently. Please keep this tab open.`
          : 'There is no uploading file'
      }
    >
      <div className="d-flex align-items-center">
        <UploadingIcon size={20} disabled={uploadingCount === 0} />
        {uploadingCount > 0 && <strong className="text-sm">{uploadingCount}</strong>}
      </div>
    </CTooltip>
  )
}

export default UploadingMenu
