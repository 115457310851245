import React from 'react'
import PropTypes from 'prop-types'
import QRCode from 'react-qr-code'

const ShowQR = ({ code }) => {
  return (
    <div style={{ maxWidth: '300px' }}>
      <QRCode
        level="Q"
        size={256}
        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
        value={code}
        viewBox={`0 0 256 256`}
      />
    </div>
  )
}

ShowQR.propTypes = {
  code: PropTypes.string,
}

export default ShowQR
