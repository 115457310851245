import { RecurrenceEndType, RecurrenceOccurType, RecurrenceType } from 'src/constants'
import { formatDate, formatDateToApi } from './format'
import dayjs from 'dayjs'

export const OrdinalValue = {
  first: 0,
  second: 1,
  third: 2,
  forth: 3,
  last: 4,
}

export const getRecurrenceEndDate = ({
  start_time,
  end_time,
  recurrence_type,
  recurrence_repeat,
  recurrence_occur,
  recurrence_occur_type,
  recurrence_occur_ordinal,
  recurrence_end_type,
  recurrence_end_date,
  recurrence_end_occurrences,
}) => {
  if (recurrence_end_type === RecurrenceEndType.none) return null
  if (recurrence_end_type === RecurrenceEndType.date) {
    return formatDateToApi(
      dayjs(recurrence_end_date)
        .set('h', dayjs(end_time).hour())
        .set('m', dayjs(end_time).minute())
        .set('s', dayjs(end_time).second()),
    )
  }
  if (recurrence_type === RecurrenceType.daily) {
    const lastDate = dayjs(end_time).add(recurrence_repeat * (recurrence_end_occurrences - 1), 'd')
    return formatDateToApi(lastDate)
  } else if (recurrence_type === RecurrenceType.weekly) {
    let firstDate = dayjs(end_time).set('day', recurrence_occur)
    if (firstDate.isBefore(start_time)) firstDate = firstDate.add(1, 'w')
    const lastDate = firstDate.add(recurrence_repeat * (recurrence_end_occurrences - 1), 'w')
    return formatDateToApi(lastDate)
  } else {
    if (recurrence_occur_type === RecurrenceOccurType.day) {
      let firstDate = dayjs(end_time).set('date', recurrence_occur)
      if (firstDate.isBefore(start_time)) firstDate = firstDate.add(1, 'M')
      const lastDate = firstDate.add(recurrence_repeat * (recurrence_end_occurrences - 1), 'M')
      return formatDateToApi(lastDate)
    } else {
      let firstDate = dayjs(end_time).set('date', 1)
      firstDate =
        firstDate.day() === 0 ? firstDate : firstDate.set('day', recurrence_occur).add(1, 'w')
      let lastDate = firstDate.add(recurrence_repeat * (recurrence_end_occurrences - 1), 'M')
      lastDate = lastDate.set('date', 1)
      lastDate = lastDate.day() === 0 ? lastDate : lastDate.set('day', recurrence_occur).add(1, 'w')
      return formatDateToApi(lastDate)
    }
  }
}

export const getScheduleDisplayName = (schedule, options = {}) => {
  if (!schedule) return 'a schedule (removed)'
  const defaultOptions = { showCategory: true, showVenue: false }
  const mergeOptions = { ...defaultOptions, ...options }
  let hours = dayjs(schedule.end_time).diff(dayjs(schedule.start_time), 'h', true)
  hours = Math.round(hours * 10) / 10
  return [
    `${mergeOptions.showCategory ? schedule.category : ''}${
      mergeOptions.showVenue && schedule.venue
        ? ` at ${schedule.venue.name.split('-')[0].trim()}`
        : ''
    }`,
    `${formatDate(schedule.next_time || schedule.start_time, 'ddd DD MMM hh:mm a')}`,
    `${hours} hour${hours > 1 ? 's' : ''}`,
  ].join(' - ')
}

export const formatScheduleWithQuestion = (schedule) => {
  if (!schedule) return 'a schedule (removed)'
  return [
    getScheduleQuestion(schedule),
    `${formatDate(schedule.start_time, 'ddd DD MMM')}`,
    `${formatDate(schedule.end_time, 'ddd DD MMM')}`,
  ].join(' - ')
}

export const formatRecurringSchedule = ({
  end_time,
  recurrence_type,
  recurrence_repeat,
  recurrence_occur,
  recurrence_occur_type,
  recurrence_occur_ordinal,
  recurrence_end_occurrences,
  recurrence_end_date,
  recurrence_end_type,
}) => {
  let text = 'Every'
  const UnitLabel = {
    [RecurrenceType.daily]: 'day',
    [RecurrenceType.weekly]: 'week',
    [RecurrenceType.monthly]: 'month',
  }
  if (recurrence_repeat > 1) text += ` ${recurrence_repeat} ${UnitLabel[recurrence_type]}s`
  else text += ` ${UnitLabel[recurrence_type]}`
  // Recurrence occur
  if (recurrence_type === RecurrenceType.weekly) {
    text += ` on ${dayjs().day(recurrence_occur).format('ddd')}`
  } else if (recurrence_type === RecurrenceType.monthly) {
    if (recurrence_occur_type === RecurrenceOccurType.day) {
      text += ` the ${recurrence_occur} of the month`
    } else if (recurrence_occur_type === RecurrenceOccurType.weekday) {
      text += ` the ${recurrence_occur_ordinal} ${dayjs().day(recurrence_occur).format('ddd')}`
    }
  }
  // Recurrence end
  if (recurrence_end_type === RecurrenceEndType.date) {
    let endDate = recurrence_end_date
    if (end_time) {
      const end = dayjs(end_time)
      endDate = dayjs(recurrence_end_date).hour(end.hour()).minute(end.minute())
    }
    text += `, until ${formatDate(endDate)}`
  } else if (recurrence_end_type === RecurrenceEndType.occurrence) {
    text += `, ${recurrence_end_occurrences} occurence(s)`
  }
  return text
}

export const isPastSchedule = (schedule) => {
  if (!schedule) return false
  if (!schedule.recurrence_type) return dayjs().isAfter(schedule.end_time)
  return dayjs().isAfter(getRecurrenceEndDate(schedule))
}

export const getScheduleQuestion = (schedule) => {
  if (!schedule || !schedule.question) return ''
  return schedule.question.content
}
