import React from 'react'
import 'src/scss/common/_pagination.scss'
import PropTypes from 'prop-types'
import { ceil } from 'lodash'
import { Pagination } from 'react-headless-pagination'

const ReactPagination = ({ page, size, total, onChange, loading }) => {
  const handleChange = (index) => {
    if (!loading) {
      onChange(index + 1)
    }
  }

  if (total > 0) {
    return (
      <div className="paginate-custom">
        <Pagination
          currentPage={page - 1}
          setCurrentPage={handleChange}
          totalPages={ceil(total / size)}
          edgePageCount={2}
          middlePagesSiblingCount={2}
          className={`paginate ${loading ? 'disabled' : ''}`}
          truncableText="..."
          truncableClassName="pagination-trunc"
        >
          <Pagination.PrevButton className="page-prev text-sm">
            {`<`} <span className="ms-2">Previous</span>
          </Pagination.PrevButton>
          <div className="flex items-center justify-center flex-grow page-list">
            <Pagination.PageButton
              activeClassName="active"
              inactiveClassName="inactive"
              className="page-button"
            />
          </div>
          <Pagination.NextButton className="page-next text-sm">
            <span className="me-2">Next</span> {`>`}
          </Pagination.NextButton>
        </Pagination>
      </div>
    )
  } else {
    return ''
  }
}

ReactPagination.propTypes = {
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
}

export default ReactPagination
