import React, { useEffect, Suspense, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './scss/style.scss'
import './scss/core/_index.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-sliding-side-panel/lib/index.css'
import 'src/scss/common/_datePicker.scss'
import 'react-datepicker/dist/react-datepicker.css'

import { Loading } from 'src/components/common'
import { toast } from 'react-toastify'
import useFirebaseMessage from './hooks/useFirebaseMessage'
import Routes from './navigations'
import { NavigationType, useNavigationType } from 'react-router-dom'
import { getMessagingToken } from './services/firebase'
import { registerDevices } from './store/reducers/fcm'
import { useConfigStore } from './store/useConfigStore'
import { useShallow } from 'zustand/react/shallow'
import { useTopicStore } from './store/useTopicStore'

const App = () => {
  const dispatch = useDispatch()
  const { getPlatformBadges, getPlatformCategories } = useConfigStore(
    useShallow((state) => ({
      getPlatformCategories: state.getPlatformCategories,
      getPlatformBadges: state.getPlatformBadges,
    })),
  )
  const getSearchSuggestions = useTopicStore(useShallow((state) => state.getSearchSuggestions))
  const navigationType = useNavigationType()
  const dms_protect = localStorage.getItem('dms_protect') || null
  const { subscribe, unsubscribe } = useFirebaseMessage()
  const { user } = useSelector((state) => state.users)

  const [isVerified, setIsVerified] = useState(
    Boolean(process.env.REACT_APP_PUBLIC === 'true') ||
      dms_protect === process.env.REACT_APP_PASSWORD,
  )

  const protectPassword = () => {
    const password = prompt('Enter password')
    if (password === process.env.REACT_APP_PASSWORD) {
      localStorage.setItem('dms_protect', password)
      setIsVerified(true)
    } else {
      alert('Sorry, password incorrect')
      setIsVerified(false)
    }
  }

  useEffect(() => {
    if (!isVerified) {
      protectPassword()
    }
    getSearchSuggestions()
    getPlatformCategories()
    getPlatformBadges()

    const fcmHandler = (data) => {
      toast.info(data.body || '')
    }
    subscribe(fcmHandler)
    return () => unsubscribe(fcmHandler)
  }, [])

  useEffect(() => {
    if (navigationType === NavigationType.Push || navigationType === NavigationType.Pop)
      setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100)
  }, [navigationType])

  useEffect(() => {
    if (!user) return
    requestPermissions(user.id)
  }, [user])

  const requestPermissions = async (userId) => {
    const fcm_token = await getMessagingToken()
    if (!!fcm_token) {
      dispatch(
        registerDevices({
          registration_id: fcm_token,
          type: 'web',
          user: userId,
        }),
      )
    }
  }

  return (
    <>
      {isVerified ? (
        <Suspense fallback={<Loading />}>
          <Routes />
        </Suspense>
      ) : (
        <h3>You do not have permission to access this website</h3>
      )}
    </>
  )
}

export default App
