import React, { useEffect } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import { changePassword, resetUsers } from 'src/store/reducers/users'

import { CButton, CCol, CForm, CFormInput, CInputGroup, CRow, CSpinner } from '@coreui/react'
import useWindowSize from 'src/hooks/useWindowSize'
import { PASSWORD_REGEX } from 'src/constants'

const ChangePasswordForm = ({ onSaved }) => {
  const { width } = useWindowSize()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.users)

  const initialValues = {
    email: '',
    old_password: '',
    password: '',
    confirm_password: '',
  }

  const validationSchema = yup.object().shape({
    email: yup.string().email('Email invalid').required('Email is required'),
    old_password: yup.string().required('Old Password is required'),
    password: yup
      .string()
      .matches(
        PASSWORD_REGEX,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
      )
      .notOneOf([yup.ref('old_password')], 'Please enter a new password')
      .required('Password is required'),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Password is required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      let data = {
        email: values.email,
        old_password: values.old_password,
        password: values.password,
        confirm_password: values.confirm_password,
      }

      const res = await dispatch(changePassword(data))
      if (res.error) return
      if (onSaved) onSaved()
    },
  })

  useEffect(() => {
    if (user) {
      formik.setValues({
        ...user,
        old_password: '',
        password: '',
        confirm_password: '',
      })
    }

    return () => {
      dispatch(resetUsers())
    }
  }, [user])

  const { values, errors, isSubmitting, handleChange, touched, handleSubmit } = formik

  return (
    <CForm onSubmit={handleSubmit}>
      <CRow className={width > 768 ? 'mt-3' : 'mt-3'}>
        <CCol md={6}>
          <CInputGroup className="mb-3">
            <CFormInput
              type="email"
              placeholder="Email"
              autoComplete="email"
              name="email"
              value={values.email}
              invalid={errors.email && touched.email}
              onChange={handleChange}
              feedbackInvalid={errors.email}
              disabled
            />
          </CInputGroup>
        </CCol>
        <CCol md={6}>
          <CInputGroup className="mb-3">
            <CFormInput
              type="password"
              placeholder="Old Password"
              autoComplete="new-password"
              name="old_password"
              value={values.old_password}
              invalid={errors.old_password && touched.old_password}
              onChange={handleChange}
              feedbackInvalid={errors.old_password}
            />
          </CInputGroup>
        </CCol>

        <CCol md={6}>
          <CInputGroup className="mb-3">
            <CFormInput
              type="password"
              placeholder="Password"
              autoComplete="new-password"
              name="password"
              value={values.password}
              invalid={errors.password && touched.password}
              onChange={handleChange}
              feedbackInvalid={errors.password}
            />
          </CInputGroup>
        </CCol>
        <CCol md={6}>
          <CInputGroup className="mb-4">
            <CFormInput
              type="password"
              placeholder="Confirm password"
              autoComplete="new-password"
              name="confirm_password"
              value={values.confirm_password}
              invalid={errors.confirm_password && touched.confirm_password}
              onChange={handleChange}
              feedbackInvalid={errors.confirm_password}
            />
          </CInputGroup>
        </CCol>
      </CRow>

      <div>
        <CButton type="submit" color="info" disabled={isSubmitting}>
          {isSubmitting && <CSpinner size="sm" className="me-2" />}
          Change
        </CButton>
      </div>
    </CForm>
  )
}

ChangePasswordForm.propTypes = {
  onSaved: PropTypes.func,
}

export default ChangePasswordForm
