import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'src/config/api'
import { toast } from 'react-toastify'

export const getRegistrationStores = createAsyncThunk(
  'registrationStore/all',
  async ({ page = 1, page_size = 10 }, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        `organisers/registration?page=${page}&page_size=${page_size}`,
      )
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get registrations failed')
        throw error
      }
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Get registrations failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const createStoreRegistration = createAsyncThunk(
  'registrationStore/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`organisers/registration`, data)
      toast.success('The form is submitted, an email has been sent to you.')
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Register organiser failed')
        throw error
      }
      if (error.response.data) {
        const data = error.response.data
        let msg = JSON.stringify(error.response.data)
        if (data.detail) msg = data.detail
        if (data.email) msg = data.email[0]
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const approveStore = createAsyncThunk(
  'registrationStore/approve',
  async ({ id, username }, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`organisers/registration/approve`, {
        registration: id,
        username,
      })
      toast.success('Approve organiser successfully! An email has been sent to organiser owner.')
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Approve organiser failed')
        throw error
      }
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Approve organiser failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const rejectStore = createAsyncThunk(
  'registrationStore/reject',
  async ({ id, reason }, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`organisers/registration/reject`, {
        registration: id,
        reason,
      })
      toast.success('Reject organiser successfully! An email has been sent to organiser owner.')
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Reject organiser failed')
        throw error
      }
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Reject organiser failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const requestInfoStore = createAsyncThunk(
  'registrationStore/requestInfo',
  async ({ id, message }, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`organisers/registration/request-info`, {
        registration: id,
        message,
      })
      toast.success(
        'Request info organiser successfully! An email has been sent to organiser owner.',
      )
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Request info organiser failed')
        throw error
      }
      if (error.response.data) {
        const msg = error.response.data
          ? error.response.data.detail
          : 'Request info organiser failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

const defaultRequestState = {
  data: null,
  loading: false,
}

const savedSlice = createSlice({
  name: 'registrationStore',
  initialState: {
    stores: defaultRequestState,
    approving: false,
    rejecting: false,
    infoRequesting: false,
    errors: null,
  },
  reducers: {
    resetStores: (state) => {
      state.errors = null
      state.stores = defaultRequestState
      state.approving = false
      state.rejecting = false
      state.infoRequesting = false
    },
  },
  extraReducers: (builder) => {
    // get all stores
    builder.addCase(getRegistrationStores.pending, (state) => {
      state.stores.loading = true
    })
    builder.addCase(getRegistrationStores.fulfilled, (state, action) => {
      state.stores.loading = false
      state.stores.data = action.payload
    })
    builder.addCase(getRegistrationStores.rejected, (state, action) => {
      state.stores.loading = false
      state.errors = {
        stores: action.payload,
      }
    })
    // approve registration
    builder.addCase(approveStore.pending, (state) => {
      state.approving = true
    })
    builder.addCase(approveStore.fulfilled, (state, action) => {
      state.approving = false
    })
    builder.addCase(approveStore.rejected, (state, action) => {
      state.approving = false
      state.errors = {
        approve: action.payload,
      }
    })
    // reject registration
    builder.addCase(rejectStore.pending, (state) => {
      state.rejecting = true
    })
    builder.addCase(rejectStore.fulfilled, (state, action) => {
      state.rejecting = false
    })
    builder.addCase(rejectStore.rejected, (state, action) => {
      state.rejecting = false
      state.errors = {
        reject: action.payload,
      }
    })
    // request info registration
    builder.addCase(requestInfoStore.pending, (state) => {
      state.infoRequesting = true
    })
    builder.addCase(requestInfoStore.fulfilled, (state, action) => {
      state.infoRequesting = false
    })
    builder.addCase(requestInfoStore.rejected, (state, action) => {
      state.infoRequesting = false
      state.errors = {
        infoRequest: action.payload,
      }
    })
  },
})

export const { resetStores } = savedSlice.actions

export default savedSlice.reducer
