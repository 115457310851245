import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'src/config/api'
import { toast } from 'react-toastify'

export const createCoupon = createAsyncThunk(
  'vouchers/new',
  async (coupon, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`vouchers/`, coupon)
      toast.success('Voucher created!')
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Create voucher failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        let _err = error.response.data
        if (_err.coupon_code) {
          toast.error(_err.coupon_code[0])
        } else {
          const msg = _err.detail ? _err.detail : 'Create voucher failed'
          toast.error(msg)
        }
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getCouponWithCode = createAsyncThunk(
  'vouchers/get-detail-with-code',
  async (coupon_code, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`vouchers/${coupon_code}/`)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get voucher detail failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          error.response.data && error.response.data.detail
            ? error.response.data.detail
            : 'Get voucher detail failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const updateCoupon = createAsyncThunk(
  'vouchers/update',
  async ({ coupon, id }, { rejectWithValue }) => {
    try {
      const response = await Axios.put(`vouchers/${id}/`, coupon)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Update voucher failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg = error.response.data ? error.response.data.detail : 'Update voucher failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getAllCoupons = createAsyncThunk(
  'vouchers/all',
  async ({ page = 1, page_size = 10, filter }, { rejectWithValue }) => {
    try {
      let URL = `vouchers/?page=${page}&page_size=${page_size}`
      if (filter) {
        for (const [key, value] of Object.entries(filter)) {
          URL += `&${key}=${value}`
        }
      }
      const response = await Axios.get(URL)
      // const response = await Axios.get(`vouchers/`)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get all vouchers failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          error.response.data && error.response.data.detail
            ? error.response.data.detail
            : 'Get all vouchers failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getCouponsFilter = createAsyncThunk(
  'vouchers/coupons-filter',
  async (filter, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`vouchers/?from=${filter.from}&to=${filter.to}`)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get vouchers filters failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          error.response.data && error.response.data.detail
            ? error.response.data.detail
            : 'Get vouchers filter failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const getCouponWithId = createAsyncThunk(
  'vouchers/get-detail',
  async (couponId, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`vouchers/${couponId}/`)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Get voucher detail failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          error.response.data && error.response.data.detail
            ? error.response.data.detail
            : 'Get voucher detail failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const deleteCouponWithId = createAsyncThunk(
  'vouchers/delete-coupon',
  async (couponId, { rejectWithValue }) => {
    try {
      const response = await Axios.delete(`vouchers/${couponId}/`)

      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Delete voucher failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          error.response.data && error.response.data.detail
            ? error.response.data.detail
            : 'Delete voucher failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

export const sendCouponViaEmails = createAsyncThunk(
  'vouchers/send-coupon-via-emails',
  async (coupon, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`vouchers/share-code/`, coupon)
      toast.success('Send voucher successfully!')
      return response.data
    } catch (error) {
      if (!error.response) {
        toast.error('Share voucher failed')
        throw error
      }
      console.log('error.response.data: ', error.response.data)
      if (error.response.data) {
        const msg =
          error.response.data && error.response.data.detail
            ? error.response.data.detail
            : 'Share voucher failed'
        toast.error(msg)
      }
      return rejectWithValue(error.response.data)
    }
  },
)

// export const getCouponsOfStore = createAsyncThunk(
//   'vouchers/coupons-of-store',
//   async (coupon, { rejectWithValue }) => {
//     try {
//       const response = await Axios.post(`vouchers/`, coupon)

//       return response.data
//     } catch (error) {
//       if (!error.response) {
//         toast.error('Share coupon failed')
//         throw error
//       }
//       console.log('error.response.data: ', error.response.data)
//       if (error.response.data) {
//         const msg =
//           error.response.data && error.response.data.detail
//             ? error.response.data.detail
//             : 'Share coupon failed'
//         toast.error(msg)
//       }
//       return rejectWithValue(error.response.data)
//     }
//   },
// )

const userSlice = createSlice({
  name: 'coupons',
  initialState: {
    loading: false,
    coupon: null,
    couponByCode: null,
    coupons: [],
    couponsFilter: null,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    isShared: false,
    errors: null,
  },
  reducers: {
    resetCoupons: (state) => {
      state.loading = false
      state.isCreated = false
      state.isUpdated = false
      state.isDeleted = false
      state.isShared = false
      state.errors = null
    },
  },
  extraReducers: (builder) => {
    // get coupons with filter
    builder.addCase(getCouponsFilter.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCouponsFilter.fulfilled, (state, action) => {
      state.loading = false
      state.couponsFilter = action.payload
    })
    builder.addCase(getCouponsFilter.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        all: action.payload,
      }
    })
    // get all coupons
    builder.addCase(getAllCoupons.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAllCoupons.fulfilled, (state, action) => {
      state.loading = false
      state.coupons = action.payload
    })
    builder.addCase(getAllCoupons.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        all: action.payload,
      }
    })
    // create coupon
    builder.addCase(createCoupon.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createCoupon.fulfilled, (state, action) => {
      state.loading = false
      state.coupon = action.payload
      state.isCreated = true
    })
    builder.addCase(createCoupon.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        new: action.payload,
      }
    })
    // update coupon
    builder.addCase(updateCoupon.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateCoupon.fulfilled, (state, action) => {
      state.loading = false
      state.coupon = action.payload
      state.isUpdated = true
    })
    builder.addCase(updateCoupon.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        update: action.payload,
      }
    })
    // get coupon with id
    builder.addCase(getCouponWithId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCouponWithId.fulfilled, (state, action) => {
      state.loading = false
      state.coupon = action.payload
    })
    builder.addCase(getCouponWithId.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        detail: action.payload,
      }
    })
    // get coupon with code
    builder.addCase(getCouponWithCode.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCouponWithCode.fulfilled, (state, action) => {
      state.loading = false
      state.couponByCode = action.payload
    })
    builder.addCase(getCouponWithCode.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        detailCode: action.payload,
      }
    })
    // delete coupon with id
    builder.addCase(deleteCouponWithId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteCouponWithId.fulfilled, (state, action) => {
      state.loading = false
      state.isDeleted = true
    })
    builder.addCase(deleteCouponWithId.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        delete: action.payload,
      }
    })
    // share coupon via emails
    builder.addCase(sendCouponViaEmails.pending, (state) => {
      state.loading = true
    })
    builder.addCase(sendCouponViaEmails.fulfilled, (state, action) => {
      state.loading = false
      state.isShared = true
    })
    builder.addCase(sendCouponViaEmails.rejected, (state, action) => {
      state.loading = false
      state.errors = {
        shared: action.payload,
      }
    })
  },
})

export const { resetCoupons } = userSlice.actions

export default userSlice.reducer
