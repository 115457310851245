import axios from 'axios'
import routes from 'src/navigations/routes'
import { sendErrorEvent } from 'src/services/sentry'

const baseURL = process.env.REACT_APP_URL_API || '/'
let token = localStorage.getItem('dms_token') || ''

const Axios = axios.create({
  baseURL,
  headers: {
    Authorization: token ? `Bearer ${token}` : null,
    // Accept: 'application/json'
  },
})

Axios.interceptors.request.use((config) => {
  let token = localStorage.getItem('dms_token') || ''
  config.headers.Authorization = token ? `Bearer ${token}` : null
  return config
})

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    sendErrorEvent(error, {
      url: `${error.config.baseURL}${error.config.url}`,
      body: error.config.data,
      response: error.response ? error.response.data : JSON.stringify(error),
    })
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('dms_token')
      localStorage.removeItem('dms_role')
      localStorage.removeItem('dms_username')
      if (window.location.pathname !== routes.LOGIN) window.location.href = routes.LOGIN
    }
    return Promise.reject(error)
  },
)

export default Axios
