import { toast } from 'react-toastify'
import Axios from 'src/config/api'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const useTopicReplyStore = create()(
  immer((set, get) => ({
    replies: [],
    page: 1,
    pageSize: 5,
    total: 0,
    loading: false,
    countReplyTopic: {},
    countReplyReply: {},
    reloadEditReply: null,
    reloadNewReplyInTopic: null,
    reloadNewReplyInReply: null,
    reloadDeleteReply: null,
    updateTopicReplyCount: (topicId, count) => {
      set({ countReplyTopic: { ...get().countReplyTopic, [topicId]: count } })
    },
    updateReplyReplyCount: (replyId, count) => {
      set({ countReplyReply: { ...get().countReplyReply, [replyId]: count } })
    },
    setReloadEditReply: (replyId) => {
      set({
        setReloadEditReply: replyId,
      })
    },
    setReloadNewReplyInTopic: (topicId) => {
      set({
        reloadNewReplyInTopic: topicId,
      })
    },
    setReloadNewReplyInReply: (replyId) => {
      set({
        reloadNewReplyInReply: replyId,
      })
    },
    setReloadDeleteReply: (replyId) => {
      set({
        reloadDeleteReply: replyId,
      })
    },
    getReply: async (replyId) => {
      try {
        const response = await Axios.get(`topics/replies/${replyId}`)
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    getTotalReplies: async ({ topicId }) => {
      try {
        const response = await Axios.get(`topics/replies/total/`, {
          params: { topic_id: topicId },
        })
        set({
          countReplyTopic: {
            ...get().countReplyTopic,
            [topicId]: response.data,
          },
        })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
          toast.error(message)
        }
      }
    },
    getTopicReplies: async ({ topicId, page, pageSize, from, to, advanced }) => {
      try {
        const url = advanced ? 'topics/replies/all-feeds/' : 'topics/replies/'
        const response = await Axios.get(url, {
          params: {
            topic_id: topicId,
            page,
            from,
            to,
          },
        })
        if (response.data) {
          set({
            replies: response.data.results,
            total: response.data.totals,
            page,
            pageSize,
            countReplyReply: {
              ...get().countReplyReply,
              ...response.data.results.reduce((map, e) => ({ ...map, [e.id]: e.reply_count }), {}),
            },
          })
        }
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
          toast.error(message)
        }
      }
    },
    getTopicSecondReplies: async (replyId) => {
      try {
        const response = await Axios.get(`topics/replies/${replyId}/reply/`)
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    createTopicReply: async ({
      topicId,
      replyId,
      content,
      images,
      newMentions,
      notifyToSchedule,
      scheduleId,
    }) => {
      try {
        let formData = new FormData()
        if (images.length > 0) {
          images.forEach((file) => {
            formData.append('images', file, file.name)
          })
        }
        formData.append('topic_id', topicId)
        formData.append('content', content)
        if (newMentions.length > 0) newMentions.forEach((e) => formData.append('new_mentions', e))
        if (replyId) formData.append('parent_reply', replyId)
        if (notifyToSchedule) formData.append('notify_to_schedule_id', notifyToSchedule)
        if (scheduleId) formData.append('feedback_to_schedule_id', scheduleId)

        const response = await Axios.post(`topics/replies/`, formData)
        toast.success('Posted successfully')

        if (!scheduleId) {
          set({
            countReplyTopic: {
              ...get().countReplyTopic,
              [topicId]: get().countReplyTopic[topicId] + 1,
            },
          })
        }
        return response.data
      } catch (error) {
        let message = 'Failed to post, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
        throw message
      }
    },
    editTopicReply: async ({
      topicId,
      replyId,
      content,
      images,
      deleteImages,
      parentReplyId,
      newMentions,
      deleteMentions,
    }) => {
      try {
        let formData = new FormData()
        if (images.length > 0) {
          images.forEach((file) => {
            formData.append('images', file, file.name)
          })
        }
        if (deleteImages.length > 0)
          deleteImages.forEach((e) => formData.append('delete_images', e))

        formData.append('content', content)
        formData.append('topic_id', topicId)
        if (deleteMentions.length > 0)
          deleteMentions.forEach((e) => formData.append('delete_mentions', e))
        if (newMentions.length > 0) newMentions.forEach((e) => formData.append('new_mentions', e))

        const response = await Axios.patch(`topics/replies/${replyId}/`, formData, {
          params: {
            parent_reply: parentReplyId,
          },
        })
        toast.success('Updated successfully')

        set({
          reloadEditReply: replyId,
        })
        return response.data
      } catch (error) {
        let message = 'Update failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },

    /* topcId + parentReplyId used in reducer */
    deleteTopicReply: async ({ replyId }) => {
      try {
        const response = await Axios.delete(`topics/replies/${replyId}/`)
        toast.success('Delete successfully')
        set({
          reloadDeleteReply: replyId,
        })

        return response.data
      } catch (error) {
        let message = 'Delete failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    voteTopicReply: async ({ replyId, isVote, type }) => {
      try {
        const response = await Axios({
          method: isVote ? 'POST' : 'DELETE',
          url: `topics/replies/${replyId}/vote/`,
          params: {
            type,
          },
        })
        return response.data
      } catch (error) {
        let message = 'Vote failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    getTopicQuestions: async ({ topicId = undefined }) => {
      try {
        const response = await Axios.get(`topics/questions/`, {
          params: { topic_id: topicId },
        })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    getTopicPostedQuestions: async ({ topicId = undefined, username = undefined }) => {
      try {
        const response = await Axios.get(`topics/posted-questions/`, {
          params: { topic_id: topicId, username },
        })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    getQuestionFeedbacks: async ({ scheduleId }) => {
      try {
        const response = await Axios.get(`topics/feedbacks/`, {
          params: {
            schedule_id: scheduleId,
          },
        })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    getReceivedFeedbacks: async ({ username }) => {
      try {
        const response = await Axios.get(`users/received-feedbacks/`, {
          params: {
            username,
          },
        })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    getGivenFeedbacks: async ({ username }) => {
      try {
        const response = await Axios.get(`users/given-feedbacks/`, {
          params: {
            username,
          },
        })
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
    getQuestionUserVotes: async (replyId) => {
      try {
        const response = await Axios.get(`topics/replies/${replyId}/votes/`)
        return response.data
      } catch (error) {
        let message = 'Get failed, please contact support if issue persists'
        if (error.response && error.response.data && error.response.data.detail) {
          message = error.response.data.detail
        }
        toast.error(message)
      }
    },
  })),
)
