import React from 'react'
import PropTypes from 'prop-types'
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react'
import ChangePasswordForm from 'src/components/user/ChangePasswordForm'
import { useDispatch, useSelector } from 'react-redux'
import { getProfile } from 'src/store/reducers/users'

const ChangePasswordModal = () => {
  const dispatch = useDispatch()
  const { needChangePassword } = useSelector((state) => state.users)

  return (
    <CModal alignment="center" visible={needChangePassword} backdrop="static">
      <CModalHeader closeButton={null}>
        <CModalTitle>Change Password Required</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <p>
          As we have improved our security measure, we would like to request that your
          password to be changed.
        </p>
        <ChangePasswordForm
          onSaved={() => {
            dispatch(getProfile())
          }}
        />
      </CModalBody>
    </CModal>
  )
}
ChangePasswordModal.propTypes = {
  open: PropTypes.bool,
}
export default ChangePasswordModal
