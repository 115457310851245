import React from 'react'

import HeaderDesktop from './desktop'
import HeaderMobile from './mobile'

import useWindowSize from 'src/hooks/useWindowSize'

const Header = () => {
  const { width } = useWindowSize()

  return width > 768 ? <HeaderDesktop /> : <HeaderMobile />
}

export default Header
