import React from 'react'
import PropTypes from 'prop-types'
import { CSpinner } from '@coreui/react'
import classNames from 'classnames'

const Loading = ({ className }) => {
  return (
    <div
      className={classNames(
        'd-flex align-items-center justify-content-center w-100 h-100',
        className,
      )}
    >
      <CSpinner sie="lg" color="primary" />
    </div>
  )
}

Loading.propTypes = {
  className: PropTypes.string,
}

export default Loading
